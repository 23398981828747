import { useState, useEffect } from "react"
import { Button, Tooltip, Modal } from 'antd'
import { BiHelpCircle } from "react-icons/bi";




const Help = (props) => {
  const [visible, setvisible] = useState(false)


  return (
    <Tooltip title="Справка" >
      <Button 
        icon={<BiHelpCircle />} 
        type="text"
        onClick={()=>setvisible(true)}
        shape={props.full ? null : "circle"}
      >
        { props.full ? <>&nbsp;Справка</> : null }
      </Button>
      <Modal
        visible={visible}
        title={<><BiHelpCircle />&nbsp;Как пользоваться сайтом</>}
        onOk={()=>setvisible(false)}
        onCancel={()=>setvisible(false)}
        footer={[<Button onClick={()=>setvisible(false)}>Ок</Button>]}
      >
        <p>AAAAAAAAAAAAA</p>
      </Modal>
    </Tooltip>
  )
}


export default Help