import {
    ACTION_DIRECTION_DATA,
    ACTION_DIRECTION_VISIBLE,
  } from "./types";
  
  

export const actionDirectionVisible = (visible) => ({
  type: ACTION_DIRECTION_VISIBLE,
  payload: visible
});

export const actionDirectionData = (direction) => ({
  type: ACTION_DIRECTION_DATA,
  payload: direction
});
  