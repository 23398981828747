const initialMetaState = {
  user: {authorized: false},
};

export const commonReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_ADD_USER":
      return { ...state, user: action.payload };
    case "ACTION_DELETE_USER":
      return { ...state, user: {} };
    default:
      return state;
  }
};
