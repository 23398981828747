import { useState, useEffect } from 'react';
import { Tree, Tabs } from 'antd';
import { get, post } from "./../../api/fetchData";
import moment from 'moment';


const { TabPane } = Tabs;


const MyDirections = (props) => {
  // const [tab, setTab] = useState()
  const [data, setData] = useState()
  // const [data, setData] = useState([{data: [], params: {}}])

  useEffect(()=>{
    const f = async () => {
      const resp = await get('transfer_directions')
      setData(resp.data)
      console.log(data)
    }
    f()
  }, [])

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck = async (checkedKeys, info, id) => {
    console.log('onCheck', info);
    // console.log(info.checked, info.checkedNodes)//.filter(node=>!node.children))
    var ids = []
    if (!info.node.children) { ids.push(info.node.key) }
    else { info.node.children.map(node1=>{
      if (!node1.children) { ids.push(node1.key); return }
      node1.children.map(node2=>{
        if (!node2.children) { ids.push(node2.key); return }
        node2.children.map(node3=>{
          if (!node3.children) { ids.push(node3.key); return }
        })
      })
    }) }
    console.log(ids)
    let formData = new FormData
    formData.append('directions', ids)
    await post('add_delete_my_directions', {id: id, action: info.checked ? 'add' : 'delete'}, formData)
  };

  // const onTabChange = (key) => {setTab(key)}

  return (
    <>
      {data&&<Tabs 
        // defaultActiveKey={data[0]} 
        // onChange={onTabChange}
        // tabBarExtraContent={ tab==='transfer' ? <AddTransferBtn /> : null }  
      >
        {data.map(d=>{return (
          <TabPane tab={`${d.transfer.brand} ${d.transfer.model} ${moment(d.transfer.year).year()}`} key={d.transfer.id} >
            <Tree
              checkable
              onSelect={onSelect}
              // onCheck={onCheck}
              defaultExpandedKeys={d.params.defaultExpandedKeys}
              defaultCheckedKeys={d.params.defaultCheckedKeys}
              onCheck={(checkedKeys, info)=>onCheck(checkedKeys, info, d.transfer.id)}
              treeData={d.data}
            />
          </TabPane>
        )})}
      </Tabs>}
    </>
  );
};


export default MyDirections