const initialMetaState = {
  visible: false,
  routes: {}
};

export const routesReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_ROUTES_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_ROUTES_DATA":
      return { ...state, routes: action.payload };
    default:
      return state;
  }
};
