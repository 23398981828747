import { isEmail } from "validator";
import { history } from './../../helpers/helpers';



import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
// import { actionSignup } from "./../../actions/auth";
// import {
//   actionAuthorize,
// } from "./../../actions/auth";
import { message, Form, Modal, Button, Input, Avatar, Select } from 'antd'
import {
  UserOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { get, post } from "./../../api/fetchData";

const queryString = require('query-string');




const { Option } = Select;

const Signup = (props) => {

  useEffect(()=>{
    const params = queryString.parse(window.location.search)
    if (window.location.pathname.substr(1)==='invite' && params.id!==undefined) {
      props.setVisible(true)
      form.setFieldsValue({invited_by: params.id})
    }
  }, [])

  const [form] = Form.useForm();

  const handleSignup = async (setLoading) => {
    const fields = form.getFieldsValue()
    if (fields.phone===undefined || fields.phone==='') {
      message.error('Укажите мобильный телефон')
    } else if (fields.phone.length!==12) {
      message.error('Некорректный номер телефона')
    } else if (fields.name===undefined) {
      message.error('Заполните Имя')
    } else if (fields.password===undefined) {
      message.error('Заполните Пароль')
    } else if (fields.password.length<6) {
      message.error('Минимальная длина пароля 4 символа')
    } else if (fields.password.length>=6) {
      const letterNumber = /^[0-9a-zA-Z]+$/;
      if (!fields.password.match(letterNumber)) {
        message.error('Пароль должен содержать только числа или латинские буквы')
      } else {
        setLoading(true)
        const resp = await post('signup', undefined, fields)
        if (resp.status===200) {
          localStorage.setItem("user", JSON.stringify(resp.data))
          message.info('Вы зарегистрированы')
          window.location.reload()
        } else {
          message.error(resp.data.detail)
        }
        setLoading(false)
      }
    } 
  }

  const CancelBtn = () => {
    return <Button onClick={()=>props.setVisible(false)}>Отмена</Button>
  }

  const OkBtn = () => {
    const [loading, setLoading] = useState(false)

    return (
      <Button
        key={Math.random()}
        onClick={()=>handleSignup(setLoading)}
        type="primary"
        loading={loading}
      >Регистрация</Button>
    )
  }



  return (
    <Modal
      title="Регистрация"
      key={Math.random()}
      visible={props.visible}
      onCancel={()=>props.setVisible(false)}
      footer={[<CancelBtn key={Math.random()} />, <OkBtn key={Math.random()} />]}
    >
      <Form
        layout="vertical"
        name="basicForm"
        form={form}
        onFinish={handleSignup}
      >
        <div style={{ textAlign: 'center' }}>
          <Avatar size={128} icon={<UserOutlined />} style={{textAlign: 'center'}}/>
          </div>
        <br />
        <Form.Item 
          label="Телефон" 
          name="phone" 
        >
          <PhoneInput defaultCountry='RU' />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="name"
          rules={[{ required: true, message: 'Обязательное поле' },]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: 'Обязательное поле' },]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item 
          name="type" 
          initialValue={props.userType}
        >
        </Form.Item>
      </Form>
    </Modal>
  )
}



export default connect()(Signup);
