import { Button , Tooltip, Modal } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';


export const EntitiesModal = ({title, visible, setVisible, h, divRef, children}) => {
  
  const BackBtn = () => {
    return (
      <Tooltip title="Вернуться" >
        <Button 
          onClick={()=>setVisible(false)} 
          icon={<ArrowLeftOutlined />} 
          type="text"
        />
      </Tooltip>
    )
  }

    
  return (
    <Modal
      visible={visible}
      title={<div><BackBtn />{title}</div>}
      onCancel={()=>setVisible(false)}
      footer={null}//[<OkBtn />]}
      width='100%'//{w}
      style={{
        top: '5px', bottom: '5px', 
        // display: 'flex', 
        // height: '100%', width: '100%',
        // height: '100%',
        left: '5px', right: '5px',
      }}
      bodyStyle={{
        // height: `${h-70}px`,
        // height: h-115, 
        // width: '100%',
        // height: '100%',
        height: window.innerHeight-115,
        // overflow: 'hidden', 
        // paddingRight: '3px', paddingLeft: '3px', paddingTop: '0px', paddingBottom: '3px',
        padding: '3px',
        // position: 'relative',
      }}
    >
      {children}
    </Modal>
  )
}