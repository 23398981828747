import { useState, useEffect } from "react";
import { Button, Image, Drawer, Form, Input, Col, Row, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { history } from './../helpers/helpers'
import { Router, Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import Auth from "./auth/auth.new";
import { ImExit } from "react-icons/im";



export const LinkTo = ({label, href, icon}) => {
  const [selected, setSelected] = useState()
  const location = useLocation();
  useEffect(() => {setSelected(location.pathname.substring(1)===href)}, [location.pathname])
  if (icon) {return <Link to={`/${href}`}><Tooltip title='Меню пользователя' placement="rightBottom" ><Button size='large' icon={icon} /></Tooltip></Link>}
  else {return <Link to={`/${href}`}><Button type='text' size='large' style={{margin: '2px', fontWeight: 'bold', borderRadius: '20px', backgroundColor: selected ? 'rgba(0,0,0,0.1)' : null}}>{label}</Button></Link> }
}


const Header = props => {
  const [auth, setAuth] = useState(false)
  return (
    <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '60px', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center'}}>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginLeft: '15px'}}>
          <Image alt='image' src='favicon.ico' width={30} height={30} preview={false} />
          <h2 style={{color: 'black', marginLeft: '5px'}}>ROCKTRIP.RU</h2>
        </div>
      </Link>
      <div style={{display: 'flex', flexDirection: 'row', marginRight: '20px'}}>
        {/* <Link to="/routes"><Button>Маршруты</Button></Link> */}
        {/* <LinkTo href='' label='ГЛАВНАЯ' /> */}
        <LinkTo href='map' label='КAРТА' />
        <LinkTo href='routes' label='ТУРЫ' />
        <LinkTo href='directions' label='ЛОКАЦИИ' />
        { !props.authorized && <Button type='text' size='large' style={{fontWeight: 'bold', margin: '2px'}}>СОТРУДНИЧЕСТВО</Button> }
        { !props.authorized && <Button size='large' style={{fontWeight: 'bold', borderRadius: '20px'}} onClick={()=>setAuth(true)} >ВОЙТИ</Button> }
        { props.authorized && <LinkTo href='favorites' label='ИЗБРАННОЕ' /> }
        { props.authorized && <LinkTo href='usermenu' label='Меню пользователя' icon={<UserOutlined />} /> }
        <Auth visible={auth} setVisible={setAuth} />
      </div>

    </div>
  )
}
















export default Header