import React, { useState, useEffect, useCallback } from 'react'
import { connect } from "react-redux";
import { 
  Select, Slider, Button, Popover, Radio, Space, Tree, Checkbox, Row, Col, Input, 
  Avatar, Divider, List, Skeleton,
} from 'antd'
import { CloseOutlined, HeartOutlined, StarOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component';





const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`

export const filterStyle = {
  marginLeft: '5px', 
  marginTop: '5px', 
  display: 'inline-block', 
  borderRadius: '3px', 
  borderColor: "rgba(52, 52, 52, 0.3)", 
  borderWidth: '1px', 
  borderStyle: "solid"
}
export const closed = {closed: true, padding: '8px', type: 'text', backgroundColor: 'white'}
export const opened = {closed: false, padding: '1px', type: 'link'}




export const Rate = props => {
  const [closable, setClosable] = useState(closed)
  var timeout;

  const onChange = (value) => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(()=>{
      setClosable(opened)
      props.setFilter({...props.filter, min_rate: value[0], max_rate: value[1]})
    }, 1000)
  }

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, min_rate: undefined, max_rate: undefined})
  }

  return (
    <div style={filterStyle}>
      <Popover trigger="click" placement="bottom" content={
          <Slider
            style={{width: '300px'}}
            // trackStyle={{ backgroundColor: "#fadb14" }}
            // handleStyle={{ borderColor: "#fadb14" }}
            range
            onChange={onChange} 
            dots
            defaultValue={[2, 4]}
            max={5}
            marks={[0, 1, 2, 3, 4, 5]}
          />
        }

      >
        <Button
          type={closable.type}
          icon={<StarOutlined />}
          onClick={e=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
        >
          Оценки
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}




export const Fav = props => {
  const [closable, setClosable] = useState(closed)

  const onClick = () => {
    setClosable(opened)
    props.setFilter({...props.filter, fav: true})
  }

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, fav: false})
  }

  return (
    <div style={filterStyle}>
      <Button
        icon={<HeartOutlined />}
        type={closable.type}
        onClick={onClick}
        style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
      >
        Избранное
      </Button>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}












export const Search = props => {
  const [value, setValue] = useState(props.filter.rgx)

  useEffect(() => {
    const handler = setTimeout(() => {props.setFilter({...props.filter, rgx: value})}, 500)
    return () => clearTimeout(handler)
  }, [value])

  return (
    <div style={{marginRight: '20px'}}>
      {/* <Popover placement="bottomRight" content={<SearchContent />} trigger='click'> */}
        <Input.Search placeholder='Искать' value={value} onChange={e=>setValue(e.target.value)} allowClear size='large' />
      {/* </Popover> */}
    </div>
  )
}




const SearchContent = props => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
      .then((res) => res.json())
      .then((body) => {
        setData([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < 50}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1,
            }}
            active
          />
        }
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.email}>
              <List.Item.Meta
                avatar={<Avatar src={item.picture.large} />}
                title={<a href="https://ant.design">{item.name.last}</a>}
                description={item.email}
              />
              <div>Content</div>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};