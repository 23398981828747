import axios from 'axios'
import authHeader from "./auth-header.js";


/**
 * A complete Coordinate Pair consisting of a latitude and longitude
@typedef {Object} CoordinatePair
@property {number} longitude - longitude coordinate
@property {number} latitude - latitude coordinate

Generates a GeoJSON FeatureCollection of random points based on
the center coordinates passed in.
@param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
@return {results} GeoJSON FeatureCollection


 */
// const API_URL = "http://20.113.39.55:8001/";
// const API_URL = "http://62.113.109.73:8015/";
// const API_URL = "http://localhost:8001/";
// const API_URL = "http://0.0.0.0:8001/";
const API_URL = "https://rocktrip.ru/api/";


// export const fetchData = () => {
//   const newFeaturesList = data;

//   return Promise.resolve({
//     type: "FeatureCollection",
//     features: newFeaturesList
//   });
// };







export const get = async (sub, params) => {
  return await axios.get(
    API_URL + sub,
    {
      headers: authHeader(),
      params: params
    })
    .then(async (response) => {return await response})
    .catch(async (err)=>{return await err.response})
};
  
  
export const post = (sub, params, formData) => {
  return axios({
    method: "post",
    url: API_URL + sub,
    params: params,
    data: formData,
    headers: authHeader(),
  })
  .then(async response => {return await response})
  // .then(async response => {const r = await response; console.log(r);return r})
  .catch(async err=>{return await err.response})
}


export const postb = (sub, params, formData) => {
  return axios({
    method: "post",
    url: API_URL + sub,
    params: params,
    data: formData,
    // headers: {...authHeader(), ...params}
    headers: authHeader()
  })
    .then(async (response) => {return await response})
    .catch(async (err)=>{return await err.response})
}



