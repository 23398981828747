import { useState, useEffect, useRef, createRef } from 'react';
import mapboxgl from "mapbox-gl";
import moment from "moment"
import {
  Drawer, Form, Button, Col, Row, Input, InputNumber, Upload,
  Select, DatePicker, Divider, Spin, Card, Modal,
  Checkbox, Switch, Typography, Space, Image, Avatar, 
} from 'antd';
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { get, post } from "./../../api/fetchData";
import UploadImages from "./uploadImages"
import AvatarLogo from './avatar'



const { Option } = Select;
const rules = [{ required: true, message: 'Обязательное поле' }]




const TransferForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState()
  const [data, setData] = useState()
  const [icon, setIcon] = useState()
  const [modalVisible, setModalVisible] = useState(false)




  useEffect(() => {
    setTimeout(()=>{
      setIcon(props.form.getFieldValue("icon"))
    }, 500)
  }, []);




  const regionShorter = (region) => {
    return region.replace("Республика ", "")
  }

  const handleSearch = async (value) => {
    setLoading(true)
    try {
      const resp = await get("latlng", {lat: props.form.getFieldValue('lat'), lng: props.form.getFieldValue('lng')})
      console.log(resp.data)
      props.form.setFieldsValue({
        district: resp.data.raw.district,
        region: regionShorter(resp.data.raw.region)
      });
      setData({
        district: resp.data.raw.district,
        region: regionShorter(resp.data.raw.region),
        town: resp.data.raw.place,
        adress: resp.data.raw.properties ? resp.data.raw.properties.address : undefined,
      })
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }




  const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`
  const options = [
    {icon: "aeroport", type: "Аэропорт"},
    {icon: "monument", type: "Памятник"},
    {icon: "tower", type: "Крепость"},
    {icon: "hotel", type: "Гостиница"},
    {icon: "hotel0", type: "Санаторно гостиничный комплекс"},
    {icon: "burial", type: "Некрополь"},
    {icon: "restourant", type: "Ресторан"},
    {icon: "swing", type: "Качели"},
    {icon: "church", type: "Церковь"},
    {icon: "mosque", type: "Мечеть"},
    {icon: "ski", type: "Лыжный курорт"},
    {icon: "railway", type: "ЖД вокзал"},
    {icon: "grocery", type: "Продуктовый магазин"},
    {icon: "horse", type: "Конюшня"},
    {icon: "busstation", type: "Автовокзал"},
  ]


  const Childrenn  = (props) => {
    const [checked, setChecked] = useState(props.form.getFieldValue('children')===false ? false : true)
    const onClick = () => {
      props.form.setFieldsValue({children: !checked})
      setChecked(!checked)
    }

    return (
      <Row>
        <Col>
          <Form.Item
            name="children"
          >
            <Switch checked={checked} onClick={onClick} />
          </Form.Item>
          </Col>
          <Col style={{marginLeft: "20px"}}>
          {checked ? "Можно с детьми" : "Без детей"} 
          </Col>
      </Row>
    )
  }


  const Year = () => {
    const year = props.form.getFieldValue("year") ? moment(props.form.getFieldValue("year")) : undefined
    const onChange = (date, dateString) => {
      dateString==='' ? props.form.setFieldsValue({year: undefined}) : props.form.setFieldsValue({year: moment(dateString).format('YYYY-MM-DDT00:00:00')})
    }
    return (<DatePicker
      picker="year"
      defaultValue={year}
      onChange={onChange}
      format='YYYY'
    />)
  }


  const CarBasement = () => {
    const basement = ['Северная Осетия-Алания', 'Кабардино-Балкария', 'Чечня', 'Дагестан']
    return (
      <Select defaultValue={basement[0]}>{
        basement.map(b=>
          <Option value={b}>{b}</Option>
      )}</Select>
    )
  }



  return (

    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>} delay={0}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="brand"
            rules={rules}
          >
            <Input 
              placeholder="Марка автомобиля" 
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="model"
            rules={rules}
          >
            <Input 
              placeholder="Марка автомобиля" 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="year"
            rules={rules}
          >
            {/* <DatePicker 
              picker="year"
              placeholder="Год выпуска авто" 
              style={{width: '100%'}}
            /> */}
            <Year/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="seats"
            rules={rules}
          >
            <InputNumber
              style={{width: '100%'}}
              placeholder="Вместимость" 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name="price"
            rules={rules}
          >
            <Input
              addonAfter="₽"
              placeholder="Цена, км" 
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name="home" >
            <CarBasement />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>Дополнительно</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="food_price" >
            <Input 
              addonAfter="₽"
              placeholder="Питание, стоимость/чел" 
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="food_cnt" >
            <InputNumber
              addonBefore="До"
              addonAfter="раз"
              style={{width: '100%'}}
              placeholder="Питание, количество раз" 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
        <Childrenn form={props.form} />
        </Col>
      </Row>
      <Divider orientation='left'><h6>Загрузить фото</h6></Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="images" >
            <UploadImages form={props.form} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'><h6>Выбрать лого</h6></Divider>
      <AvatarLogo form={props.form} />
      <Form.Item name="id" />
      <Form.Item name="logo" />
      <Form.Item name="avatar" />
    </Spin>
  )
}

export default TransferForm
