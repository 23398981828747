import React, { useRef, useEffect } from "react";
import { Input, Button, Row, Col, Space, Tooltip, Image, Typography } from 'antd'
import { FaRegMap } from "react-icons/fa";
import { AiOutlineFilter, AiFillPhone } from "react-icons/ai";
import logo from './../../static/logo.png';
import Filter from "./filter";
import Search from "./search";
import Help from "./help";
import UserMenu from "./../menu/menu";
import Auth from './../auth/auth'
import { Routes, Directions } from './extra'




const Sidebar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const full = window.innerWidth>850 ? true : false
  

  return (
    <div className='parent-sidebar'>
      <div className="child-sidebar">
        <div><h1 style={{color: 'red'}}>Ознакомительная версия без дизайна</h1></div>
        <div className='child-child-sidebar'>
          <img src="http://rocktrip.ru/storage/mainLogo.png" style={{width: '40px', height: '40px', marginLeft: '3px'}} />
          <UserMenu full={full} />
          <Search />
          {/* <RollVisible /> */}
          {/* <Filter /> */}
          <Routes full={full} />
          <Directions full={full}/>
          <Help full={full} />
          {!user&&<Auth full={full} />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
