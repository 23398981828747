const initialMetaState = {
  visible: false,
};

export const favoritesReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_FAVORITES_VISIBLE":
      return { ...state, visible: action.payload };
    default:
      return state;
  }
};
