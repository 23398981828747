import {useState, useEffect } from 'react'
// import { PageHeader, Tabs, Button } from 'antd';
import { Layout, Tabs, Button } from 'antd';
import Signin from './../auth/signin'
import Signup from './../auth/signup'

const RenderContent = () => {
  return (
    <>

    </>
);
  }


const { Header } = Layout



const Introduction = () => {
  const [signupModalVisible, setSignupModalVisible] = useState(false)
  const [signinModalVisible, setSigninModalVisible] = useState(false)
    const handleSignin = () => {
      setSigninModalVisible(true)
    }
    const handleSignup = () => {
      setSignupModalVisible(true)
    }

    return (
        <>
        <Header
          className="site-page-header-responsive"
          title={<h2 style={{color: 'orange', fontWeight: 'bold'}}>Rocktrip.ru</h2>}
          subTitle="partner"
          extra={[
            <Button key="signin" onClick={handleSignin}>Войти</Button>,
            <Button key="signup" onClick={handleSignup} type="primary">
              Регистрация
            </Button>,
          ]}
        >
        </Header>
        <RenderContent />
        <Signin visible={signinModalVisible} setVisible={setSigninModalVisible}/>
        <Signup visible={signupModalVisible} setVisible={setSignupModalVisible} userType="partner" />
      </>
    )
}


export default Introduction
