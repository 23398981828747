import React, { useState } from 'react'
import { Select } from 'antd';
import querystring from 'querystring';
import { get } from "./../../api/fetchData";


const { Option } = Select;



const SmartSelect = (props) => {
  const [data, setData] = useState(props.data)
  const [v, setValue] = useState(props.value)


  const handleSearch = async (value) => {
    try {
      const sub = "search?" + querystring.encode({
        rgx: value,
        collection: props.collection,
      })
      const resp = await get(sub)
      setData(resp.data)
    } catch (e) {
      console.log(e)
    }
  }


    return (
      <Select
        key={props.placeholder}
        showSearch
        value={v}
        placeholder={props.placeholder}
        style={props.style}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onFocus={handleSearch}
        onChange={value=>setValue(value)}
        notFoundContent={null}
        onSelect={(value)=>props.onSelect(value, data, props.index)}
        disabled={props.disabled}
        bordered={props.bordered}
      >
        {data.map(d => <Option key={d.value}>{d.label}</Option>)}
      </Select>
    )
}


export default React.memo(SmartSelect)
