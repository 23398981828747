import { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Card, Input, Badge } from 'antd'
import { StarFilled } from '@ant-design/icons';
import { FaFlagCheckered } from 'react-icons/fa';
import { actionMyRouteMove, actionMyRouteAdd } from "./../../actions/myroute"
import { actionTransfersSelected } from "./../../actions/transfers"
import { Favorite } from './../favorites'
import { minTommss, minTommss2 } from "./../../helpers/helpers"
import { useNavigate } from "react-router-dom";



const mapStateToProps = (state, ownProps) => ({
  myRoute: state.myrouteReducer.data,
	transfers_selected: state.transfersReducer.selected,
})


const mapDispatchToProps = dispatch => ({
  myRouteAdd: wayPoint => {dispatch(actionMyRouteAdd(wayPoint));},
  myRouteMove: route => {dispatch(actionMyRouteMove(route));},
	setMyRouteSelectTransfer: transfers_selected => {dispatch(actionTransfersSelected(transfers_selected));}
})





const DirectionBtn = connect(mapStateToProps, mapDispatchToProps)(props => {
	const [selected, setSelected] = useState(props.myRoute.filter(r=>r.lat===props.direction.lat&&r.lng===props.direction.lng).length!==0)
	const myRouteAddRemove = async () => {
		if (!selected) {props.myRouteAdd(JSON.parse(JSON.stringify(props.direction)))} 
		else {props.myRouteMove(props.myRoute.filter(r=>r.lat!==props.direction.lat&&r.lng!==props.direction.lng))}
		setSelected(!selected)
	}

  if (props.mobile) {
		return (
  	  <Button 
  	    type='link'
				ghost
  	    style={{
  	      width: '55px',
  	      height: '15px',
  	      color: selected ? '#40a9ff' : '#0b8235',
  	      padding: '0px',
  	      fontSize: '9px',
  	    }} 
  	    onClick={myRouteAddRemove}
  	  >
  	    { selected ? '✓Добавлено в мой маршрут' : '+Добавить в мой маршрут' }
  	  </Button>
  	)
	} else {
		return (
  	    <Button 
					type={selected ? 'primary' : 'default'} 
					onClick={myRouteAddRemove} 
					size='large'
					style={{marginTop: '5px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'}}
				>
  	      { selected ? '✓Добавлено в мой маршрут' : '+Добавить в мой маршрут' }
  	    </Button>
  	)
	}
})




const MyrouteBtn = connect(mapStateToProps, mapDispatchToProps)(props => {
	const navigate = useNavigate();
	const onClick = async () => {
		props.myRouteMove(props.myroute.directions)
		navigate('/map')
	}

  if (props.mobile) {
		return (
  	  <Button 
  	    type='link'
				ghost
  	    style={{
  	      width: '55px',
  	      height: '15px',
  	      // color: selected ? '#40a9ff' : '#0b8235',
  	      padding: '0px',
  	      fontSize: '9px',
  	    }} 
  	    onClick={onClick}
  	  >
  	    Открыть на карте
  	  </Button>
  	)
	} else {
		console.log(props.myRoute)
		return (
  	  <Tooltip title="Открыть на карте">
  	    <Button 
					// type={selected && 'primary'} 
					onClick={onClick} 
					size='large'
					style={{marginTop: '5px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'}}
				>
  	      Открыть на карте
  	    </Button>
  	  </Tooltip>
  	)
	}
})




const TransferBtn = connect(mapStateToProps, mapDispatchToProps)((props) => {
  const [myRouteBtn, setMyRouteBtn] = useState(
		props.transfers_selected.filter(selected=>selected.id===props.transfer.id).length===0
    ? '+ Выбрать'
    : '✓Выбрано'
  )
	
	const selected = '+ Выбрать'
  const myRouteSelectTransfer = () => {
    if (myRouteBtn==='+ Выбрать') {
      props.setMyRouteSelectTransfer(props.transfers_selected.concat(props.transfer))
      setMyRouteBtn('✓Выбрано')
    } else {
      props.setMyRouteSelectTransfer(props.transfers_selected.filter(selected=>selected.id!==props.transfer.id))
      setMyRouteBtn(selected)
    }
  }

	if (props.mobile) {
		return (
  	  <Button 
  	    type='text'
  	    style={{
  	      width: '55px',
  	      height: '15px',
  	      color: myRouteBtn===selected ? '#40a9ff' : '#0b8235',
  	      padding: '0px',
  	      fontSize: '9px',
  	    }} 
  	    onClick={myRouteSelectTransfer}
  	  >
  	    {myRouteBtn}
  	  </Button>
  	)
	} else {
		return (
  	  <Tooltip title="Маршрут">
  	    <Button 
  	      style={{
  	        borderRadius: "2px", 
  	        color: myRouteBtn===selected ? '#40a9ff' : '#0b8235',
  	        backgroundColor: myRouteBtn===selected ? null : '#e5fce3',
  	        borderColor: myRouteBtn===selected ? null : '#0b8235',
  	      }} 
  	      onClick={myRouteSelectTransfer}
  	    >
  	      {myRouteBtn}
  	    </Button>
  	  </Tooltip>
  	)
	}
})










const descStyle = {
		display: 'inline-block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
}




const DirectionDesc = ({entity, mobile, hideAdd}) => {
	if (mobile) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', marginRight: '5px', marginLeft: '5px' }}>
				<div className="desc2style" style={{alignItems: 'center', fontWeight: 'bold', fontSize: '12px'}}>
					{entity.name}
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<span style={{...descStyle, width: entity.rate ? '80%' : '100%', fontWeight: 'bold', color: 'gray', fontSize: '10px'}}>{entity.district ? entity.district : entity.town}</span>
					{entity.rate && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<span style={{fontWeight: 'bold', color: 'gray'}}>{entity.rate}</span>
						<StarFilled style={{color: '#fadb14', fontSize: '10px'}} />
					</div>}
				</div>
				{ !hideAdd && <DirectionBtn direction={entity} mobile={mobile} /> }
			</div>
		)
	} else {
			// <div style={{display: 'flex', flexDirection: 'column', width: 'auto', height: '100%', justifyContent: 'space-between', backgroundColor: 'green'}}>
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: 'auto', margin: '0px', justifyContent: 'space-between'}}>
				<div style={{display: 'flex', flexDirection: 'column', margin: '20px'}}>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<span style={{...descStyle, width: entity.rate ? '80%' : '100%', fontWeight: 'bold', color: 'gray'}}>{entity.district ? entity.district : entity.town}</span>
						{entity.rate && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
							<span style={{fontWeight: 'bold', color: 'gray'}}>{entity.rate}</span>
							<StarFilled style={{color: '#fadb14', fontSize: '12px'}} />
						</div>}
					</div>
					<div className="desc2style" style={{alignItems: 'center', fontWeight: 'bold', fontSize: '16px'}}>
						{entity.name}
					</div>
				</div>
				<DirectionBtn direction={entity} />
			</div>
		)
	}
}



const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`

export const RouteProperty = (props) => {
	const size = props.mobile ? '10px' : '20px'
  return (
    <Tooltip title={props.title} >
      <div style={{
        marginRight: props.mobile ? '5px' : '10px', 
        fontWeight: props.bold ? 'bold' : null,
        display: 'flex',
        alignItems: 'center',
				fontSize: props.mobile ? '9px' : null
      }}>
        {props.icon ? <img src={src(props.icon)} style={{height: size, width: size}} /> : null }
				{props.value}
      </div>
    </Tooltip>
  )
}

export const RouteDirections = props => {
  return (
    <>
      <h4 style={{color: 'white'}}>Направления</h4>
      <br />
      {props.directions.map(direction=>{return (
        <p>
          <img src={src(direction.icon)} style={{height: '20px', width: '20px'}}/>
          {direction.name}
        </p>
      )})}
    </>
  )
}



const RouteDesc = ({entity, mobile}) => {
	if (mobile) {
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<Tooltip title="Отправление">
						<h6 style={{...descStyle, width: entity.transfers.length > 0 ? '60%' : '100%'}}>
							<FaFlagCheckered style={{color: '#40a9ff'}} />
							{entity.directions[0].town ? entity.directions[0].town : entity.directions[0].name}
						</h6>
					</Tooltip>
					{entity.transfers.length > 0 ? <h6>{`от ${entity.transfers[0].min_price} ₽`}</h6> : null}
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: "flex", alignItems: 'center', justifyContent: 'left'}}>
						<RouteProperty icon='marker' title={<RouteDirections directions={entity.directions} />} value={entity.directions.length} mobile />
						<RouteProperty title="Продолжительность, ч" value={`~${minTommss(entity.duration)}ч`} mobile/>
						<RouteProperty title="Расстояние, км" value={`${entity.distance}км`} mobile />
					</div>
					{entity.rate ? <div style={{display: "flex", alignItems: 'center'}} >&nbsp;<h6>{entity.rate}</h6><StarFilled style={{color: '#fadb14', fontSize: '12px', marginBottom: '5px'}} /></div> : null}
				</div>
			</div>
		)
	} else {
		return (
			<div style={{display: 'flex', flexDirection: 'column', padding: '20px', width: 'auto', marginTop: '0px'}}>
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
					<div className="desc2style" style={{fontWeight: 'bold', fontSize: '16px'}}>
						{entity.name}
					</div>
					{entity.rate && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<span style={{fontWeight: 'bold', color: 'gray'}}>{entity.rate}</span>
						<StarFilled style={{color: '#fadb14', fontSize: '12px'}} />
					</div>}
				</div>
				{/* {entity.transfers.length > 0 ? <span style={{color: 'gray', fontSize: '16px', fontWeight: 'bold'}}>{`₽ от ${entity.transfers[0].min_price}`}</span> : null} */}
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', height: '33px'}}>
					<Input size='small' value="любая дата" style={{maxHeight: '20px', maxWidth: '100px'}} />
					<h3 style={{fontWeight: 'bold'}}>{entity.price && `${entity.price} ₽`}</h3>
				</div>
				<span style={{color: 'gray', marginBottom: '5px'}}>{`${entity.directions.length} локаций, ${minTommss2(entity.duration)}`}</span>
			</div>
		)
	}
}






const MyrouteDesc = ({entity, mobile}) => {
	if (mobile) {
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<Tooltip title="Отправление">
						<h6 style={{...descStyle, width: entity.transfers.length > 0 ? '60%' : '100%'}}>
							<FaFlagCheckered style={{color: '#40a9ff'}} />
							{entity.directions[0].town ? entity.directions[0].town : entity.directions[0].name}
						</h6>
					</Tooltip>
					{entity.transfers.length > 0 ? <h6>{`от ${entity.transfers[0].min_price} ₽`}</h6> : null}
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: "flex", alignItems: 'center', justifyContent: 'left'}}>
						<RouteProperty icon='marker' title={<RouteDirections directions={entity.directions} />} value={entity.directions.length} mobile />
						<RouteProperty title="Продолжительность, ч" value={`~${minTommss(entity.duration)}ч`} mobile/>
						<RouteProperty title="Расстояние, км" value={`${entity.distance}км`} mobile />
					</div>
					{entity.rate ? <div style={{display: "flex", alignItems: 'center'}} >&nbsp;<h6>{entity.rate}</h6><StarFilled style={{color: '#fadb14', fontSize: '12px', marginBottom: '5px'}} /></div> : null}
				</div>
			</div>
		)
	} else {
		return (
			<div style={{display: 'flex', flexDirection: 'column', width: 'auto', margin: '0px'}}>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', margin: '20px'}}>
					<div className="desc2style" style={{fontWeight: 'bold', fontSize: '16px'}}>
						{entity.name}
					</div>
					<span style={{color: 'gray', marginBottom: '5px'}}>{`${entity.directions.length} локаций, ${minTommss2(entity.duration)}`}</span>
				</div>
				<MyrouteBtn myroute={entity} />
			</div>
		)
	}
}






const TransferDesc = ({entity, mobile}) => {
	const transferType = (type) => {
    switch (type) {
      case "minibus": return "Микроавтобус"        
      case "offroad_van": return "Минивен 4x4" 
      case "offroad": return "Внедорожник" 
      default: break;
    }
  }

	if (mobile) {
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<h6 style={{...descStyle, width: '60%'}}>{transferType(entity.type)}</h6>
					{entity.min_price&&<h6>{`${entity.min_price} ₽`}</h6>}
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: "flex", justifyContent: 'left'}}>
						<RouteProperty icon='seats' title="Количество пассажиров" value={entity.seats} mobile />
            {entity.food_cnt&&<RouteProperty icon='food' title={`Питание в пути, до ${entity.food_cnt} раз`} value={entity.food_cnt} mobile />}
            {entity.child&&<RouteProperty icon='child' title="Можно с детьми" mobile />}
					</div>
					{entity.rate ? <div style={{display: "flex", alignItems: 'center'}} >&nbsp;<h6>{entity.rate}</h6><StarFilled style={{color: '#fadb14', fontSize: '12px', marginBottom: '5px'}} /></div> : null}
				</div>
				<div style={{display: 'flex', justifyContent: 'right'}} >
					{entity.min_price&&<TransferBtn transfer={entity} mobile />}
				</div>
			</div>
		)
	} else {
		return (
			<>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<h3 style={{...descStyle, width: '60%'}}>{transferType(entity.type)}</h3>
					{entity.min_price&&<h3>{`${entity.min_price} ₽`}</h3>}
				</div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: "flex", justifyContent: 'left'}}>
						<RouteProperty icon='seats' title="Количество пассажиров" value={entity.seats} bold />
            {entity.food_cnt&&<RouteProperty icon='food' title={`Питание в пути, до ${entity.food_cnt} раз`} value={entity.food_cnt} bold />}
            {entity.child&&<RouteProperty icon='child' title="Можно с детьми" bold />}
					</div>
					{entity.rate ? <div style={{display: "flex", alignItems: 'center'}} >&nbsp;<h3>{entity.rate}</h3><StarFilled style={{color: '#fadb14', fontSize: '12px', marginBottom: '5px'}} /></div> : null}
				</div>
				<div style={{display: 'flex', justifyContent: 'right'}} >
					{entity.min_price&&<TransferBtn transfer={entity} />}
				</div>
			</>
		)
	}
}









const Cover = ({entity, type, mobile, width}) => {
	const [ava, setAva] = useState(mobile ? entity.avatar : entity.logo)
	
	useEffect(()=>{
		if (type==='myroute') {
			const directionImages = entity.directions.map(d=>d.images).shift(1)
			if (directionImages.length>0) {setAva(directionImages[0])}
		} else {setAva(mobile ? entity.avatar : entity.logo)}
	}, [])
	
  const Title = () => {
		// return mobile 
		// 	?
		// 	<h5 className="cover-title" >
		// 		&nbsp;{type==='transfer' ? `${entity.brand} ${entity.model}` : entity.name}
    //   </h5>
		// 	: null
		return null
	}

	const routeTypesColors = {
		'Джип тур': 'brown',
		'Восхождение': 'gray',
		'Групповой': 'green',
		'Пешая прогулка': 'gold',
		'Поход': 'blue',
	}

  return (
    <div 
			style={{
				width: width, 
				height: mobile ? width : Math.round(width*7/8), 
				backgroundImage: `url("${ava}")`, 
				backgroundSize: 'cover', 
				backgroundPosition: 'center',
				borderTopRightRadius: '8px',
				borderTopLeftRadius: '8px',
			}}
		>
      <div style={{right: 0, position: 'absolute', width: '100%', alignItems: 'center'}}>
				<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
					{ (type==='route' && entity.type) ? <Badge.Ribbon placement="start" color={routeTypesColors[entity.type]}  text={entity.type} /> : <div /> }
      		{ type!=='myroute' && <Favorite fav_id={entity.id}  add={entity.fav ? true : false} type={type} /> }
				</div>
      </div>
      <Title />
    </div>
  )
}









const EntityCard = ({entity, selectEntity, w, type, mobile, hideAdd}) => {
	if (!mobile) {mobile = w > 599 ? false : true}
	const preWidth = mobile ? 130 : 250
  const width = Math.floor((w-(Math.floor(w/preWidth)*20))/Math.floor(w/preWidth))
	
	var description
	if (type==='direction') {description=<DirectionDesc entity={entity} mobile={mobile} hideAdd={hideAdd} />}
	else if (type==='route') {description=<RouteDesc entity={entity} mobile={mobile} />}
	else if (type==='myroute') {description=<MyrouteDesc entity={entity} mobile={mobile} />}
	else if (type==='transfer') {description=<TransferDesc entity={entity} mobile={mobile} />}

	return (
    <div 
      onClick={(e)=>{if (e.target.localName==='div' && type!=='myroute') {selectEntity({...entity, images: `["${entity.images.join('","')}"]`})}}} 
      style={{width: width, display: 'inline-block', margin: '13px'}}
      key={entity.id}
    >
			<Card
				hoverable
				size='small'
				style={{width: width, height: Math.round(width*1.35)}}
			>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: Math.round(width*1.35), borderRadius: '2px'}}>
					<Cover entity={entity} width={width} type={type} mobile={mobile} />
					{type==='direction' && <DirectionDesc entity={entity} mobile={mobile} hideAdd={hideAdd} />}
					{type==='route' && <RouteDesc entity={entity} mobile={mobile} />}
					{type==='myroute' && <MyrouteDesc entity={entity} mobile={mobile} />}
					{type==='transfer' && <TransferDesc entity={entity} mobile={mobile} />}
				</div>
			</Card>
    </div>
  )
}



export default EntityCard