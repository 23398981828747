import {
    ACTION_ROUTE_DATA,
    ACTION_ROUTE_VISIBLE,
  } from "./types";
  
  

export const actionRouteVisible = (visible) => ({
  type: ACTION_ROUTE_VISIBLE,
  payload: visible
});

export const actionRouteData = (route) => ({
  type: ACTION_ROUTE_DATA,
  payload: route
});
  