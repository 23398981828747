import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Drawer, Menu, Divider } from 'antd'
import { 
  AiOutlineMenu, 
  AiOutlinePhone, 
} from "react-icons/ai";
import { FaCarSide, FaCommentDollar } from "react-icons/fa";
import { FiUserPlus, FiUserCheck, FiSettings } from "react-icons/fi";
import { FaRegHandshake, FaRoute, FaMapMarkerAlt } from "react-icons/fa";
import { BiNavigation } from "react-icons/bi"
import { VscSignOut } from "react-icons/vsc"
import { MdOutlineAltRoute } from "react-icons/md"
import { actionMenuVisible } from "./../../actions/menu"
import { 
  actionDirectionsVisible,  
} from "./../../actions/directions"
import { 
  actionRoutesVisible,  
} from "./../../actions/routes"
import { 
  actionTransfersVisible,  
} from "./../../actions/transfers"
import Signup from './../auth/signup'
import Signin from './../auth/signin'
import Promo from './promo'
import Settings from './settings'


const queryString = require('query-string');




const UserMenu = (props) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [signupModalVisible, setSignupModalVisible] = useState(false)
  const [signinModalVisible, setSigninModalVisible] = useState(false)
  const [promoModalVisible, setPromoModalVisible] = useState(false)
  const [settingsModalVisible, setSettingsModalVisible] = useState(false)


  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setLoggedIn(true)
      // ссылка приглашение
      const params = queryString.parse(window.location.search)
      if (window.location.pathname.substr(1)==='invite' && params.id!==undefined) {
        props.menuVisible(true)
      }
    }
  }, [])



  



  const Footer = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <p>Made with ❤️</p>
      </div>
    )
  }
  
  
  const SubMenu = () => {
    const handleClick = (e) => {
      switch (e.key) {
        case 'signin':
          setSigninModalVisible(true)
          break
        case 'signup':
          setSignupModalVisible(true)
          break
        case 'settings':
          setSettingsModalVisible(true)
          break
        case 'directions':
          props.directionsVisible(true)
          break
        case 'routes':
          props.routesVisible(true)
          break
        case 'routehistory':
          props.routehistoryVisible(true)
          break
        case 'promo':
          setPromoModalVisible(true)
          break
        case 'transfer':
          props.transfersVisible(true)
          break
        case 'partnership':
          // setTest1(true)
          break
        case 'signout':
          localStorage.removeItem("user");
          setLoggedIn(false)
        default:
          break
      }
    }
    return (
      <Menu onClick={handleClick} mode="vertical" >
        {/* {!loggedIn && <Menu.Item key="auth" >
          <SigninSignup />
        </Menu.Item>} */}
        {!loggedIn && <Menu.Item key="signin" icon={<FiUserCheck />}>
          Войти
        </Menu.Item>}
        {!loggedIn && <Menu.Item key="signup" icon={<FiUserPlus />} style={{color: "#1890ff"}}>
          Регистрация
        </Menu.Item>}
        <Menu.Item key="directions" icon={<FaMapMarkerAlt />}>
          Направления
        </Menu.Item>
        <Menu.Item key="routes" icon={<FaRoute />}>
          Маршруты
        </Menu.Item>
        {loggedIn && <Menu.Item key="routeHistory" icon={<MdOutlineAltRoute />}>
          История маршрутов
        </Menu.Item>}
        <Menu.Item key="transfer" icon={<FaCarSide />}>
          Трансфер
        </Menu.Item>
        <Menu.Item key="promo" icon={<FaCommentDollar />}>
          Акции
        </Menu.Item>
        <Menu.Item key="partnership" icon={<FaRegHandshake />}>
          {/* <a href="/partner" >Сотрудничество</a> */}
          Сотрудничество
        </Menu.Item>
        {loggedIn && <Menu.Item key="settings" icon={<FiSettings />}>
          Настройки
        </Menu.Item>}
        {loggedIn && <Menu.Item key="signout" icon={<VscSignOut />} style={{color: "red"}}>
          Выйти
        </Menu.Item>}
        <Divider style={{marginTop: '50px'}}/>
        <Menu.Item key="phone" icon={<AiOutlinePhone />}>
        <a target="_blank" href="tel:+7-915-250-8332" style={{fontWeight: 'bold'}}>+7 (915) 250 8332</a>
        </Menu.Item>
        <div style={{display: 'flex', justifyContent: 'block'}}>
          <a 
            style={{marginLeft: '10px'}} 
            href="https://www.instagram.com/rocktrip.ru" 
          >
            <img src="http://rocktrip.ru/storage/instagram.png" style={{height: '40px', width: '40px'}} />
          </a>
          <a 
            style={{marginLeft: '10px'}} 
            href="https://wa.me/79152508332" 
          >
            <img src="http://rocktrip.ru/storage/whatsapp.png" style={{height: '40px', width: '40px'}} />
          </a>
          <a 
            style={{marginLeft: '10px'}} 
            href="mailto:info@rocktrip.ru" 
          >
            <img src="http://rocktrip.ru/storage/email.png" style={{height: '40px', width: '40px'}} />
          </a>
        </div>
        </Menu>
    )
  }


  const onMenuBtnClick = () => {
    props.menuVisible(true)
  }
  
  const handleOk = () => {
    props.menuVisible(false)
  }

  const handleCancel = () => {
    props.menuVisible(false)
  }


  return (
    <>
      <Button 
        // icon={<AiOutlineMenu />} 
        style={{marginLeft: '3px', paddingTop: props.full ? '0px': null}} 
        onClick={onMenuBtnClick}
        type='text'
        shape={props.full ? null : "circle"}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <AiOutlineMenu style={{marginBottom: props.full ? '17px': null}} />
          {props.full ? <p style={{fontWeight: 'bold', fontSize: '18px'}}>&nbsp;Меню</p> : null }
        </div>
      </Button>
      <Drawer
        title={
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src="http://rocktrip.ru/storage/mainLogo.png" style={{width: '60px', marginRight: '7px'}} />
            <h2 style={{color: '#1890ff'}}>Rocktrip</h2>
          </div>
        }
        width='264px'
        onClose={handleCancel}
        visible={props.visible}
        bodyStyle={{position: "relative"}}
        className='vertical'
        placement="left"
        footer={<Footer />}
      >
        <Signin visible={signinModalVisible} setVisible={setSigninModalVisible}/>
        <Signup visible={signupModalVisible} setVisible={setSignupModalVisible} userType="transfer_guide" />
        {/* <Invite visible={inviteModalVisible} setVisible={setInviteModalVisible}/> */}
        <Promo visible={promoModalVisible} setVisible={setPromoModalVisible}/>
        {/* <Transfer visible={transferModalVisible} setVisible={setTransferModalVisible}/> */}
        {/* {!loggedIn && <SigninSignup setSignupModalVisible={setSignupModalVisible} setSigninModalVisible={setSigninModalVisible} />} */}
        {loggedIn && <Settings visible={settingsModalVisible} setVisible={setSettingsModalVisible} />}
        <SubMenu style={{position: "absolute"}} />
        {/* <div style={{position: "absolute", bottom: 0}}>
          <SubMenuBottom />
        </div> */}
      </Drawer>
    </>
  );
};



const mapStateToProps = (state) => {
  return {
    visible: state.menuReducer.visible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  menuVisible: (visible) => {
    dispatch(actionMenuVisible(visible))
  },
  directionsVisible: (visible) => {
    dispatch(actionDirectionsVisible(visible))
  },
  routesVisible: (visible) => {
    dispatch(actionRoutesVisible(visible))
  },
  transfersVisible: (visible) => {
    dispatch(actionTransfersVisible(visible))
  },
  routehistoryVisible: () => {

  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)