import React, { useState } from "react";

import { message, Form, Modal, Button, Input, Avatar } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { post } from "./../../api/fetchData";
import { history, rule } from './../../helpers/helpers';



const Signin = props => {

  const [form] = Form.useForm();

  const handleSignin = async (setLoading) => {
    const fields = form.getFieldsValue()
    setLoading(true)
    const resp = await post('signin', undefined, fields)
    if (resp.status===200 && resp.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(resp.data))
      window.location.reload()
    } else if (fields.phone===undefined) {
      message.error('Укажите номер телефона')
    } else if (fields.password===undefined) {
      message.error('Заполните пароль')
    } else {
      message.error(resp.data.detail)
    }
    setLoading(false)
  }

  const CancelBtn = () => {
    return <Button onClick={()=>props.setVisible(false)} >Отмена</Button>
  }

  const OkBtn = () => {
    const [loading, setLoading] = useState(false)

    return (
      <Button
        key={Math.random()}
        onClick={()=>handleSignin(setLoading)}
        type="primary"
        loading={loading}
      >Войти</Button>
    )
  }


  const Phone = () => {
    const [valid, setValid] = useState(true)
    const [phone, setPhone] = useState("")

    const checkPhone = async (value) => {
      if (value) {
        setPhone(value)
        if (value!=="") {
          if (value.length>10) {
            setValid(true)
          }
        }
        setValid(false)
      }
    }

    return (
      <Form.Item label="Телефон" name="phone" >
        <PhoneInput
          defaultCountry='RU'
        />
      </Form.Item>
    )
  }







  return (
    <Modal
      title="Вход"
      key={Math.random()}
      visible={props.visible}
      onCancel={()=>props.setVisible(false)}
      footer={[<CancelBtn key={Math.random()} />, <OkBtn key={Math.random()} />]}
    >
      <Form
        layout="vertical"
        name="basicForm"
        form={form}
        // initialValues={props.initialValues}
        onFinish={handleSignin}
      >
      <div style={{ textAlign: 'center' }}>
        <Avatar size={128} icon={<UserOutlined />} style={{textAlign: 'center'}}/>
        </div>
      <br />
      <Phone />
      <Form.Item
        label="Пароль"
        name="password"
        // rules={rule}
      >
      <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
    </Form.Item>
  </Form>
</Modal>

  )
}


// function mapStateToProps(state) {
//   const { isLoggedIn } = state.authReducer;
//   const { message } = state.messageReducer;
//   return {
//     isLoggedIn,
//     message
//   };
// }

export default Signin
