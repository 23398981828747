import { useState, useEffect } from 'react'
import Avatar from 'react-avatar-edit'


const AvatarLogo = (props) => {
	const [avatar, setavatar] = useState(null)
  const src='http://rocktrip.ru/storage/directions/61b4bfb441706965e9f59c3f/logo.png'
  
  useEffect(()=>{
    if (typeof(props.form.getFieldValue('logo'))==="string") {
      props.form.setFieldsValue({logo: null, avatar: null})
    }
  }, [])

  const onClose = () => {
    setavatar(null)
  }
  
  const onCrop = async (avatar) => {
    setavatar(avatar)
    // const ava = await getBase64(avatar)
    console.log(avatar)
    props.form.setFieldsValue({avatar: [avatar]})
  }

  const onBeforeFileLoad = (elem) => {
    // if (elem.target.files[0].size > 71680) {
    //   alert("Слишком большой файл!");
    //   elem.target.value = "";
    // };
		console.log(elem)
  }

  const onFileLoad = async (file) => {
    const logo = await getBase64(file)
    props.form.setFieldsValue({logo: [logo]})
    console.log(file)
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '10px', verticalAlign: 'middle'}}>
      <Avatar
        width={300}
        height={200}
        onCrop={onCrop}
        onClose={onClose}
        onBeforeFileLoad={onBeforeFileLoad}
        // src={src}
        onFileLoad={onFileLoad}
        label="Загрузить лого"
        exportAsSquare={true}
      />
      {avatar===null 
        ? <div style={{height: '200px', width: '200px'}}>Аватар</div>
        : <img src={avatar} alt="Аватар" style={{height: '200px', width: '200px'}} />
      }
    </div>
  )
}


export default AvatarLogo