import {
    ACTION_FAVORITES_VISIBLE,
  } from "./types";
  
  

export const actionFavoritesVisible = (visible) => ({
  type: ACTION_FAVORITES_VISIBLE,
  payload: visible
});
  