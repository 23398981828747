import {
    ACTION_ROUTES_DATA,
    ACTION_ROUTES_VISIBLE,
  } from "./types";
  
  

export const actionRoutesVisible = (visible) => ({
  type: ACTION_ROUTES_VISIBLE,
  payload: visible
});

export const actionRoutesData = (routes) => ({
  type: ACTION_ROUTES_DATA,
  payload: routes
});
  