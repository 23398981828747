import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, CheckBox, Radio } from 'antd'
import { AiOutlineFilter } from "react-icons/ai";
import { actionFilterVisible } from "./../../actions/filter"
import { actionRollVisible } from "./../../actions/roll"


const Filter = (props) => {

  const onFilterBtnClick = () => {
    console.log(props.filterVisible)
    props.setFilterVisible(true)
  }
  
  const handleOk = () => {
    props.setFilterVisible(false)
  }

  const handleCancel = () => {
    props.setFilterVisible(false)
  }

  const HideRoll = () => {
    const [value, setValue] = useState(props.rollVisible ? "unhideRoll" : "hideRoll")
    const onChange = (e) => {
      if (e.target.value==='unhideRoll') {props.setRollVisible(true); setValue("unhideRoll")}
      else {props.setRollVisible(false); setValue("hideRoll")}
    }
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Radio value="unhideRoll">Список</Radio>
        <Radio value="hideRoll">Карта</Radio>
      </Radio.Group>
    )
  }

  const SearchSource = () => {
    const [value, setValue] = useState("direction&route")
    const onChange = (e) => {
      if (e.target.value==='direction&route') {setValue("direction&route")}
      else if (e.target.value==='direction') {setValue("direction")}
      else {setValue("route")}
    }
    return (
      <Radio.Group onChange={onChange} value={value}>
        <Radio value="direction&route">Направления и маршруты</Radio>
        <Radio value="direction">Направления</Radio>
        <Radio value="route">Маршруты</Radio>
      </Radio.Group>
    )
  }

    
  return (
    <Tooltip title="Фильтр" >
      <Button 
        icon={<AiOutlineFilter />} 
        style={{marginLeft: '3px'}} 
        onClick={onFilterBtnClick}
        type='text'
      />
      <Modal
        visible={props.filterVisible}
        title="Фильтровать"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <HideRoll />
        <SearchSource />
      </Modal>
    </Tooltip>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    filterVisible: state.filterReducer.visible,
    rollVisible: state.filterReducer.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    setFilterVisible: (visible) => {
      dispatch(actionFilterVisible(visible));
    },
    setRollVisible: (visible) => {
      dispatch(actionRollVisible(visible));
    },
})}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)