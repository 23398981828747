import { useEffect } from "react"
import { Button, Tooltip, Popover, Select, Input } from 'antd'
import { connect } from 'react-redux'
import { AiOutlineFilter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { actionRollVisible } from "./../../actions/roll"




const Search = props => {
    
  return (
    <div style={{width: '100%', marginRight: '3px', float: 'left'}} >
      <Input 
        onClick={()=>props.rollVisible(true)}
        bordered={false}
        placeholder={"Куда поехать"} 
        prefix={<BsSearch style={{marginRight: '5px', color: 'gray'}} />}
      />
    </div>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.rollReducer.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    rollVisible: (visible) => {
      dispatch(actionRollVisible(visible));
    },
})}

export default connect(mapStateToProps, mapDispatchToProps)(Search)