import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Select, Slider, Button, Popover, Tree, Checkbox, Row, Col, Divider } from 'antd'
import { CloseOutlined, CarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { FaFlagCheckered, FaMapMarkerAlt } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5'
import { BiTime } from 'react-icons/bi';
import { get } from "./../../api/fetchData";
import { actionRoutesFilter } from './../../actions/filter'
import { Rate, Fav, filterStyle, opened, closed, Search } from './commonFilters'

const { Option } = Select;

const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`







const Duration = props => {
  const [closable, setClosable] = useState(closed)
  var timeout;

  const onChange = value => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(()=>{
      setClosable(opened)
      props.setFilter({...props.filter, min_duration: value[0], max_duration: value[1]})
    }, 1000)
  }

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, min_duration: undefined, max_duration: undefined})
  }

  return (
    <div style={filterStyle}>
      <Popover trigger="click" placement="bottom" content={
          <Slider
            style={{width: '300px'}}
            range
            onChange={onChange} 
            defaultValue={[0, 24]}
            max={24}
            step={1}
          />
        }

      >
        <Button
          type={closable.type}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
          icon={<ClockCircleOutlined />}
        >
          {/* <img src='http://rocktrip.ru/storage/icons/duration.png' style={{width: 20, height: 20}}/>Длительность */}
          Длительность
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}




const Price = props => {
  const [closable, setClosable] = useState(closed)
  var timeout;

  const onChange = value => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(()=>{
      setClosable(opened)
      props.setFilter({...props.filter, min_price: value[0], max_price: value[1]})
    }, 1000)
  }

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, min_price: undefined, max_price: undefined})
  }


  return (
    <div style={filterStyle}>
      <Popover trigger="click" placement="bottom" content={
          <Slider
            style={{width: '300px'}}
            range
            onChange={onChange} 
            // dots
            defaultValue={[0, 100000]}
            max={100000}
            // marks={[0, 100000]}
            step={1000}
          />
        }

      >
        <Button
          type={closable.type}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
        >
          <span style={{fontWeight: 'bold'}}>₽</span>&nbsp;Цена
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}




const StartLocation = () => {
  const [closable, setClosable] = useState(closed)
  const [data, setData] = useState()
  
  useEffect(()=>{
    const f = async () => {
      const resp = await get("my_directions", {selected: []})
      setData(resp.data)
    }
    f()
  }, [])

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck = async (checkedKeys, info, id) => {
    console.log('onCheck', info);
    // console.log(info.checked, info.checkedNodes)//.filter(node=>!node.children))
    var ids = []
    if (!info.node.children) { ids.push(info.node.key) }
    else { info.node.children.map(node1=>{
      if (!node1.children) { ids.push(node1.key); return }
      node1.children.map(node2=>{
        if (!node2.children) { ids.push(node2.key); return }
        node2.children.map(node3=>{
          if (!node3.children) { ids.push(node3.key); return }
        })
      })
    }) }
    console.log(ids)
    // let formData = new FormData
    // formData.append('directions', ids)
    // await post('add_delete_my_directions', {id: id, action: info.checked ? 'add' : 'delete'}, formData)
  };

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          data&&
          <Tree
            checkable
            onSelect={onSelect}
            // onCheck={onCheck}
            defaultExpandedKeys={data.params.defaultExpandedKeys}
            defaultCheckedKeys={data.params.defaultCheckedKeys}
            onCheck={(checkedKeys, info)=>onCheck(checkedKeys, info, data.transfer.id)}
            treeData={data.data}
          />
        }
      >
        <Button
          type={closable.type}
          icon={<FaFlagCheckered style={{color: '#40a9ff'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
        >
          &nbsp;Старт
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={()=>setClosable(closed)} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}


const Directions = () => {
  const [closable, setClosable] = useState(closed)
  const [data, setData] = useState()
  
  useEffect(()=>{
    const f = async () => {
      const resp = await get("my_directions", {selected: []})
      setData(resp.data)
    }
    f()
  }, [])

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck = async (checkedKeys, info, id) => {
    console.log('onCheck', info);
    // console.log(info.checked, info.checkedNodes)//.filter(node=>!node.children))
    var ids = []
    if (!info.node.children) { ids.push(info.node.key) }
    else { info.node.children.map(node1=>{
      if (!node1.children) { ids.push(node1.key); return }
      node1.children.map(node2=>{
        if (!node2.children) { ids.push(node2.key); return }
        node2.children.map(node3=>{
          if (!node3.children) { ids.push(node3.key); return }
        })
      })
    }) }
    console.log(ids)
    // let formData = new FormData
    // formData.append('directions', ids)
    // await post('add_delete_my_directions', {id: id, action: info.checked ? 'add' : 'delete'}, formData)
  };

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          data&&
          <Tree
            checkable
            onSelect={onSelect}
            // onCheck={onCheck}
            defaultExpandedKeys={data.params.defaultExpandedKeys}
            defaultCheckedKeys={data.params.defaultCheckedKeys}
            onCheck={(checkedKeys, info)=>onCheck(checkedKeys, info, data.transfer.id)}
            treeData={data.data}
          />
        }
      >
        <Button
          type={closable.type}
          icon={<IoLocationOutline style={{color: 'black'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
        >
          &nbsp;Направления
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={()=>setClosable(closed)} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}




const types = [
  'Джип тур',
  'Восхождение',
  'Групповой',
  'Пешая прогулка',
  'Поход',
]

const Type = props => {
  const [closable, setClosable] = useState(closed)

  const onChange = list => {
    setClosable(opened)
    props.setFilter({...props.filter, type: list.join(',')})
  };

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, type: undefined})
  }


  return (
    <div style={filterStyle}>
      <Popover trigger="click" placement="bottom" content={
          <>
            <Tree
              checkable
              defaultExpandedKeys={['all']}
              checkedKeys={props.filter&&props.filter.type&&props.filter.type.split(',')}
              onCheck={onChange}
              // treeData={[{title: 'Все', key: 'all', children: types.map(o=>({title: <Title option={o} key={Math.random()} />, key: o.key}))}]}
              treeData={[{title: 'Все', key: 'all', children: types.map(o=>({title: o, key: o}))}]}
            />
          </>
        }
      >
        <Button
          type={closable.type}
          // icon={<IoLocationOutline style={{color: '#40a9ff'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
          // icon={<GiMountainClimbing />}
          icon={<CarOutlined />}
        >
          {/* <img src='http://rocktrip.ru/storage/icons/tower.png' style={{width: 20, height: 20}}/>Тип */}
          Тип
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}




// return (
//   <div style={filterStyle}>
//     <Popover
//       trigger="click"
//       placement="bottom"
//       content={
//         <>
//           <Row>
//             <Col>
//               <Checkbox onChange={onCheckAllChange} checked={checkAll}>
//                 { checkedList.length===options.length ? "Снять все" : "Выделить все" }
//               </Checkbox>
//             </Col>
//           </Row>
//           <Checkbox.Group value={checkedList} onChange={onChange} >
//             <Divider />
//             {options.map(option=>{
//               return (
//                 <Row>
//                   <Col>
//                     <Checkbox value={option.key}>
//                       <img src={`http://rocktrip.ru/storage/icons/${option.key}.png`} style={{height: '20px'}} />
//                       &nbsp;{option.label}
//                     </Checkbox>
//                   </Col>
//                 </Row>
//               )
//             })}
//           </Checkbox.Group>
//         </>
//       }
//     >





const RoutesFilter = props => {

  return (
    <div style={{marginBottom: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', backgroundColor: '#EEEEEE'}}>
      <div style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
        {/* <StartLocation setFilter={props.setFilter} filter={props.filter} /> */}
        <Directions setFilter={props.setFilter} filter={props.filter} />
        <Type setFilter={props.setFilter} filter={props.filter} />
        <Duration setFilter={props.setFilter} filter={props.filter} />
        <Price setFilter={props.setFilter} filter={props.filter} />
        <Rate setFilter={props.setFilter} filter={props.filter} />
        <Fav setFilter={props.setFilter} filter={props.filter} />
      </div>
      <Search setFilter={props.setFilter} filter={props.filter} />
    </div>         
  )
}





const mapStateToProps = state => ({filter: state.filterReducer.routes_filter})
const mapDispatchToProps = dispatch => ({setFilter: filter => {dispatch(actionRoutesFilter(filter))}})


export default connect(mapStateToProps, mapDispatchToProps)(RoutesFilter)