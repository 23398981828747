const initialMetaState = {
  visible: false,
};

export const menuReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_MENU_VISIBLE":
      console.log(action)
      return { ...state, visible: action.payload };
    default:
      return state;
  }
};
