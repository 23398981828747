const initialMetaState = {
  visible: false,
  zIndex: 10,
  route: {images: "[]"}
};

export const routeReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_ROUTE_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_ROUTE_DATA":
      return { ...state, route: action.payload };
    default:
      return state;
  }
};
