import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Input, Tag, Button, Row, Col, Tooltip, notification, Result,
  Badge, Modal, Drawer, Divider, DatePicker, TimePicker, Checkbox, InputNumber, Form, Spin
} from 'antd'
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons'
import { FaRoute } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BsArrowRepeat } from "react-icons/bs"
import { BiSort } from "react-icons/bi"

import { List, arrayMove } from "react-movable";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { 
  actionMyRouteVisible,  
  actionMyRouteRemove,  
  actionMyRouteMove,
  actionMyRouteAdd,
} from "./../../actions/myroute"
import { actionDirectionsVisible } from "./../../actions/directions"
import { actionTransfersDistance, actionTransfersSelected } from "./../../actions/transfers"
import SelectTransfer from './../transfers/selectTransfer'
import { post } from "./../../api/fetchData";
import { optiCoords } from "./../map"
import { minTommss } from './../../helpers/helpers'




const rule = [{required: true, message: 'Обязательное поле',}]






export const BookForm = props => {
  return (
    <div style={{margin: '20px'}}>
      <Divider />
      {/* <Form
        layout="horizontal"
        name="basicForm"
        form={props.form}
        onFinish={props.handleBook}
      > */}
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item name="phone" >
              <Input size='large' placeholder='Телефон' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="username" >
              <Input size='large' placeholder='Имя' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item name="date" >
              <DatePicker size='large' style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="name" >
              <Button onClick={props.handleBook} type="primary" size='large' style={{marginBottom: '20px', width: '100%'}}>
                Забронировать маршрут
              </Button>
            </Form.Item>
          </Col>
        </Row>
      {/* </Form> */}
    </div>
  )
}














const MyRoute = props => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
  const [disable, setDisable] = useState(true)
  const [ordered, setOrdered] = useState(false)


  const myRef = useRef()
  const [form] = Form.useForm();
  const legs = props.legs

  const w = window.innerWidth-40
  const h = window.innerHeight
  const distance = legs.length>0&&Math.round(legs.map(l=>l.distance).reduce((a,b)=>a+b)/100)/10
  const duration = legs.length>0&&Math.round(legs.map(l=>l.duration).reduce((a,b)=>a+b)/360)/10
  const lastDirIndex = props.myRoute.length-1


  useEffect(()=>{
    if (props.visible) {
      setUser(localStorage.getItem("user"))
      form.setFieldsValue({username: props.user.username, phone: props.user.phone})
    }
  }, [props.visible])

  const handleCancel = key => {
    props.myRouteMove(props.myRoute.filter((r, i)=>i!==key))
    if (props.myRoute.filter((r, i)=>i!==key).length===0) {
      props.myRouteVisible(false);
      props.cancelTransfers()
    }
  }

  const addReverseRoute = () => {
    props.myRouteMove(props.myRoute.concat(props.myRoute[0]))
  }


  const handleBook = async () => {
    setLoading(true)
    form.setFieldsValue({directions: props.myRoute.map(d=>d.id)})
    form.setFieldsValue({transfers: props.transfers_selected.map(t=>{return({...t, directions: undefined})})})//.map(t=>t.id)})
    form.setFieldsValue({distance: Math.round(legs.map(l=>l.distance).reduce((a,b)=>a+b)/100)/10})
    form.setFieldsValue({duration: Math.round(legs.map(l=>l.duration).reduce((a,b)=>a+b)/360)/10})
    form.setFieldsValue({user_id: user ? JSON.parse(user).id : null})
    // const resp = await post('addroutehistory', {edit: false}, form.getFieldsValue())
    // if (resp.status===200) {
    //   notification['success']({
    //     message: 'Тур сформирован',
    //     description: 'Ожидайте звонка оператора для окончательного подтверждения тура.',
    //   })
    // }
    console.log(form.getFieldsValue())
    setOrdered(true)
    setLoading(false)
  }

  
  const Clear = () => {
    const onClick = () => {props.myRouteMove([]); props.myRouteVisible(false); props.cancelTransfers() }
    return <Button onClick={onClick} style={{marginRight: '10px'}} size='small'> Очистить </Button>
  }
  
  const RollUp = () => <Button 
    onClick={()=>props.myRouteVisible(false)} 
    icon={<CloseOutlined />} 
    type='text'
    size='large'
  />

  const SaveMyRoute = () => {
    const [open, setOpen] = useState(false)
    const [name, setName] = useState()
    const [status, setStatus] = useState()

    const onSave = async () => {
      if (!name || name==='') {setStatus('error'); return}
      var formData = new FormData()
      formData.append('data', props.myRoute.map(d=>d.id).join(','))
      // const resp = await post('savemyroute', {name: name}, {data: props.myRoute.map(d=>d.id).join(',')})
      const resp = await post('savemyroute', {name: name}, formData)
      // console.log('savemyroute', {name: name}, {directions: props.myRoute.map(d=>d.id).join(',')})
      setOpen(false)
    }

    const Title = () => (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '460px', margin: '10px', marginRight: '20px', marginLeft: '20px'}}>
        <h4>Сохранить маршрут в избранное</h4>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Button onClick={onSave} icon={<AiOutlineCheck />} type='text' size="large" />
          <Button onClick={()=>setOpen(false)} icon={<AiOutlineClose />} danger type='text' size="large" />
        </div>
      </div>
    )

    return (
      <div style={{marginRight: '10px'}}>
        <Button onClick={()=>setOpen(true)} size='large'>Сохранить маршрут</Button>
        <Modal
          title={<Title />}
          open={open}
          onCancel={()=>setOpen(false)}
          footer={null}
          closable={false}
          width={w>600 ? '500px': w-100}
        >
          <Input 
            value={name} 
            placeholder="Наименивание маршрута"
            onChange={e=>{setName(e.target.value);setStatus()}}
            style={{margin: '20px', width: '460px'}}
            status={status}
          />
        </Modal>
      </div>
    )
  }

  const optimize = async () => {
    setLoading(true)
    const resp = await optiCoords(props.myRoute.map(item=>`${item.lng},${item.lat}`).join(';'));
    const opti = resp.waypoints.map((wp, i)=>{return {index: wp.waypoint_index, waypoint: props.myRoute[i]}})
      .sort((a, b) => a.index - b.index).map(r=>r.waypoint)
    props.myRouteMove(opti)
    setLoading(false)
  }



  const Title = () => {

    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px'}}>
        <RollUp />
        <h2>Мой маршрут</h2>
        <SaveMyRoute />
      </div>
    )
  }


  return (
      <div className="route">
        { props.myRoute.length>0 && <Badge count={props.myRoute.length-1} style={{ backgroundColor: '#52c41a' }} >
          <Button 
            icon={<FaRoute style={{color: "white", marginRight: '5px'}} />} 
            // style={{backgroundColor: "#1890ff", borderRadius: '20px', color: 'white'}} 
            onClick={()=>props.myRouteVisible(true)}
            size='large'
            type='primary'
          >
            Маршрут
          </Button>
        </Badge>}
        <Drawer
          open={props.visible}
          title={<Title />}
          onOk={()=>props.myRouteVisible(false)}
          onCancel={()=>props.myRouteVisible(false)}
          footer={null}
          style={{top: '10px', bottom: '10px'}}
          bodyStyle={{height: h-80, padding: '0px' }}
          width={w>600 ? '600px': w}
          centered
          closable={false}
          size='large'
          mask={false}
        >
          <div style={{display: 'flex', width: '100%', height: '20px'}} />



          { !ordered && <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll',  overflowX: 'hidden',  width: '100%',  height: h-260 }} ref={myRef}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}>
            <div className="myRouteSummaryBlocks">
              <div className="myRouteSummaryBlocksContent">Локаций</div>
              <div className="myRouteSummaryBlocksContent">{props.myRoute.length}</div>
            </div>
            <div className="myRouteSummaryBlocks">
              <div className="myRouteSummaryBlocksContent">Расстояние</div>
              <div className="myRouteSummaryBlocksContent">{`${distance} км`}</div>
            </div>
            <div className="myRouteSummaryBlocks">
              <div className="myRouteSummaryBlocksContent">Длительность</div>
              <div className="myRouteSummaryBlocksContent">{`${duration} часа`}</div>
            </div>
          </div>
            <Spin spinning={loading} indicator={<LoadingOutlined style={{fontSize: 24 }} spin />}>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <span>Перемешайте направления для построения оптимального маршрута</span>
              </div>
              <List
                values={props.myRoute}
                onChange={({ oldIndex, newIndex }) => {
                  props.myRouteMove(arrayMove(props.myRoute, oldIndex, newIndex));
                  console.log(legs, props.myRoute)
                }}
                renderList={({ children, props, isDragged }) => (
                  <ul {...props} style={{ paddingLeft: '20px', paddingRight: '20px', cursor: isDragged ? "grabbing" : undefined }} >{children}</ul>
                )}
                renderItem={({ value, props, isDragged, isSelected }) => (
                  <li 
                    {...props} 
                    style={{ ...props.style, display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px", marginRight: "20px", listStyleType: "none", cursor: isDragged ? "grabbing" : "grab", border: "1px solid gray", color: "#333", backgroundColor: isDragged || isSelected ? "#7F7F7F" : "#EDF1F2" }}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', height: '50px', alignItems: 'center'}}>
                      <h3 style={{fontWeight: 'bold', marginRight: '10px', marginLeft: '10px', width: '20px', textAlign: 'center'}} >{[0, lastDirIndex].includes(props.key) ? '🏁' : props.key}</h3>
                      <span style={{fontWeight: 'bold', marginRight: '10px'}} >{value.name}</span>
                      {value.district&&<span style={{color: 'gray'}} >{value.district}</span>}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      { props.key!==0&&legs[props.key] ? <Tag color="#7F7F7F" style={{color: '#212121'}}>{Math.round(legs[props.key].distance/100)/10} км</Tag> : null }
                      <Tooltip  title="Убрать из маршрута">
                        <Button onClick={()=>{handleCancel(props.key)}} icon={<AiOutlineClose />} type="text" danger />
                      </Tooltip>
                    </div>
                  </li>
                )}
              />
              <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginRight: '20px', justifyContent: 'center', alignItems: 'center'}}>
                <Button onClick={()=>{props.directionsVisible(true)}} size='small' >
                  Добавить локацию
                </Button>
                {props.myRoute.length>1&&props.myRoute[0]!==props.myRoute[props.myRoute.length-1]&&<Button 
                  onClick={addReverseRoute}
                  icon={<BsArrowRepeat style={{marginRight: '3px'}}/>}
                  type='link'
                  size='small'
                >
                  Обратный путь
                </Button>}
                <Button onClick={optimize} icon={<BiSort />} type="text" size='small'>
                  Оптимизировать
                </Button>
                <Clear />
              </div>
              <br/>
            </Spin>
          </div> }

          <Form layout="horizontal" name="basicForm" form={form} onFinish={handleBook} >
            { !ordered && <BookForm handleBook={handleBook} form={form} /> }
            <Form.Item name="directions" hidden />
            <Form.Item name="transfers" hidden />
            <Form.Item name="distance" hidden />
            <Form.Item name="duration" hidden />
            <Form.Item name="user_id" hidden />
            <Form.Item name="status" hidden initialValue='order' />
          </Form>
          { ordered && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <Result
              status="success"
              title="Тур забронирован"
              subTitle={<span style={{color: '#151515', fontSize: '18px'}}>С Вами свяжется менеджер для окончательного подтверждения деталей заказа.</span>}
              extra={[<Button type="primary" size='large' onClick={()=>{props.myRouteVisible(false)}}>Закрыть окно</Button>,]}
              size='large'
            />
          </div> }
        </Drawer>
      </div>
    
  );
};



const mapStateToProps = state => ({
  visible: state.myrouteReducer.visible,
  myRoute: state.myrouteReducer.data,
  legs: state.myrouteReducer.legs,
  transfers_selected: state.transfersReducer.selected,
  height: state.rollReducer.height,
  user: state.commonReducer.user,
})

const mapDispatchToProps = dispatch => ({
  myRouteVisible: visible => {dispatch(actionMyRouteVisible(visible));},
  myRouteMove: route => {dispatch(actionMyRouteMove(route))},
  directionsVisible: visible => {dispatch(actionDirectionsVisible(visible))},
  cancelTransfers: () => {
    dispatch(actionTransfersDistance(undefined));
    dispatch(actionTransfersSelected([]));
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MyRoute)
