import {
    ACTION_ROLL_VISIBLE,
    ACTION_ROLL_SCREEN_HEIGHT,
  } from "./types";
  
  

export const actionRollVisible = (visible) => ({
  type: ACTION_ROLL_VISIBLE,
  payload: visible
});


export const actionRollScreenHeight = (height) => ({
  type: ACTION_ROLL_SCREEN_HEIGHT,
  payload: height
});
  