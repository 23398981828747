import React, {useState, useEffect, useRef} from 'react';
import { 
  Button, DatePicker, Card, Spin, Radio, Input,
  Row, Col, Collapse, Checkbox, Menu, Dropdown,
} from 'antd';
import { CloseOutlined, LoadingOutlined, CheckSquareOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { get, post } from "./../../api/fetchData";
import Add from './../add/add'
import { minTommss } from './../../helpers/helpers'
import { src } from './../../helpers/options'


const { Panel } = Collapse;
const s = {
  order: {label: 'Заявка', style: {backgroundColor: '#fff0f6'}},
  accepted: {label: 'Принято', style: {backgroundColor: '#bae7ff'}},
  done: {label: 'Исполнено', style: {backgroundColor: '#b7eb8f'}},
  canceled: {label: 'Отменено', style: {backgroundColor: '#f5f5f5'}},
}

const Status = (props) => {
  const [status, setStatus] = useState(props.status)

  const onMenuClick = async (e) => {
    setStatus(e.key)
    console.log(e)
    const resp = await post('addroutehistory', {edit: true}, {id: props.id, status: e.key})
    props.reload()
  }

  const menu = (
    <Menu onClick={onMenuClick} >
      {Object.keys(s).map(k=>{return(
        <Menu.Item key={k}>{s[k].label}</Menu.Item>
      )})}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight"arrow>
       <Button style={s[status].style}>{s[status].label}<DownOutlined /></Button>
    </Dropdown>
  )
}




const RouteHistory = (props) => {
    const [data, setData] = useState(props.data)
    const [tab, setTab] = useState('order')
    const [loading, setLoading] = useState(false)
    const [w, setW] = useState(window.innerWidth - 40)
    // const [editData, setEditData] = useState()
    // const [addFormVisible, setAddFormVisible] = useState(false)
    // const [value, setValue] = useState()
  
    const divRef = useRef(null)
    const h = window.innerHeight - 124
    // const w = window.innerWidth - 40
  
  
    useEffect(()=>{
      const f = async () => {
        await onChange(tab)
        setW(divRef.current.offsetWidth-20)
      }
      f()
    }, [])
  
  
    const onChange = async (value) => {
      setLoading(true)
      var resp
      resp = await get('routehistory', {status: value})
      console.log(resp.data)
      setData(resp.data)
      setTab(value)
      setLoading(false)
    }
  

    return (
      <div ref={divRef} style={{width: 'auto'}}>
        <div style={{display: 'flex', justifyContent: 'right'}}>
          <Radio.Group 
            options={[{label: 'Все', value: 'all'}].concat(Object.keys(s).map(k=>{return {label: s[k].label, value: k}}))} 
            onChange={async (e)=>await onChange(e.target.value)} 
            value={tab} 
            optionType="button" 
            // buttonStyle="solid" 
          />
        </div>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          <div style={{ display: 'block', overflowY: 'scroll', height: h, }}>
            {!loading&&data.map(rh=>{return (
              <div
                style={{
                  display: 'inline-block', 
                  width: Math.floor((w-(Math.floor(w/450)*10+10))/Math.floor(w/450)), margin: '5px' 
                }}
              >
                <div style={{ display: 'flex', alignItems: 'top', }} >
                  <Card 
                    hoverable
                    size="small" 
                    title={data.route_name ? data.route_name : 'Пользовательский маршрут'} 
                    extra={<Status id={rh.id} status={rh.status} reload={async()=>await onChange(tab)} />} 
                    style={{ width: '100%' }}
                  >
                    <Row gutter={16}>
                      <Col span={10}>
                        <DatePicker 
                          defaultValue={moment(rh.datetime)} 
                          placeholder="Дата и время" 
                          showTimeFormat="YYYY-MM-DD HH:mm"
                          minuteStep={15} 
                          format='DD.MM.YY HH:mm'
                          // style={{width: '100%'}} 
                        />
                      </Col>
                      <Col span={8}>
                        <div>{ rh.phone }</div>
                      </Col>
                      <Col span={6}>
                        <div>{ rh.username }</div>
                      </Col>
                    </Row>
                    <Collapse defaultActiveKey={['1']} ghost>
                      <Panel header={`Направления (${rh.directions.length})`} key="directions">
                        <div style={{display: 'block', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap'}} >
                          {rh.directions.map(d=>{return(
                            <div style={{display: 'inline-block'}} >
                            <Card 
                              hoverable
                              size='small'
                              style={{display: 'inline-block', width: 'auto', margin: '5px', padding: '3px', height: '60px'}}
                              cover={<>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <h4>{d.name}</h4>
                                  <Button icon={<CloseOutlined />} type="text" danger />
                                </div>
                                <p>{ d.district }</p>
                              </>}
                            />
                            </div>
                          )})}
                        </div>
                      </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={['1']} ghost>
                      <Panel header="Транспорт" key="transfers">
                        <div style={{display: 'block', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap'}} >
                          {rh.transfers.map(t=>{return (
                            <div style={{display: 'inline-block'}} >
                              <Card 
                                hoverable
                                size='small'
                                style={{width: '330px', margin: '5px', padding: '3px', height: '105px'}}
                                cover={<><div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <div style={{width: '105px'}}>
                                    <img 
                                      src={`http://rocktrip.ru/storage/transfer/${t.id}/avatar.png`} 
                                      style={{height: '98px', 'width': '98px', display: 'flex'}}
                                    />
                                  </div>
                                  <div style={{width: '100%', paddingLeft: '5px', paddingRight: '5px'}}>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h4>{t.brand} {t.model} {moment(t.year).format('YYYY')}</h4>
                                    <Button icon={<CloseOutlined style={{marginBottom: '7px'}} />} type="text" danger />
                                  </div>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <p>{t.phone}</p>
                                    <p>{t.name}</p>
                                  </div>
                                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h4>{t.price*rh.distance+t.food_price*rh.food}</h4>
                                    <Button 
                                      icon={<CheckSquareOutlined 
                                      style={{marginBottom: '7px'}} />} 
                                      type="text" 
                                    />
                                  </div>
                                  </div>
                                </div></>}
                              />
                            </div>
                          )})}
                        </div>
                      </Panel>
                    </Collapse>
                                      
                    <Collapse defaultActiveKey={['1']} ghost>
                      <Panel header="Дополнительно" key="description">
                        <div style={{display: 'block', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap'}} >
                            <div style={{display: 'inline-block', margin: '5px'}} >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <a>{moment(rh.datetime).format('DD.MM.YY HH:mm')}</a>
                              </div>
                            </div>
                            <div style={{display: 'inline-block', margin: '5px'}} >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={src('distance')} style={{width: '25px', height: '25px'}} />
                                <h4>{rh.distance} км</h4>
                              </div>
                            </div>
                            <div style={{display: 'inline-block', margin: '5px'}} >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={src('duration')} style={{width: '25px', height: '25px'}} />
                                <h4>{minTommss(rh.duration)} ч</h4>
                              </div>
                            </div>
                            <div style={{display: 'inline-block', margin: '5px'}} >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={src('food')} style={{width: '25px', height: '25px', WebkitFilter: rh.food ? undefined : 'invert(85%)'}} />
                                <h4>{rh.food}</h4>
                              </div>
                            </div>
                            <div style={{display: 'inline-block', margin: '5px'}} >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={src('child')} style={{width: '25px', height: '25px', WebkitFilter: rh.child ? undefined : 'invert(85%)'}} />
                              </div>
                            </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </Card>
                </div>
              </div>
            )})}
          </div>
        </Spin>
      </div>
    )
  }


  export default RouteHistory;