export const options = [
    // {icon: "aeroport", type: "Аэропорт"},
    // {icon: "tower", type: "Крепость"},
    {icon: "hotel", type: "Гостиница"},
    {icon: "country_hotel", type: "База отдыха"},
    {icon: "medical_hotel", type: "Санаторий"},
    {icon: "hostel", type: "Хостел"},
    // {icon: "burial", type: "Некрополь"},
    {icon: "restourant", type: "Ресторан"},
    {icon: "cafe", type: "Кафе"},
    {icon: "fastfood", type: "Фастфуд"},

    {icon: "church", type: "Церковь"},
    {icon: "mosque", type: "Мечеть"},
    {icon: "sanctum", type: "Святилище"},
    // {icon: "ski", type: "Лыжи"},
    // {icon: "railway", type: "ЖД вокзал"},
    // {icon: "grocery", type: "Продукты"},
    // {icon: "horse", type: "Конюшня"},
    // {icon: "busstation", type: "Автовокзал"},
    
    {icon: "store", type: "Магазин"},
    {icon: "national", type: "Культура"},
    {icon: "theater", type: "Театр"},
    {icon: "museum", type: "Музей"},
    {icon: "library", type: "Библиотека"},
    

    // {icon: "gorge", type: "Ущелье"},
    {icon: "swing", type: "Качели"},
    {icon: "waterfall", type: "Водопад"},
    {icon: "artobject", type: "Арт объект"},
    {icon: "viewpoint", type: "Смотровая"},
    {icon: "nature", type: "Природа"},
    {icon: "buildings", type: "Строения"},
    {icon: "monument", type: "Памятник"},
    // {icon: "transport", type: "Транспорт"},

    {icon: "activity", type: "Активити"},
    {icon: "other", type: "Другое"},
  ] 

  export const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`
  