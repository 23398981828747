import { useState, useEffect } from 'react';
import { 
  // Comment, 
  Avatar, Form, Button, List, Input, Spin } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import moment from 'moment';
import { get, post } from './../api/fetchData';


const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${'Отзывы'}`}
    itemLayout="horizontal"
    // renderItem={props => <Comment {...props} />}
    renderItem={props => <></>}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={3} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Оставить отзыв
      </Button>
    </Form.Item>
  </>
);


const Review = (props) => {
  const [comments, setComments] = useState([])
	const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('')
	const [loading, setLoading] = useState(false)
	const [user_id, setUser_id] = useState()

	useEffect(()=>{
    const f = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) { setUser_id(user.id) }
		const resp = await get('reviews', {parent_id: props.parent_id})
		setComments(resp.data)
    }
    f()
	}, [])

  const handleSubmit = async () => {
    if (!value) {return}
		setLoading(true)
    setSubmitting(true)
    setSubmitting(false)
    setValue('')
    // setComments([
    //   {
    //     author: 'Han Solo',
    //     avatar: 'https://joeschmoe.io/api/v1/random',
    //     content: <p>{value}</p>,
    //     datetime: moment().format('DD.MM.YYYY hh:mm:ss'),
    //   },
    //   ...comments,
    // ])
		// console.log({user_id: user_id, parent_id: props.parent_id, content: value, datetime: moment()})
		const resP = await post('addreview', undefined, {user_id: user_id, parent_id: props.parent_id, content: value, datetime: moment().format()})
		const resp = await get('reviews', {parent_id: props.parent_id})
		setComments(resp.data)
		setLoading(false)
  };

  const handleChange = e => {
    setValue(e.target.value)
  }

  return (
    <>
			<Spin spinning={loading} indicator={<LoadingOutlined style={{fontSize: 24 }} spin />}>
        {/* {user_id ? 
          <Comment
            avatar={<Avatar size={48} icon={<UserOutlined />} style={{textAlign: 'center'}}/>}
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={submitting}
                value={value}
              />
            }
          /> :
          <p>Авторизируйтесь, для составления отзывов</p>
        }
        {comments.length > 0 && <CommentList comments={comments} />} */}
			</Spin>
    </>
  );
}


export default Review
