const initialMetaState = {
  visible: false,
};

export const rollReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_ROLL_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_ROLL_SCREEN_HEIGHT":
      return { ...state, height: action.payload };
    default:
      return state;
  }
};
