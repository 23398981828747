import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, List, Typography, Layout, Modal, Skeleton, Divider, Avatar, Tabs } from 'antd'
import { HeartTwoTone, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { AiOutlineFilter } from "react-icons/ai";
import InfiniteScroll from 'react-infinite-scroll-component';
import { get, fetchData } from "./../api/fetchData";
import { actionFavoritesVisible } from "./../actions/favorites"
// import Direction from './directions/direction'


export const Favorite = props => {
  const [fav, setFav] = useState(props.add)
  const onClick = async () => {
    setFav(!fav)
    await get('favs', {fav_id: props.fav_id, type: props.type, add: !fav})
  }

  return (
    <Tooltip title={fav ? 'В избранном' : 'Добавить в избранное'}>
      <Button
        icon={fav ? <HeartFilled style={{color: "RGB(214, 31, 44)"}} /> : <HeartOutlined style={{color: "white"}} />} 
        type="text" 
        onClick={onClick}
        style={{fontSize: '20px'}}
      />
    </Tooltip>
  )
} 



const { TabPane } = Tabs;

const Favorites = (props) => {
  const [tab, setTab] = useState('direction')

  useEffect(()=>{
    const f = async () => {const resp = await get('favs', {type: tab})}
    f()
  }, [])

  const onTabChange = () => {

  }

  return (
    <Tooltip title="Избранное" >
      <Button 
        icon={<HeartTwoTone />} 
        shape="circle" 
        // style={{backgroundColor: "#1890ff"}}
        type="text"
        onClick={()=>props.favoritesVisible(true)}
      />
      <Modal
        visible={props.visible}
        title={<><HeartTwoTone twoToneColor="#eb2f96" />Избранное</>}
        onOk={()=>props.favoritesVisible(false)}
        onCancel={()=>props.favoritesVisible(false)}
        // footer={[<AddBtn />, <OkBtn />]}
      >
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="Направления" key="direction">
            Content of Tab Pane 1
          </TabPane>
          <TabPane tab="Маршруты" key="route">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Трансфер" key="transfer">
            Content of Tab Pane 3
          </TabPane>
        </Tabs>
      </Modal>
    </Tooltip>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.favoritesReducer.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
  favoritesVisible: (visible) => {
    dispatch(actionFavoritesVisible(visible));
  },
})}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)