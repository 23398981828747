import {
    ACTION_DIRECTIONS_DATA,
    ACTION_DIRECTIONS_VISIBLE,
  } from "./types";
  
  

export const actionDirectionsVisible = (visible) => ({
  type: ACTION_DIRECTIONS_VISIBLE,
  payload: visible
});

export const actionDirectionsData = (directions) => ({
  type: ACTION_DIRECTIONS_DATA,
  payload: directions
});
  