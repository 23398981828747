import { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Button, Space, Spin, Collapse, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { get } from "./../../api/fetchData";


const { Panel } = Collapse;


const UserInfo = () => {
  const [data, setData] = useState()
	const [changed, setChanged] = useState(false)
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [err, setErr] = useState("")


	const [form] = Form.useForm();
	

  useEffect(()=>{
		const f = async () => {
		const resp = await get('user')
		setData(resp.data)
		}
		f()
  }, [])

	const onChange = (e) => {
		setChanged(true)
	}

	const onPhoneChange = (value) => {
		setChanged(true)
	}

	const onPasswordChange = (e) => {
		
		const value = e.target.value
		const rgx = /^[a-zA-Z0-9]+$/
		if (value&&!rgx.test(value)) {
			setDisabled(true)
      setErr("Используйте латинские символы и числа в пароле")
			setChanged(true)
		} else if (value&&value.length<6) {
			setDisabled(true)
      setErr("Минимальная длина пароля 6 символов")
			setChanged(true)
		} else if (form.getFieldValue('new_pass_') !== form.getFieldValue('new_pass')) {
			setDisabled(true)
      setErr("Пароли не совпадают")
			setChanged(true)
    } else {
			setDisabled(false)
      setErr("")
			setChanged(true)
    }
  }


	const validate = () => {
		const fields = form.getFieldsValue()
		if (!fields.name) {message.error('Укажите имя'); return false}
		else if (!fields.phone) {message.error('Укажите телефон'); return false}
		else if (fields.phone.length!==12) {message.error('Неккоректный номер телефона'); return false} 
		else { return true }
	}

	const onSave = async () => {
		if (!validate()) {return}
		setLoading(true)
		const resp = await get('user', form.getFieldsValue())
		setLoading(false)
		if (resp.status===200) {
			message.success(resp.data.message)
			setChanged(false)
		} else {
			message.error(resp.data.detail)
		}
	}

	const onCancel = () => {
		form.setFieldsValue({...data, password: null, new_pass_: null, new_pass: null})
		setChanged(false)
		setDisabled(false)
		setErr("")
	}

	return (
		<Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
		{data&&<div style={{maxWidth: '600px'}}>
			<br />
			<Form layout="horizontal" name="basicForm" form={form} initialValues={data} >
				<Row>
					<Col span={24}>
						<Form.Item
							name='name'
							label='Имя пользователя'
						>
							<Input onChange={onChange} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name='phone'
							label='Телефон'
						>
							<PhoneInput 
								onChange={onPhoneChange}
								defaultCountry='RU'
							/>
						</Form.Item>
					</Col>
				</Row>
				<Collapse ghost>
					<Panel header={<p style={{color: "#1890ff"}}>Изменить пароль</p>} key="1">
						<Row>
							<Col span={24}>
								<Form.Item
									name='password'
									label='Старый пароль'
								>
									<Input.Password 
										onChange={onChange}
										prefix={<LockOutlined className="site-form-item-icon" />}
										iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    							/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item
									name='new_pass_'
									label='Новый пароль'
								>
									<Input.Password 
										onChange={onPasswordChange}
										iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    							/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item
									name='new_pass'
									label='Повторите пароль'
								>
									<Input.Password 
										onChange={onPasswordChange}
										iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    							/>
								</Form.Item>
							</Col>
						</Row>
						{/* {disabled ?  <p style={{color: 'red'}}>{err}</p> : <p> </p>} */}
						{<p style={{color: 'red'}}>{err}</p>}
					</Panel>
				</Collapse>
				<Form.Item name='id' />
			</Form>
			{changed&&<div style={{display: 'block'}}>
				<Button 
					danger 
					style={{margin: "10px"}}
					onClick={onCancel}
					disabled={disabled}
				>
					Отмена
				</Button>
				<Button 
					type="primary" 
					style={{margin: "10px"}} 
					onClick={onSave}
					disabled={disabled}
				>
					Сохранить изменения
				</Button>
			</div>}
		</div>}
		</Spin>
	)
}



export default UserInfo