import { Modal, Button, Typography, Divider } from 'antd'




const { Text, Paragraph } = Typography;


const Signup = () => {
  return (
    <>
      <p>Зарегистрируйтесь и получите скидку 10% на первый маршрут</p>
    </>
  )
}


const Invite = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return (
    <>
      <p>Пригласите* друга и получите скидку 10% на любой маршрут</p>
      <p>Скопируйте ссылку и отправьте другу:</p>
      <Text>
        <Paragraph 
          copyable 
          // style={{cursor: 'pointer'}}
          code
          // onClick={()=>{navigator.clipboard.writeText(`http://www.rocktrip.ru/invite?id=${user ? user.id : undefined}`)}} // doesnt work on all browsers
        >
          {`http://www.rocktrip.ru/invite?id=${user ? user.id : undefined}`}
        </Paragraph>
      </Text>
      <Text italic type="secondary">*На каждый маршрут применима только одна скидка</Text><br/>
      <Text italic type="secondary">*Скидка активируется после завершения хотя бы одного маршрута друга </Text>
    </>
  )
}


const Promo = (props) => {

  return (
      <Modal
        title="Акции"
        key={Math.random()}
        visible={props.visible}
        onCancel={()=>props.setVisible(false)}
        footer={[<Button onClick={()=>props.setVisible(false)} type="primary" >Ок</Button>]}
      >
        <Signup />
        <Divider />
        <Invite />
      </Modal>
    )
  }


export default Promo
 