import { Button } from 'antd';
import { connect } from "react-redux";
import { BiNavigation } from "react-icons/bi"
import { FaRegHandshake, FaRoute, FaMapMarkerAlt } from "react-icons/fa";
import { 
	actionRoutesVisible,  
} from "./../../actions/routes"
import { 
	actionDirectionsVisible,  
} from "./../../actions/directions"




const mapStateToProps = (state, ownProps) => {
  return {
    // visible: state.routeReducer.visible,
    // route: state.routeReducer.route,
    // myRoute: state.myrouteReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    routesVisible: () => {
      dispatch(actionRoutesVisible(true));
    },
		directionsVisible: () => {
      dispatch(actionDirectionsVisible(true));
    },
})}


export const Routes = connect(mapStateToProps, mapDispatchToProps)((props) => {
	console.log('full', props.full)
	return (
		<Button 
			onClick={props.routesVisible} 
			icon={<FaRoute />}
			type='text'
			shape={props.full ? null : "circle"}
		>
			&nbsp;{ props.full ? "Маршруты" : null }
		</Button>
	)
})

export const Directions = connect(mapStateToProps, mapDispatchToProps)((props) => {
	return (
		<Button 
			onClick={props.directionsVisible} 
			icon={<FaMapMarkerAlt />}
			type='text'
			shape={props.full ? null : "circle"}
		>
			&nbsp;{ props.full ? "Направления" : null }
		</Button>
	)
})