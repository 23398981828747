import {
    ACTION_MYROUTE_URL_COORDS,
    ACTION_MYROUTE_VISIBLE,
    ACTION_MYROUTE_REMOVE,
    ACTION_MYROUTE_MOVE,
    ACTION_MYROUTE_LEGS,
    ACTION_MYROUTE_ADD,
  } from "./types";
  
  

export const actionMyRouteVisible = visible => ({
  type: ACTION_MYROUTE_VISIBLE,
  payload: visible
});
  

export const actionMyRouteAdd = (wayPoint) => ({
  type: ACTION_MYROUTE_ADD,
  payload: wayPoint
});


export const actionMyRouteRemove = (wayPoint) => ({
  type: ACTION_MYROUTE_REMOVE,
  payload: wayPoint
});


export const actionMyRouteMove = (route) => ({
  type: ACTION_MYROUTE_MOVE,
  payload: route
});


export const actionMyRouteUrlCoords = () => ({
  type: ACTION_MYROUTE_URL_COORDS,
});


export const actionMyRouteLegs = (legs) => ({
  type: ACTION_MYROUTE_LEGS,
  payload: legs
});

