import { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import './../../loading.css'
import {images, importAll, coords} from './map'
import { get } from "../../api/fetchData";
import * as turf from '@turf/turf'




mapboxgl.accessToken = 'pk.eyJ1IjoicnVzc3NpazEiLCJhIjoiY2t2Nzl0Y3Z1MW82ZDJ1czNxMzYyYTFzbCJ9.2bXmmydb3D-zOZhP6LDkdA';


const polyMask = mask => turf.difference(turf.bboxPolygon([180, 90, -180, -90]), mask)


const RouteMap = props => { 
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);

	const urlCoords = props.directions.map(d=>`${d.lng},${d.lat}`).join(';')
  const features = {
    type: 'FeatureCollection',
    features: props.directions.map((d, i)=>({
      type: 'Feature',
      geometry: {type: 'Point', coordinates: [d.lng, d.lat]},
      properties: {label: [0, props.directions.length-1].includes(i) ? `🏁 ${d.name}` : d.name},
    }))
  }
  


	async function getRoute() {
    if (urlCoords==="") {if (map) {if (map.getLayer('routeImg')) {map.removeLayer('routeImg')}}; return}
    if (urlCoords.split(';').length<2) {
      map.removeLayer('route');
      if (map.getSource('route')) {map.removeSource('route')}
    } else {
      const json = await coords(urlCoords);
      const data = json.routes[0]; // duration distance in legs
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {type: 'LineString', coordinates: data.geometry.coordinates}
      }
      // if the route already exists on the map, we'll reset it using setData
      if (map.getSource('route')) {map.getSource('route').setData(geojson)}
      // otherwise, we'll make a new request
      else { map.addLayer({
        id: 'route',
        type: 'line',
        source: { type: 'geojson', data: geojson },
        layout: { 'line-join': 'round', 'line-cap': 'round' },
        paint: { 'line-color': '#52c41a', 'line-width': 5, 'line-opacity': 0.75 }
      })}
    }
    map.moveLayer('route', 'clusters')
  }




  useEffect(() => {
    const f = async () => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [44.1786, 42.9507],
        zoom: 8,
        pitch: 45,
      });

      const borders = await get('borders');
      const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
        enableHighAccuracy: true},
        trackUserLocation: true,
        showUserLocation:true},
      )

      map.on("load", async () => {
        map.addSource("directions", {
          type: "geojson",
          data: features,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 70,
        });

        map.addControl(geolocateControl, "bottom-right");
        geolocateControl.on('geolocate', function(e) {
          const lng = e.coords.longitude;
          const lat = e.coords.latitude
          const userPosition = [lng, lat];
        });

        for (const [id, data] of Object.entries(borders.data)) {
          map.addSource(`polygon_${id}`, { type: "geojson", data: polyMask(turf.polygon([data])),});
          map.addLayer({ id: `polygon_${id}`, type: 'fill', source: `polygon_${id}`, layout: {}, paint: { 'fill-color': 'black', 'fill-opacity': 0.5 }});
          map.addLayer({ id: `polygon_line_${id}`, type: 'line', source: `polygon_${id}`, layout: { 'line-join': 'round', 'line-cap': 'round'}, paint: { 'line-color': 'white', 'line-width': 2}});
        }


        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'directions',
          filter: ['has', 'point_count'],
          paint: { 'circle-color': '#7F7F7F', 'circle-radius': 20}
        });

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'directions',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
          }
        });

        // getRoute()
        map.addLayer({
          id: 'directions-layer',
          type: 'symbol',
          source: 'directions',
          layout: {
            "text-field": ['get', 'label'],
            "text-offset": [1.2, 0],
            "text-anchor": "left",
            "text-size": 18,
          },
          paint: { "text-halo-color": "rgba(255, 255, 255, 0.5)", "text-halo-width": 200 },
        })

      })


      


      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
        const clusterId = features[0].properties.cluster_id;
        map.getSource('directions').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;
          map.easeTo({ center: features[0].geometry.coordinates, zoom: zoom })
        })
      });



      map.on('mouseenter', 'clusters', () => {map.getCanvas().style.cursor = 'pointer'})
      map.on('mouseleave', 'clusters', () => {map.getCanvas().style.cursor = ''})
      map.on("mouseenter", "directions-layer", e => {if (e.features.length) { map.getCanvas().style.cursor = "pointer"}});
      map.on("mouseleave", "directions-layer", () => {map.getCanvas().style.cursor = "";});

      setMap(map)
      setTimeout(()=>{setLoading(false)}, 1000)
      return () => map.remove(); // clean up on unmount
    }
    f()
  }, []);



	useEffect(()=>{ map && map.on("load", () => {getRoute()})}, [map])








  return (
    <div style={{position: 'relative', top: 0, bottom: 0, right: 0, left: 0, width: '100%', height: props.h }} >
      <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '100%', height: props.h }} >
        <div className="map-container" ref={mapContainerRef} />
        {loading&&<div class="balls-parent"><div class="balls"><div /><div /><div /></div></div>}
      </div>
    </div>
  )
};







export default RouteMap
