import { useState } from 'react'
import { Modal, Button, Tabs } from 'antd'
import UserInfo from './userInfo'



const { TabPane } = Tabs;

const Settings = (props) => {
	const [tab, setTab] = useState('profile')
	const [saveDisabled, setSaveDisabled] = useState(true)

	const onChange = (tab) => {
		setTab(tab)
	}

	const SaveBtn = () => {
		return (
			<Button 
				onClick={()=>props.setVisible(false)} 
				type="primary"
				disabled={saveDisabled}
			>
				Сохранить
			</Button>
		)
	}

  return (
      <Modal
        title="Настройки"
        visible={props.visible}
        onCancel={()=>props.setVisible(false)}
        footer={[]}
      >
        <Tabs defaultActiveKey={tab} onChange={onChange}>
          <TabPane tab="Профиль" key="profile">
            <UserInfo />
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Tab 3" key="3">
            Content of Tab Pane 3
          </TabPane>
        </Tabs>
      </Modal>
    )
  }


export default Settings
 