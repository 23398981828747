const initialMetaState = {
  visible: false,
  zIndex: 10,
  direction: {images: "[]"}
};

export const directionReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_DIRECTION_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_DIRECTION_DATA":
      return { ...state, direction: action.payload };
    default:
      return state;
  }
};
