import React, {useState, useEffect, useRef} from 'react';
import { 
  Button, Table, Radio, Spin, Switch, Input
} from 'antd';
import { LoadingOutlined, EyeInvisibleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import RouteHistory from './routeHistory'
import { get, post } from "./../../api/fetchData";
import Add from './../add/add'




export const ava = imUrl => <img src={imUrl} style={{width: '50px', height: '50px'}}/>
export const cnt = images => images.length
export const active = (text, record, tab) => <Active record={record} tab={tab} />//<Switch checked={record.active} onChange={(checked)=>switcActive(record, checked)} />


export const Active = props => {
  const [active, setActive] = useState(props.record? props.record.active : false)
  const switcActive = async () => {
    var record = props.record
    if (props.tab==='route') {record = {...record, directions: record.directions.map(d=>d.id)}}
    await post('add'+props.tab, {edit: true}, {...record, active: !active})
    setActive(!active)
  }

  return <Button 
    type="text" 
    icon={active ? <EyeOutlined style={{color: '#008dff'}} /> : <EyeInvisibleOutlined style={{color: 'gray'}} />}
    onChange={switcActive} 
  />
}



class MyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.initData
    }
    this.filterRows = this.filterRows.bind(this)
  }

  filterRows(search) {
    const res = this.props.initData.data.filter(obj=>Object.values(obj).some(val=>typeof(val)==='string'&&val.toLowerCase().includes(search.toLowerCase())));
    this.setState({data: {...this.state.data, data: res}})
  }

  render() {
    return(
      <Table 
        style={{cursor: 'pointer'}} 
        dataSource={this.state.data.data} 
        columns={[
          {title: '#', key: 'index', width: "60px", render: (text, record, index) => index},
          {title: 'id', key: 'id', width: "80px", render: (text, record, index) => record.id},
          {title: 'Ava', dataIndex: 'avatar', key: 'avatar', width: '80px', render: ava,}, 
          {title: 'Img', dataIndex: 'images', key: 'images', width: '60px', render: cnt,},
          ].concat(this.state.data.params.columns.filter(item => !item.hidden).map(c=>{return({...c,})})).concat([
            {title: 'Статус', dataIndex: 'active', key: 'active', width: '80px', render: (text, record)=>active(text, record, this.props.tab),},
          ])}  
        pagination={false}
        scroll={{ y: 'calc(90vh - 4em)' }}
        onRow={(record, index)=>{return {onClick: e => {if (!e.target.className.includes('switch')) {
          this.props.setEditData(JSON.stringify(record)); this.props.setAddFormVisible(true)}}}}}
      />
    )
  }
}




const Admin = props => {
  const [tab, setTab] = useState('direction')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [editData, setEditData] = useState()
  const [addFormVisible, setAddFormVisible] = useState(false)
  const [value, setValue] = useState()

  const tableRef = useRef()


  useEffect(()=>{const f = async () => {await onChange(tab)}; f()}, [])


  const onChange = async (value) => {
    setLoading(true)
    var resp
    switch (value) {
      case "direction":
        resp = await get('directions', {mode: 'admin', active: false})
        setData(resp.data)
        if (tableRef.current) {tableRef.current.setState({data: resp.data})}
        break;
      case "route":
        resp = await get('routes', {mode: 'admin', active: false})
        setData(resp.data)
        if (tableRef.current) {tableRef.current.setState({data: resp.data})}
      case "routehistory":
        resp = await get('routehistory', {mode: 'admin'})
        setData(resp.data)
      default:
        break;
    }
    setTab(value)
    setLoading(false)
  }


  const options = [
    { label: 'Направления', value: 'direction' },
    { label: 'Маршруты', value: 'route' },
    { label: 'История маршрутов', value: 'routehistory' },
  ]

  const Extra = () => {
    const onClick = () => {
      setEditData()
      setAddFormVisible(!addFormVisible)
    }

    const onChange = e => {
      console.log(e.target.value)
      tableRef.current.filterRows(e.target.value)
    }
    return (
      <div style={{display: 'flex'}}>
        <Input
          prefix={<SearchOutlined />}
          onChange={onChange} 
          placeholder="Поиск"
          allowClear
          defaultValue={value}
        />
        {tab!=='routehistory'&&<Button 
          type="primary"
          onClick={onClick}
        >
          Добавить {
            tab==='direction' 
            ? " направление" 
            : tab==='route'
            ? " маршрут"
            : " маршрут"
          }
        </Button>}
      </div>
    )
  }


  const h = window.innerHeight


  return (
    <div style={{padding: '10px', height: h-100, marginTop: '50px'}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Radio.Group 
          options={options} 
          onChange={async (e)=>await onChange(e.target.value)} 
          value={tab} 
          optionType="button" 
          buttonStyle="solid" 
        />
        <Extra />
      </div>
      <br/>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        {tab==='routehistory' ? data && <RouteHistory data={data} reload={async()=>await onChange(tab)}/> : data && <MyTable 
          ref={tableRef} 
          initData={data}
          setEditData={setEditData} 
          setAddFormVisible={setAddFormVisible} 
          tab={tab} 
        />}
      </Spin>
      {addFormVisible && <Add 
        addFormKey={`add${tab}`} 
        editData={editData}
        addFormVisible={addFormVisible}
        setAddFormVisible={setAddFormVisible}
        reload={async()=>await onChange(tab)}
      />}
    </div>
  )
}


export default Admin



