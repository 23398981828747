import { useState, useEffect } from 'react';
import { Modal, Upload, Image, Button } from 'antd';
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}




const UploadImages = props => {
  const [fileList, setFileList] = useState(props.form.getFieldValue('images').map(im=>{
    return {url: im, originFileObj: new File([""], im.split('/')[im.split('/').length-1])}
  }))
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  useEffect(()=> {
    props.form.setFieldsValue({upload: [...fileList]})
    // console.log(fileList)
  }, [])

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {file.preview = await getBase64(file.originFileObj);}
    setPreviewImage(file.url || file.preview) 
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  };


  const handleBeforeUpload = file => {
  setFileList([...fileList, file]); 
  return false;
};

  const handleChangeFiles = ({ fileList, file }) => {
    setFileList([...fileList]);
    props.form.setFieldsValue({upload: [...fileList]})
  };


  const handleRemove = selectedFile => fileList.filter(file=>selectedFile.uid!==file.uid)

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Upload
        {...{
          fileList,
          defaultFileList: fileList,
          onRemove: handleRemove,
          beforeUpload: handleBeforeUpload,
          multiple: true,
          onChange: handleChangeFiles,
          onPreview: handlePreview,
          listType: "picture-card",
          progress: { showInfo: true },
          data: file => {console.log("DATA:", file)}
        }}
      >
        {fileList.length >= 12 ? null : 
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Загрузить из компьютера</div>
          </div>
        }

      </Upload>
      {props.route && <AddImagesFromDirection form={props.form} fileList={fileList} handleChangeFiles={handleChangeFiles} /> }

      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>     
  )
}


export default UploadImages







const AddImagesFromDirection = props => {
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState([])
  const [selected, setSelected] = useState([])

  useEffect(()=>{
    const all = props.form.getFieldValue('directions') ? props.form.getFieldValue('directions').map(d=>d.images ? d.images: []).flat(1) : []
    setImages(all)
    const selectedPrevious = props.form.getFieldValue('images').map(im=>im.url)
    setSelected(selectedPrevious.filter(im=>all.includes(im)))
  }, [])

  const onSelect = im => {
    if (selected.includes(im)) {setSelected(selected.filter(s=>s!==im))}
    else {setSelected([...selected, im])}
  }

  const onSave = () => {
    console.log(props.fileList)
    const newSelectedFiles = selected.filter(s=>!props.fileList.map(im=>im.url).includes(s)).map(im=>({url: im, originFileObj: new File([""], im.split('/')[im.split('/').length-1])}))
    props.handleChangeFiles({fileList: [...props.fileList, ...newSelectedFiles]})
    setOpen(false)
  }

  const Title = () => {

    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '5px'}}>
        <h1>Выбрать фото</h1>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Button onClick={onSave} >Сохранить</Button>
          <Button onClick={()=>setOpen(false)} style={{marginLeft: '10px'}}>Отмена</Button>
        </div>

      </div>
    )
  }

  return (
    <div>
      <Button onClick={()=>{setOpen(true)}} >Загрузить фотографии из локаций</Button>
      <Modal
        open={open}
        onCancel={()=>setOpen(false)}
        width='90vw'
        bodyStyle={{height: '80vh'}}
        title={<Title />}
        footer={null}
        closable={false}
      >
        <div style={{overflowY: 'scroll', height: '100%', width: '100%'}}>
          {images.map(im=>(
            <div style={{display: 'inline-block', width: '400px', height: '300px', padding: '5px'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', position: 'relative', backgroundColor: selected.includes(im) ? 'green': undefined}}>
                <Image src={im} height={280} width={380} />
                <Button 
                  icon={selected.includes(im) ? <CheckOutlined /> : <PlusOutlined />} 
                  style={{top: 10, left: 10, position: 'absolute', margin: '10px', backgroundColor: selected.includes(im) ? 'green' : 'white'}} 
                  onClick={()=>onSelect(im)}
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}