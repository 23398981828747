import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/Gilroy-Light.otf'
import "react-image-gallery/styles/css/image-gallery.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from "antd"
import locale from 'antd/lib/locale/ru_RU';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from "./store";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider
    clientId="1036551642508-jbohm2q4rgjete2b1sgec0tuf85nhpb4.apps.googleusercontent.com"
  >
    <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider 
        locale={locale}
        theme={{
          token: {colorPrimary: '#151515', fontFamily: 'Gilroy-Light'},
          components: {
            Button: {
              borderRadius: '20px',
              borderRadiusLG: '20px'
            },
            Input: {
              borderRadiusLG: '0px',
            },
            DatePicker: {
              borderRadiusLG: '0px'
            }
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
    </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
