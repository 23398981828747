import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {
  Button,
} from 'antd';

mapboxgl.accessToken = 'pk.eyJ1IjoicnVzc3NpazEiLCJhIjoiY2t2Nzl0Y3Z1MW82ZDJ1czNxMzYyYTFzbCJ9.2bXmmydb3D-zOZhP6LDkdA';

const AddDirectionMap = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(props.lng ? props.lng : 44);
  const [lat, setLat] = useState(props.lat ? props.lat : 43);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    if (!props.visible) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 9
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (!props.visible) return;
    map.current.on('move', () => {
      // setLng(map.current.getCenter().lng.toFixed(4));
      // setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    var marker = new mapboxgl.Marker()
    if (props.lng&&props.lat) {marker.setLngLat({lat: props.lat, lng: props.lng}).addTo(map.current)}
    map.current.on("click", e => {
      marker.setLngLat(e.lngLat).addTo(map.current)
      setLng(e.lngLat.lng.toFixed(4));
      setLat(e.lngLat.lat.toFixed(4));
    });
  });


  return (
    <>
    {props.visible && <div>
      <div className="map-adddirection-sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div ref={mapContainer} className="map-container" />
      <div className="add-map-footer">
        <Button style={{marginRight: '5px'}} onClick={()=>props.setModalVisible(false)}>Отмена</Button>
        <Button type="primary" onClick={()=>props.onModalOk(lng, lat)}>Подтвердить</Button>
      </div>
    </div>}
    </>
  );
}



export default AddDirectionMap
