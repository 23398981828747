import { useState, useEffect, useRef } from 'react'
import { Empty, Divider, Spin, Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import EntityCard from '../commonTemplates/entityCard'
import Direction from '../directions/direction'
import Route from '../routes/route'
import { get } from '../../api/fetchData'
import { actionDirectionsVisible } from "./../../actions/directions"
import { actionDirectionVisible, actionDirectionData } from "./../../actions/direction"
import { actionMyRouteRemove, actionMyRouteMove, actionMyRouteAdd, } from "./../../actions/myroute"
import { actionRouteData, actionRouteVisible } from '../../actions/route'
import direction from '../directions/direction'



const MyFavorites = props => {
  const [loading, setLoading] = useState(false)
  const [routes, setRoutes] = useState([])
  const [myroutes, setMyroutes] = useState([])
  const [directions, setDirections] = useState([])
  const [w, setW] = useState(100)
  const [h, setH] = useState(100)

  const divRef = useRef()

  useEffect(()=>{
    const f = async () => {
      setLoading(true)
      const resp1 = await get('directions', {fav: true, mode: "list"})
      const resp2 = await get('routes', {fav: true})
      const resp3 = await get('myroutes')
      setDirections(resp1.data)
      setRoutes(resp2.data)
      setMyroutes(resp3.data)
      console.log(resp3.data)
      setLoading(false)
    }
    f()
    setW(divRef.current&&divRef.current.clientWidth-10)
    setH(divRef.current&&divRef.current.clientHeight-50)
  }, [])


  return (
    <div
    style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '60px', width: '100%', backgroundColor: '#EEEEEE'}}

    //  style={{display: 'flex', paddingTop: '50px', width: '100%', backgroundColor: '#EEEEEE'}} 
    >
      <div className='dirs' >
        <div ref={divRef} className='dirs2' >
          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: h, overflowY: 'scroll'}}> */}

              {/* <div style={{width: '100%'}}><Divider orientation="left" plain><h1>{`Мои маршруты (${myroutes.length})`}</h1></Divider></div> */}
              <div className='myFavoritesDiv'>
                <div className='myFavoritesDivTitle' >
                  <h2>{`Мои маршруты (${myroutes.length})`}</h2>
                </div>
              <div style={{display: 'flex', flexDirection: 'row', width: w-70, paddingLeft: '20px', paddingRight: '20px', overflowY: 'auto'}}>
                { myroutes.length > 0 ? myroutes.map(r=><EntityCard entity={r} type='myroute' w={w} selectEntity={props.selectRoute} />) : <Empty description={'Пока нет построенных маршрутов. Для построения, перейдите на карту'} /> }
                </div>
              </div>
              {/* <div style={{width: '100%'}}><Divider orientation="left" ><h1>{`Туры (${routes.length})`}</h1></Divider></div> */}
              <div className='myFavoritesDiv'>
                <div className='myFavoritesDivTitle' >
                  <h2>{`Туры (${routes.length})`}</h2>
                </div>
              <div style={{display: 'flex', flexDirection: 'row', width: w-70, paddingLeft: '20px', paddingRight: '20px', overflowY: 'auto'}}>
                { routes.length > 0 ? routes.map(r=><EntityCard entity={r} type='route' w={w} selectEntity={props.selectRoute} />) : <Empty description='Пока нет избранных туров' /> }
              </div>
              </div>
              <div className='myFavoritesDiv'>
                <div className='myFavoritesDivTitle' >
                  <h2>{`Локации (${directions.length})`}</h2>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', width: w-70, overflowY: 'auto'}}>
                  { directions.length > 0 ? directions.map(d=><EntityCard entity={d} type='direction' w={w} selectEntity={props.selectDirection} />) : <Empty description='Пока нет избранных локаций' /> }
                </div>
              </div>
              <div style={{height: '200px'}} />
            {/* </div> */}
            {props.directionVisible&&<Direction />}
            {props.routeVisible&&<Route />}
          </Spin>
        </div>
      </div>
    </div>
  )
}




const mapStateToProps = state => ({
  visible: state.directionsReducer.visible,
  directionVisible: state.directionReducer.visible,
  routeVisible: state.routeReducer.visible,
  directions: state.directionsReducer.directions,
  myRoute: state.myrouteReducer.data,
  directions_filter: state.filterReducer.directions_filter,
})

const mapDispatchToProps = dispatch => ({
  selectDirection: direction => { dispatch(actionDirectionData(direction)); dispatch(actionDirectionVisible(true)) },
  selectRoute: route => { dispatch(actionRouteData(route)); dispatch(actionRouteVisible(true))}
})


export default connect(mapStateToProps, mapDispatchToProps)(MyFavorites)
