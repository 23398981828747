const initialMetaState = {
  visible: false,
  directions: {}
};

export const directionsReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_DIRECTIONS_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_DIRECTIONS_DATA":
      return { ...state, directions: action.payload };
    default:
      return state;
  }
};
