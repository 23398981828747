import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Select, Slider, Button, Popover, Radio, Space, Tree, Checkbox, Row, Col, Divider } from 'antd'
import { StarFilled, CloseOutlined } from '@ant-design/icons';
import { IoLocationOutline } from "react-icons/io5";
import { get } from "./../../api/fetchData";
import {
  actionTransfersFilter
} from './../../actions/filter'
import { Rate, Fav, filterStyle, opened, closed } from './commonFilters'














const Price = () => {
  const [priceClosable, setPriceClosable] = useState(closed)
  const [price, setPrice] = useState('all')

  const onChange = (value) => {
    setPrice(value)
    console.log(value)
    setPriceClosable(opened)
  }

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Slider
            style={{width: '300px'}}
            range
            onChange={onChange} 
            // dots
            defaultValue={[0, 50000]}
            max={50000}
            // marks={[0, 1, 2, 3, 4, 5]}
            step={5000}
          />
        }

      >
        <Button
          type={priceClosable.type}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', paddingRight: priceClosable.padding}}
        >
          ₽ Цена
        </Button>
      </Popover>
      {!priceClosable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={()=>setPriceClosable(closed)} 
        type="text" 
      />}
    </div>
  )
}




const Directions = (props) => {
  const [directionsClosable, setDirectionsClosable] = useState(closed)
  const [data, setData] = useState()
  
  useEffect(()=>{
    const f = async () => {
    const resp = await get("my_directions")
    setData(resp.data)
    }
    f()
  }, [])

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck = async (checkedKeys, info) => {
    console.log('onCheck', info);
    var ids = []
    info.checkedNodes.map(node=>{
      if (!node.children) { ids.push(node.key) }
      else {node.children.map(node1=>{
        if (!node1.children) { ids.push(node1.key); return }
        node1.children.map(node2=>{
          if (!node2.children) { ids.push(node2.key); return }
          node2.children.map(node3=>{
            if (!node3.children) { ids.push(node3.key); return }
          })
        })
      })}
    })
    console.log(ids.filter((value, index, self)=>self.indexOf(value) === index))
    props.setFilter({...props.filter, directions: ids.join(',')})
    // let formData = new FormData
    // formData.append('directions', ids)
    // await post('add_delete_my_directions', {id: id, action: info.checked ? 'add' : 'delete'}, formData)
  };

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          data&&
          <Tree
            checkable
            onSelect={onSelect}
            // onCheck={onCheck}
            defaultExpandedKeys={data.params.defaultExpandedKeys}
            defaultCheckedKeys={data.params.defaultCheckedKeys}
            onCheck={(checkedKeys, info)=>onCheck(checkedKeys, info)}
            treeData={data.data}
          />
        }
      >
        <Button
          type={directionsClosable.type}
          icon={<IoLocationOutline style={{color: '#40a9ff'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', paddingRight: directionsClosable.padding}}
        >
          Направления
        </Button>
      </Popover>
      {!directionsClosable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={()=>setDirectionsClosable(closed)} 
        type="text" 
      />}
    </div>
  )
}




const options = [
  {key: "minibus", label: "Микроавтобус"},
  {key: "offroad_van", label: "Минивэн 4WD"},
]
const Type = (props) => {
  const [typeClosable, setTypeClosable] = useState(closed)
  const [checkedList, setCheckedList] = useState(options.map(option=>option.key));
  const [checkAll, setCheckAll] = useState(true);

  const onChange = list => {
    setTypeClosable(opened)
    setCheckedList(list);
    setCheckAll(list.length === options.length);
    props.setFilter({...props.filter, type: list.join(',')})
  };

  const onCheckAllChange = e => {
    setTypeClosable(opened)
    setCheckedList(checkedList.length===options.length ? [] : options.map(option=>option.key));
    setCheckAll(checkedList.length===options.length ? false : true);
    props.setFilter(checkedList.length===options.length ? props.filter : {... props.filter, type: options.map(option=>option.key)})
  };

  const onCancel = () => {
    setTypeClosable(closed)
    setCheckedList(options.map(option=>option.key));
    props.setFilter({...props.filter, type: []})
  }

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <>
            <Row>
              <Col>
                <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                  { checkedList.length===options.length ? "Снять все" : "Выделить все" }
                </Checkbox>
              </Col>
            </Row>
            <Checkbox.Group value={checkedList} onChange={onChange} >
              <Divider />
              {options.map(option=>{
                return (
                  <Row>
                    <Col>
                      <Checkbox value={option.key}>
                        <img src={`http://rocktrip.ru/storage/car_types/${option.key}.png`} style={{height: '20px'}} />
                        &nbsp;{option.label}
                      </Checkbox>
                    </Col>
                  </Row>
                )
              })}
            </Checkbox.Group>
          </>
        }
      >
        <Button
          type={typeClosable.type}
          // icon={<IoLocationOutline style={{color: '#40a9ff'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', paddingRight: typeClosable.padding}}
        >
          <img src='http://rocktrip.ru/storage/car_types/wheel.png' style={{width: 20, height: 20}}/>&nbsp;Тип
        </Button>
      </Popover>
      {!typeClosable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text" 
      />}
    </div>
  )
}





const TransfersFilter = (props) => {


  return (
    <div>

      {/* <Select 
        mode="tags" 
        placeholder="Расположение" 
        defaulValue="Любое" 
        style={{minWidth: '120px', display: 'inline-block'}}

      >
        {options.map(option=>{
          return (
            <Option key={option.key} style={{width: '300px'}}>
              <img src={`http://rocktrip.ru/storage/icons/${option.key}.png`} style={{height: '25px'}} />
              &nbsp;{option.label}
            </Option>
          )
        })}
      </Select> */}
      {!props.distance&&<Directions setFilter={props.setFilter} filter={props.filter} />}
      <Type setFilter={props.setFilter} filter={props.filter} />
      <Price setFilter={props.setFilter} />
      <Rate setFilter={props.setFilter} />
      <Fav setFilter={props.setFilter} filter={props.filter} />
    </div>        
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    distance: state.transfersReducer.distance,
    filter: state.filterReducer.transfers_filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    setFilter: (filter) => {
      dispatch(actionTransfersFilter(filter));
    },
})}


export default connect(mapStateToProps, mapDispatchToProps)(TransfersFilter)