import {
    ACTION_TRANSFER_VISIBLE,
    ACTION_TRANSFER_DATA,
  } from "./types";
  
  

  
export const actionTransferVisible = (visible) => ({
  type: ACTION_TRANSFER_VISIBLE,
  payload: visible
});


export const actionTransferData = (transfer) => ({
  type: ACTION_TRANSFER_DATA,
  payload: transfer
});
  