const initialMetaState = {
  visible: false,
  editData: [],
  addFormKey: undefined,
};

export const addFormReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_ADDFORM_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_ADDFORM_KEY":
      return { ...state, addFormKey: action.payload };
    case "ACTION_ADDFORM_EDITDATA":
      return { ...state, editData: action.payload };
    default:
      return state;
  }
};
