const initialMetaState = {
  visible: false,
  data: [],
  urlCoords: "",
  legs: [],
};

export const myrouteReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_MYROUTE_URL_COORDS":
      return { ...state, urlCoords: state.data.map(item=>`${item.lng},${item.lat}`).join(';') };
    case "ACTION_MYROUTE_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_MYROUTE_REMOVE":
      return { 
        ...state, 
        data: state.data.filter(wayPoint=>JSON.stringify(wayPoint)!==JSON.stringify(action.payload)), 
        urlCoords: state.data.filter(wayPoint=>JSON.stringify(wayPoint)!==JSON.stringify(action.payload)).map(item=>`${item.lng},${item.lat}`).join(';'), 
      };
    case "ACTION_MYROUTE_MOVE":
      return { ...state, data: action.payload, urlCoords: action.payload.map(item=>`${item.lng},${item.lat}`).join(';') };
    case "ACTION_MYROUTE_LEGS":
      return { ...state, legs: [...[{duration: 0, distance: 0}], ...action.payload] };
    case "ACTION_MYROUTE_ADD":
      return { ...state, data: [...state.data, action.payload], urlCoords: [...state.data, action.payload].map(item=>`${item.lng},${item.lat}`).join(';')}
    default:
      return state;
  }
};
