import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, List, Typography, Layout, Modal, Skeleton, Divider, Avatar } from 'antd'
import { AiOutlineFilter } from "react-icons/ai";
import { FiMap } from "react-icons/fi";
import { FaMap } from "react-icons/fa";
import InfiniteScroll from 'react-infinite-scroll-component';
import { get, fetchData } from "./../api/fetchData";
import { actionRollVisible } from "./../actions/roll"
import { actionDirectionVisible } from "./../actions/direction"
import { actionDirectionData } from "./../actions/direction"


const {Content} = Layout






const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.rollReducer.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    selectDirection: (direction) => {
      dispatch(actionDirectionData(direction));
      dispatch(actionDirectionVisible(true));
    },
    rollVisible: (visible) => {
      dispatch(actionRollVisible(visible));
    },
})}










export const RollVisible = connect(mapStateToProps, mapDispatchToProps)((props) => {
  const onClick = () => {
    props.rollVisible(!props.visible)
  }
  return (
    <Button
      icon={props.visible ? <FaMap /> : <FiMap />}
      onClick={onClick}
      type="text"
    />
  )
})














const Roll = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [batch, setBatch] = useState(1);

  const loadMoreData = async () => {
    if (loading) {return}
    setLoading(true);
    const resp = await get('directions', {mode: 'list', batch: batch})
    setData([...data, ...resp.data]);
    setLoading(false);
    setBatch(batch+1)
  };

  useEffect(()=>{
    loadMoreData()
  }, [])

  const onClick = (direction) => {
    props.selectDirection({...direction, images: `["${direction.images.join('","')}"]`})
  }
  
  if (props.visible) {
    return (
      <Content className='parent-roll'>
        <div className='child-roll'>
          <div
            id="scrollableDiv"
            style={{
              height: 'inherit',
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={loadMoreData}
              hasMore={data.length < 50}
              // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<Divider plain>Все записи</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={data}
                renderItem={direction=>(
                  <List.Item 
                    key={direction.id} 
                    style={{cursor: 'pointer'}} 
                    onClick={()=>onClick(direction)} 
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={`http://rocktrip.ru/storage/directions/${direction.id}/avatar.png`} />}
                      title={direction.name}
                      description={direction.district}
                    />
                    <p style={{width: '75px', textAlign: 'right'}}>{direction.type}</p>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
      </Content>
    )
  } else { 
    return null
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Roll)