// import { PageHeader, Tooltip, Button } from 'antd';
import { Layout, Tooltip, Button } from 'antd';
import { VscSignOut } from "react-icons/vsc"



const { Header } = Layout


const Signout = () => {
  const onClick = () => {
    localStorage.removeItem("user");
    window.location.reload()
  }
  return (
    <Tooltip>
      <Button 
        onClick={onClick}
        icon={<VscSignOut />} 
        type="text" 
        danger 
      />
    </Tooltip>

  )
}


const MyHeader = () => {
    return (
      <Header
        className="site-page-header-responsive"
        title={<h2 style={{color: 'orange', fontWeight: 'bold'}}>Rocktrip.ru</h2>}
        subTitle="partner"
        extra={[
          <Signout />,
        ]} 
      >
      </Header>
    )
}


export default MyHeader
