import { createBrowserHistory } from "history";

export const history = createBrowserHistory();


export const rule = [{ required: true, message: 'Обязательное поле' },]


export function minTommss(minutes){
  if (minutes) {
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  } else {
    return "00:00"
  }

}


export function minTommss2(duration){
  if (duration) {
    var hours = Math.ceil(duration)
    if (hours>96) {return `${Math.ceil(hours/24)} дней`}
    else if (hours>24||Math.ceil(hours/24)>1) {return `${Math.ceil(hours/24)} дня`} 
    else if (hours>4) {return `${hours} часов`} 
    else if (hours>1) {return `${hours} часа`} 
    else if (hours===1) {return `${hours} час`} 
  } else {
    return "менее часа"
  }

}