import { useState, useEffect } from 'react'
import { Button, Divider } from 'antd'
import { connect } from 'react-redux'
import EntityCard from './../commonTemplates/entityCard'
import { 
  actionTransfersVisible,  
  actionTransfersDistance,
  actionTransfersSelected,
} from "./../../actions/transfers"
import { 
  actionTransferVisible,  
  actionTransferData,
} from "./../../actions/transfer"
import {
  actionTransfersFilter
} from './../../actions/filter'




const SelectTransfer = (props) => {

    const onClick = () => {
      props.setFilter({...props.filter, directions: props.myRoute.map(d=>d.id).join(',')})
      props.setDistance(Math.round(props.legs.map(leg=>leg.distance).reduce((acc, a)=>acc+a, 0)/10)/100)
      props.transfersVisible(true)
    }

    const onDeselect = (id) => {
      props.setSelectedTransfer(props.transfers_selected.filter(selected=>selected.id!==id))
    }

    const onTransferClick = (e, transfer) => {
      if (e.target.localName==='div') {
        props.selectTransfer({...transfer, images: `["${transfer.images.join('","')}"]`, strict: true})
      }
    }


    return (
      <>
        <Divider orientation='left' >Трансфер</Divider>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button onClick={onClick} style={{marginRight: '20px'}}>Подобрать</Button>
          <h5>*Можно выбрать несколько</h5>
        </div>
        {/* {props.transfers_selected.map(transfer=>{return (
            <div onClick={(e)=>{onTransferClick(e, transfer)}}>
            <Card hoverable={true} bodyStyle={{height: '60px', padding: '1px'}} style={{margin: '5px'}}>
              <div style={{display: 'block', height: '100%'}}>
                <img 
                  src={transfer.avatar} 
                  style={{height: '58px', width: '58px', marginRight: '10px', display: 'inline-block'}}
                />
                <div style={{display: 'inline-block'}}>
                  {transfer.brand} {transfer.model}
                </div>
                <div style={{display: 'inline-block', float: 'right', height: '100%'}}>
                  <div style={{display: 'flex', alignItems: 'center', color: 'gray', height: '100%', marginRight: '5px', fontSize: '13px'}}>
                    <h4>{`${transfer.min_price} ₽`}</h4>
                    <Button onClick={()=>onDeselect(transfer.id)} icon={<AiOutlineClose />} type='text' danger />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )})} */}
        {props.transfers_selected.map(transfer=><EntityCard entity={transfer} type='transfer' w={props.w.clientWidth} selectEntity={props.selectTransfer} />)}
      </>
    )
  }




const mapStateToProps = (state, ownProps) => {
  return {
    legs: state.myrouteReducer.legs,
    visible: state.transfersReducer.visible,
    filter: state.filterReducer.transfers_filter,
    myRoute: state.myrouteReducer.data,
    transfers_selected: state.transfersReducer.selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    transfersVisible: (visible) => {
      dispatch(actionTransfersVisible(visible));
    },
    setFilter: (filter) => {
      dispatch(actionTransfersFilter(filter));
    },
    setDistance: (distance) => {
      dispatch(actionTransfersDistance(distance));
    },
    setSelectedTransfer: (transfers_selected) => {
      dispatch(actionTransfersSelected(transfers_selected));
    },
    selectTransfer: (transfer) => {
      dispatch(actionTransferData(transfer));
      dispatch(actionTransferVisible(false));
      setTimeout(()=>{dispatch(actionTransferVisible(true))})
    },
})}

export default connect(mapStateToProps, mapDispatchToProps)(SelectTransfer)