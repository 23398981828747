import { useState, useEffect } from 'react';
import { Rate } from 'antd';
import { get } from './../api/fetchData';




const Rates = (props) => {
	const [rate, setRate] = useState(props.rate)
	const [cnt, setCnt] = useState(0)
  const [disabled, setDisabled] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [user_id, setUser_id] = useState()

	useEffect(()=>{
    const f = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) { setUser_id(user.id); setDisabled(false) }
    if (!props.rate) {
		  const resp = await get('rates', {parent_id: props.parent_id})
		  setCnt(resp.data.cnt)
		  setRate(resp.data.rate)
    }
    setLoaded(true)
  }
  f()
	}, [])



  const onChange = async (newRate) => {
    const resp = await get('rates', {rate: newRate, user_id: user_id, parent_id: props.parent_id})
		setCnt(resp.data.cnt)
		setRate(newRate)
  }

  return (
    <>
      &nbsp;
			{loaded&&<Rate allowHalf disabled={disabled} defaultValue={rate} onChange={onChange} />}
      &nbsp;
      {`(${cnt})`}
    </>
  );
}


export default Rates
