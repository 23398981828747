import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, Carousel, Divider, Image, Card, Rate, Row, Select, Slider, Spin } from 'antd'
import { AiOutlineFilter } from "react-icons/ai";
import { HeartTwoTone, StarFilled, PlusOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { 
  actionTransferVisible,  
  actionTransferData,  
} from "./../../actions/transfer"
import { 
  actionTransfersVisible,
  actionTransfersSelected,  
} from "./../../actions/transfers"
import { get } from "./../../api/fetchData";
import TransfersFilter from './../filters/transfersFilter'
import { Favorite } from './../favorites'
import { RouteProperty } from './../routes/routes'
import EntityCard from './../commonTemplates/entityCard'
import { EntitiesModal } from './../commonTemplates/modals'






const Transfers = (props) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const w = window.innerWidth-40
  const h = window.innerHeight
  
  useEffect(() => {
    const f = async () => {
    if (props.visible) {
      setLoading(true)
      const resp = await get('transfers', {...props.transfers_filter, mode: "list"})
      setData(resp.data)
      setLoading(false)
    }
  }
  f()
  }, [props.transfers_filter, props.visible])



    
  return (
    <EntitiesModal
      visible={props.visible}
      title="Все трансферы"
      setVisible={props.transfersVisible}
      h={h}
    >
      <TransfersFilter />
      <div style={{
        overflowY: 'scroll', 
        height: '93%',
        position: 'absolute',
        width: '100%',
      }}>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          {data&&data.map(transfer=><EntityCard 
            entity={{...transfer, min_price: props.distance ? props.distance*transfer.price : undefined}} 
            type='transfer' 
            w={w} 
            selectEntity={props.selectTransfer}
          />)}
        </Spin>
        <br/>
        <br/>
      </div>
    </EntitiesModal>
  )
}


const mapStateToPropsDbtn = (state, ownProps) => {
  return {
    transfers_selected: state.transfersReducer.selected,
  };
};


const mapStateToProps = (state, ownProps) => {
  return {
    legs: state.myrouteReducer.legs,
    distance: state.transfersReducer.distance,
    visible: state.transfersReducer.visible,
    transfers: state.transfersReducer.transfers,
    transfers_filter: state.filterReducer.transfers_filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    transfersVisible: (visible) => {
      dispatch(actionTransfersVisible(visible));
    },
    selectTransfer: (transfer) => {
      dispatch(actionTransferData(transfer));
      dispatch(actionTransferVisible(true));
    },
    setMyRouteSelectTransfer: (transfers_selected) => {
      dispatch(actionTransfersSelected(transfers_selected));
    }
})}


const TransferBtn = connect(mapStateToPropsDbtn, mapDispatchToProps)((props) => {
  const [myRouteBtn, setMyRouteBtn] = useState(props.transfers_selected.filter(selected=>selected.id===props.transfer.id).length===0
    ? 'Выбрать'
    : 'Выбрано'
  )

  const myRouteSelectTransfer = () => {
    if (myRouteBtn==='Выбрать') {
      props.setMyRouteSelectTransfer(props.transfers_selected.concat(props.transfer))
      setMyRouteBtn('Выбрано')
    } else {
      props.setMyRouteSelectTransfer(props.transfers_selected.filter(selected=>selected.id===props.transfer))
      setMyRouteBtn('Выбрать')
    }
  }
  return (
    <Tooltip title="Маршрут">
      <Button 
        icon={myRouteBtn==='Выбрать' ? <PlusOutlined style={{color: '#40a9ff'}} /> : <CheckOutlined style={{color: '#0b8235'}} /> } 
        style={{
          borderRadius: "10px", 
          color: myRouteBtn==='Выбрать' ? '#40a9ff' : '#0b8235',
          backgroundColor: myRouteBtn==='Выбрать' ? null : '#e5fce3',
          borderColor: myRouteBtn==='Выбрать' ? null : '#0b8235',
        }} 
        onClick={myRouteSelectTransfer}
      >
        {myRouteBtn}
      </Button>
    </Tooltip>
  )
})
// const TransferBtn = connect(mapStateToPropsDbtn, mapDispatchToProps)(TransferBtn_)

export default connect(mapStateToProps, mapDispatchToProps)(Transfers)