import {
    ACTION_MENU_VISIBLE,
  } from "./types";
  
  

export const actionMenuVisible = (visible) => ({
  type: ACTION_MENU_VISIBLE,
  payload: visible
});
  