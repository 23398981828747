export const ACTION_ROUTE_VISIBLE = "ACTION_ROUTE_VISIBLE"
export const ACTION_ROUTE_DATA = "ACTION_ROUTE_DATA"




export const ACTION_FILTER_VISIBLE = "ACTION_FILTER_VISIBLE"
export const ACTION_MENU_VISIBLE = "ACTION_MENU_VISIBLE"


export const ACTION_FAVORITES_VISIBLE = "ACTION_FAVORITES_VISIBLE"
export const ACTION_FAVORITES_DATA = "ACTION_FAVORITES_DATA"


export const ACTION_DIRECTIONS_VISIBLE = "ACTION_DIRECTIONS_VISIBLE"
export const ACTION_DIRECTIONS_FILTER = "ACTION_DIRECTIONS_FILTER"
export const ACTION_DIRECTIONS_DATA = "ACTION_DIRECTIONS_DATA"


export const ACTION_DIRECTION_VISIBLE = "ACTION_DIRECTION_VISIBLE"
export const ACTION_DIRECTION_DATA = "ACTION_DIRECTION_DATA"


export const ACTION_ROUTES_VISIBLE = "ACTION_ROUTES_VISIBLE"
export const ACTION_ROUTES_FILTER = "ACTION_ROUTES_FILTER"
export const ACTION_ROUTES_DATA = "ACTION_ROUTES_DATA"


export const ACTION_MYROUTE_VISIBLE = "ACTION_MYROUTE_VISIBLE"
export const ACTION_MYROUTE_URL_COORDS = "ACTION_MYROUTE_URL_COORDS"
export const ACTION_MYROUTE_REMOVE = "ACTION_MYROUTE_REMOVE"
export const ACTION_MYROUTE_MOVE = "ACTION_MYROUTE_MOVE"
export const ACTION_MYROUTE_LEGS = "ACTION_MYROUTE_LEGS"
export const ACTION_MYROUTE_ADD = "ACTION_MYROUTE_ADD"


export const ACTION_TRANSFERS_VISIBLE = "ACTION_TRANSFERS_VISIBLE"
export const ACTION_TRANSFERS_FILTER = "ACTION_TRANSFERS_FILTER"
export const ACTION_TRANSFERS_SELECTED = "ACTION_TRANSFERS_SELECTED"
export const ACTION_TRANSFERS_DISTANCE = "ACTION_TRANSFERS_DISTANCE"


export const ACTION_TRANSFER_VISIBLE = "ACTION_TRANSFER_VISIBLE"
export const ACTION_TRANSFER_DATA = "ACTION_TRANSFER_DATA"


export const ACTION_ADDFORM_VISIBLE = "ACTION_ADDFORM_VISIBLE"
export const ACTION_ADDFORM_EDITDATA = "ACTION_ADDFORM_EDITDATA"
export const ACTION_ADDFORM_KEY = 'ACTION_ADDFORM_KEY'


export const ACTION_SIGNUP = "ACTION_SIGNUP"
export const ACTION_SIGNIN = "ACTION_SIGNIN"
export const ACTION_SIGNOUT = "ACTION_SIGNOUT"


export const ACTION_ROLL_VISIBLE = "ACTION_ROLL_VISIBLE"
export const ACTION_ROLL_SCREEN_HEIGHT = "ACTION_ROLL_SCREEN_HEIGHT"


