import { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Col, Row, Spin, Avatar, message } from "antd";
import { UserOutlined, LogoutOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { history } from './../helpers/helpers'
import { Router, Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import Auth from "./auth/auth.new";
import { ImExit } from "react-icons/im";
import { connect } from "react-redux";
import { actionAddUser } from "../actions/common";
import { get, post } from './../api/fetchData'
const jwt = require('jwt-encode')




const rule = [{required: true, message: 'Обязательное поле',}]


const mapStateToProps = (state, ownProps) => ({user: state.commonReducer.user,})
const mapDispatchToProps = dispatch => ({addUser: data => {dispatch(actionAddUser(data))}})




const ChangePassword = props => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onClick = async () => {
    setLoading(true)
    const fields = form.getFieldsValue()
    const resp = await get('change_password', fields)
    if (resp.status===200) {message.success('Пароль успешно изменен'); setOpen(false)}
    // else {message.error('Ошибка')}
    else {message.error(resp.data.detail)}
    setLoading(false)
  }

  return (
    <div>
      <Button onClick={()=>setOpen(true)} style={{width: '100%'}} size='large' >Сменить пароль</Button>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{fontSize: 24 }} spin />} >
        <Modal
          title={<div style={{padding: '20px', paddingTop: '10px'}}><h3>Смена пароля</h3></div>}
          open={open}
          closable={false}
          onCancel={()=>setOpen(false)}
          footer={[<Button type='primary' onClick={onClick} >Сменить пароль</Button>, <Button onClick={()=>setOpen(false)}>Отмена</Button>]}
          bodyStyle={{padding: '40px'}}
        >
          <Form form={form} >
            <Row gutter={16} >
              <Col span={8} >Старый пароль</Col>
              <Col span={16} >
                <Form.Item name='old_pass' rules={rule} >
                  <Input.Password size='large' prefix={<LockOutlined className="site-form-item-icon" />} />                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col span={8} >Новый пароль</Col>
              <Col span={16} >
                <Form.Item name='new_pass' rules={rule} >
                  <Input.Password size='large' prefix={<LockOutlined className="site-form-item-icon" />} />                  
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </div>
  )
}



const UserMenu = connect(mapStateToProps, mapDispatchToProps)(props => {
  const [form] = Form.useForm()
  
  useEffect(()=>{form.setFieldsValue(props.user)}, [])

  const save = async () => {
    const fields = form.getFieldsValue()
    if (!fields.mail) {message.error('Заполните электронную почту')}
    else if (!fields.username) {message.error('Заполните имя пользователя')}
    const formData = new FormData()
    formData.append('data', jwt(fields, "SECRET"))
    const resp = await post('edituser', undefined, formData) 
    if (resp.status===200) {message.success('Сохранено')}
    else {message.error('Ошибка при сохранении')}
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("https://cdn.tripster.ru/thumbs2/ac3604d6-c2dd-11eb-bfc1-4e8a1e03d1bb.800x600.jpg")',
      backgroundSize: "cover",
      backgroundColor: 'gray',
      backgroundBlendMode: 'screen',
    }}>
      <div style={{display: 'flex', flexDirection: 'row', width: '400px', height: '700px', marginTop: '80px', justifyContent: 'center', backgroundColor: 'white', borderRadius: '20px'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px' }}>
            <Avatar size={128} icon={<UserOutlined />} style={{textAlign: 'center'}}/>
          </div>
          <Form form={form} >
            <Row gutter={16} >
              <Col span={8} >Электронная почта</Col>
              <Col span={16} >
                <Form.Item name='mail' rules={rule} >
                  <Input size='large' />                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col span={8} >Имя пользователя</Col>
              <Col span={16} >
                <Form.Item name='username' rules={rule} >
                  <Input size='large' />                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col span={8} >
                Телефон
              </Col>
              <Col span={16} >
                <Form.Item name='phone' >
                  <Input size='large' />                  
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col span={8} >Пароль</Col>
              <Col span={16} >
                {/* <Form.Item name='password' >
                  <Input.Password size='large' prefix={<LockOutlined className="site-form-item-icon" />} />                  
                </Form.Item> */}
                <ChangePassword />
              </Col>
            </Row>
          </Form>
          <Button 
            size='large'
            onClick={save}
            style={{marginTop: '20px'}}
            type='primary'
          >Сохранить изменения</Button>
          { props.user.admin && <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
            <Link to='/admin' style={{ textDecoration: 'none' }}><Button type='link' size='large'>Панель администратора</Button></Link>
          </div> }
          {/* { props.user.admin && <Link to='/admin' style={{ display: 'flex', width: '100%', textDecoration: 'none' }}>
            <Button type='link' size='large'>Панель администратора</Button>
          </Link> } */}
          <Button 
            size='large'
            danger
            icon={<ImExit />}
            onClick={()=>{localStorage.removeItem('user');window.location.reload()}}
            style={{marginTop: '100px'}}
          >Выйти</Button>
        </div>
      </div>
    </div>
  )
})






export default UserMenu