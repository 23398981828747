import { useState, useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { connect } from "react-redux";
import * as turf from '@turf/turf'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { 
  actionDirectionVisible,
  actionDirectionData, 
} from "./../../actions/direction"
import { actionMyRouteLegs } from "./../../actions/myroute"
import { 
  actionRollVisible,
  actionRollScreenHeight
} from "./../../actions/roll"
import Direction from './../directions/direction'
import Route from './../routes/route'
import Transfer from './../transfers/transfer'
import Transfers from './../transfers/transfers'
import Directions from './../directions/directions'
import Routes from './../routes/routes'
import MyRoute from "./../routes/myRoute";

import { get } from "./../../api/fetchData";
import './../../loading.css'




mapboxgl.accessToken = 'pk.eyJ1IjoicnVzc3NpazEiLCJhIjoiY2t2Nzl0Y3Z1MW82ZDJ1czNxMzYyYTFzbCJ9.2bXmmydb3D-zOZhP6LDkdA';
export function importAll(r) {
  const images = Object.assign(...r.keys().map((k, i) => ({[k.replace('./', '').replace('.png', '')]: r.keys().map(r)[i].default})))
  return images
}

export const images = importAll(require.context('./../../static/icons/', false, /\.(png|jpe?g)$/));

function polyMask(mask) {
  var bboxPoly = turf.bboxPolygon([180, 90, -180, -90]);
  return turf.difference(bboxPoly, mask);
}

export const coords = async (urlCoords) => {
  const resp = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/cycling/${urlCoords}?steps=true&geometries=geojson&overview=full&access_token=${mapboxgl.accessToken}`,
    { method: 'GET' }
  )
  return resp.json()
}

export const optiCoords = async (urlCoords) => {
  var destination = ""
  if (urlCoords.split(';')[0]===urlCoords.split(';')[urlCoords.split(';').length-1]) {destination='&destination=last'}
  const resp = await fetch(`https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${urlCoords}?steps=true&geometries=geojson&overview=full&source=first${destination}&access_token=${mapboxgl.accessToken}`,
    { method: 'GET' }
  )
  return resp.json()
}


const Map = props => { 
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [style, setStyle] = useState()


  async function getRoute() {
    if (props.urlCoords==="") {if (map) {if (map.getLayer('routeImg')) {map.removeLayer('routeImg')}}; return}
    if (props.urlCoords.split(';').length<2) {
      map.removeLayer('route');
      if (map.getSource('route')) {map.removeSource('route')}
    } else {
      const json = await coords(props.urlCoords);
      const data = json.routes[0]; // duration distance in legs
      props.myRouteLegs(data.legs)
      const route = data.geometry.coordinates;
      const geojson = {
      type: 'Feature',
      properties: {},
      geometry: {type: 'LineString',coordinates: data.geometry.coordinates}
    };
    // if the route already exists on the map, we'll reset it using setData
    if (map.getSource('route')) {map.getSource('route').setData(geojson);}
    // otherwise, we'll make a new request
    else {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#3887be',
          'line-width': 5,
          'line-opacity': 0.75
        }
      });
    }}
    if (props.urlCoords.split(';').length>0) {routeIcons()}
    if (map.getLayer('route')) {map.moveLayer('route', 'routeImg')};
  }


  const routeIcons = () => {
    const routeImgsource = {type: "FeatureCollection", features: []}
    if (props.urlCoords!=="") {
      props.urlCoords.split(';').map((coord, index)=>{
        routeImgsource.features.push({
          type: 'Feature',
          geometry: {type: 'Point', coordinates: coord.split(',')},
          properties: {icon: `${index}`},
        })
      })
    }
  
    if (map.getSource('routeImg')) {
      map.getSource('routeImg').setData(routeImgsource);
      // if (props.urlCoords==="") {map.removeSource('routeImg')}
    } else {
      var cases = ['case']
      Object.keys(images).map(img=>{cases.push(['==', ["get", "icon"], img], img)})
      cases.push('custom')
      Promise.all(
        Object.keys(images).map(img => new Promise((resolve, reject) => {
          map.loadImage(images[img], function (error, image) {
            if (error) {console.log(error)};
            map.addImage(img, image, { 'sdf': false });
              resolve();
          })
        }))
      )
      .then(
        map.addLayer({
          id: 'routeImg',
          type: 'symbol',
          source: {type: 'geojson', data: routeImgsource},
          filter: ['!', ['has', 'point_count']],
          'layout': {
          'icon-image': cases,
          'icon-size': 0.5
          }
        })
      )
    }
  }


  useEffect(() => {
    const f = async () => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/outdoors-v11",
        center: [44.1786, 42.9507],
        zoom: 8,
        pitch: 45,
        // bearing: 80,

    });

    const resp = await get('directions');
    const borders = await get('borders');
    // add navigation control (zoom buttons)
    // map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
      enableHighAccuracy: true},
      trackUserLocation: true,
      showUserLocation:true},
    )

    map.on("load", async () => {
      map.addSource("directions", {
        type: "geojson",
        data: resp.data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 70,
      });
      // map.addControl(
      //   new mapboxgl.GeolocateControl({
      //   positionOptions: {
      //   enableHighAccuracy: true
      //   },
      //   // When active the map will receive updates to the device's location as it changes.
      //   trackUserLocation: true,
      //   // Draw an arrow next to the location dot to indicate which direction the device is heading.
      //   showUserHeading: true
      //   }), "bottom-right"
      // );

      map.addControl(geolocateControl, "bottom-right");
      // geolocateControl.trigger();
      geolocateControl.on('geolocate', function(e) {
        const lng = e.coords.longitude;
        const lat = e.coords.latitude
        const userPosition = [lng, lat];
        console.log(userPosition)
        // if(userPosition) {
        //   setUserLocation(userPosition);
        //   createCircleLayer(userPosition,map, setRadius);
        // }
      });


      // geolocateControl.trigger();
      // if(userLocation){
      //  map.setCenter(userLocation[1],userLocation[0]);
      // }

      for (const [id, data] of Object.entries(borders.data)) {
        // map.addSource(id, {
        //   type: "geojson",
        //   data: {
        //     type: 'Feature',
        //     properties: {},
        //     geometry: {
        //       type: 'LineString',
        //       coordinates: data
        //     }
        //   }
        // });
  

        map.addSource(`polygon_${id}`, {
          type: "geojson",
          data: polyMask(turf.polygon([data])),
        });
        map.addLayer({
          id: `polygon_${id}`,
          type: 'fill',
          source: `polygon_${id}`,
          'layout': {},
          paint: {
            'fill-color': 'black', // blue color fill
            'fill-opacity': 0.5,
          }
        });
        map.addLayer({
          id: `polygon_line_${id}`,
          type: 'line',
          source: `polygon_${id}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': 'white',
            'line-width': 2
          }
        });
      }


      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'directions',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#51bbd6',
          'circle-radius': 20
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'directions',
        filter: ['has', 'point_count'],
        layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
        }
      });

      var cases = ['case']
      Object.keys(images).map(img=>{cases.push(['==', ["get", "icon"], img], img)})
      cases.push('custom')
      Promise.all(
        Object.keys(images).map(img => new Promise((resolve, reject) => {
          map.loadImage(images[img], function (error, image) {
            if (error) {console.log(error)};
            map.addImage(img, image, { 'sdf': false });
              resolve();
          })
        }))
      )
      .then(
        map.addLayer({
          id: 'directions-layer',
          type: 'symbol',
          source: 'directions',
          filter: ['!', ['has', 'point_count']],
          'layout': {
            'icon-image': cases,
            'icon-size': 0.5,
            "text-field": ['get', 'label'],
            // "text-rotation-alignment": "auto",
            // "text-allow-overlap": true,
            "text-font": ["Arial Unicode MS Bold"],//, "Arial Unicode MS"], Arial Unicode MS Bold
            "text-offset": [1.2, 0],
            "text-anchor": "left",
            "text-size": 14,
            // 'text-opacity': 0.1,
            // "text-halo-color": "rgba(0, 0, 0, 0.2)",
          },
          'paint': {
            "text-halo-color": "rgba(255, 255, 255, 0.5)",
            "text-halo-width": 200,
            // "text-color": "#fff",
          },
        })
      )


    });


    



    setMap(map)

    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
      const clusterId = features[0].properties.cluster_id;
      map.getSource('directions').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        map.easeTo({ center: features[0].geometry.coordinates, zoom: zoom })
      })
    });
       
    map.on('mouseenter', 'clusters', () => {map.getCanvas().style.cursor = 'pointer'})
    map.on('mouseleave', 'clusters', () => {map.getCanvas().style.cursor = ''})
    // change cursor to pointer when user hovers over a clickable feature
    map.on("mouseenter", "directions-layer", e => { if (e.features.length) { map.getCanvas().style.cursor = "pointer"}});
    // reset cursor to default when user is no longer hovering over a clickable feature
    map.on("mouseleave", "directions-layer", () => {map.getCanvas().style.cursor = "";});
    // add popup when user clicks a point
    map.on("click", "directions-layer", e => { if (e.features.length) {props.selectDirection(e.features[0].properties, true)}});
    map.on("mousedown", e => { props.rollVisible(false)});
    map.on("click", e => {});
    if (mapContainerRef.current) {props.setScreenHeight(mapContainerRef.current.clientHeight)}
    setTimeout(()=>{setLoading(false)}, 1000)
    // clean up on unmount
    return () => map.remove();
  }
  f()
  }, []); 






  useEffect(()=>{
    getRoute()
  }, [props.urlCoords])




  return (
    <div>
      {props.directionVisible&&<Direction />}
      {props.routeVisible&&<Route />}
      {props.transferVisible&&<Transfer />}
      {props.directionsVisible&&<Directions />}
      {props.routesVisible&&<Routes />}
      {props.transfersVisible&&<Transfers />}
      {/* {props.urlCoords!==""&&<MyRoute />} */}
      <MyRoute />
      {/* <div className="test" >AAAAAAAA</div> */}
      <div className="map-container" ref={mapContainerRef} />
      {loading&&<div class="balls-parent"><div class="balls"><div /><div /><div /></div></div>}
    </div>
  )
};








const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.rollReducer.visible,
    directionVisible: state.directionReducer.visible,
    routeVisible: state.routeReducer.visible,
    transferVisible: state.transferReducer.visible,
    directionsVisible: state.directionsReducer.visible,
    routesVisible: state.routesReducer.visible,
    transfersVisible: state.transfersReducer.visible,
    urlCoords: state.myrouteReducer.urlCoords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    selectDirection: (direction, visible) => {
      dispatch(actionDirectionData(direction));
      dispatch(actionDirectionVisible(visible));
    },
    myRouteLegs: (legs) => {
      dispatch(actionMyRouteLegs(legs));
    },
    rollVisible: (visible) => {
      dispatch(actionRollVisible(visible));
    },
    setScreenHeight: (height) => {
      dispatch(actionRollScreenHeight(height));
    } 
})}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
