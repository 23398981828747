import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, Carousel, Divider, Image, Card, Rate, Row, Select, Slider, Spin } from 'antd'
import { StarFilled, PlusOutlined, CheckOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { actionDirectionsVisible } from "./../../actions/directions"
import { actionDirectionVisible, actionDirectionData } from "./../../actions/direction"
import { actionMyRouteRemove, actionMyRouteMove, actionMyRouteAdd } from "./../../actions/myroute"
import { get } from "./../../api/fetchData";
import DirectionsFilter from './../filters/directionsFilter'
import EntityCard from './../commonTemplates/entityCard'
import { EntitiesModal } from './../commonTemplates/modals'




const Directions = (props) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  const w = window.innerWidth-40
  const h = window.innerHeight

  useEffect(() => {
    const f = async () => {
      setLoading(true)
      const resp = await get('directions', {...props.directions_filter, type: props.directions_filter.type.join(','), mode: "list"})
      setData(resp.data)
      setLoading(false)
    }
    f()
  }, [props.directions_filter]) 


  const BackBtn = () => {
    return (
      <Tooltip title="Вернуться" >
        <Button 
          onClick={()=>props.directionsVisible(false)} 
          icon={<ArrowLeftOutlined />} 
          type="text"
        />
      </Tooltip>
    )
  }




    
  return (
    <EntitiesModal
      visible={props.visible}
      title="Все направления"
      setVisible={props.directionsVisible}
      h={h}
    >
      <DirectionsFilter />
      <div style={{
        overflowY: 'scroll', 
        height: '93%',
        position: 'absolute',
        width: '100%',
      }}>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          {data&&data.map(direction=><EntityCard entity={direction} type='direction' w={w} selectEntity={props.selectDirection} />)}
        </Spin>
        <br/>
        <br/>
      </div>
    </EntitiesModal>
  )
}




const mapStateToProps = (state, ownProps) => ({
  visible: state.directionsReducer.visible,
  directions: state.directionsReducer.directions,
  myRoute: state.myrouteReducer.data,
  directions_filter: state.filterReducer.directions_filter,
});

const mapDispatchToProps = dispatch => ({
    directionsVisible: visible => {
      dispatch(actionDirectionsVisible(visible));
    },
    selectDirection: direction => {
      dispatch(actionDirectionData(direction));
      dispatch(actionDirectionVisible(true));
    },
    myRouteAdd: wayPoint => {dispatch(actionMyRouteAdd(wayPoint))},
    myRouteRemove: wayPoint => {
      dispatch(actionMyRouteRemove(wayPoint));
    },
    myRouteMove: route => {dispatch(actionMyRouteMove(route))},
})

export default connect(mapStateToProps, mapDispatchToProps)(Directions)