const initialMetaState = {
  visible: false,
  selected: []
};

export const transfersReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_TRANSFERS_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_TRANSFERS_SELECTED":
      return { ...state, selected: action.payload };
    case "ACTION_TRANSFERS_DISTANCE":
      return { ...state, distance: action.payload };
    default:
      return state;
  }
};
