import { useState, useEffect, useRef } from "react"
import { connect } from "react-redux";
import { Button, Typography, Modal, Carousel, Divider, Card, Tabs, Rate, Popover, Tooltip } from 'antd'
import { StarFilled, CloseOutlined } from '@ant-design/icons';
import { AiOutlineFilter } from "react-icons/ai";
import { HiOutlineExternalLink } from "react-icons/hi";
import { HeartTwoTone } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';
import { actionDirectionVisible, actionDirectionData } from "./../../actions/direction"
import { actionRouteVisible, actionRouteData } from "./../../actions/route"
import { actionRoutesVisible } from "./../../actions/routes"
import { actionMyRouteMove, actionMyRouteAdd } from "./../../actions/myroute"
import { get } from "./../../api/fetchData";
import { Favorite } from './../favorites'
import Review from './../reviews'
import Rates from './../rate'
import EntityCard from './../commonTemplates/entityCard'
import { LinkTo } from "../header";
import { Link } from "react-router-dom";



const { TabPane } = Tabs;
// const { Link } = Typography;

const Direction = props => {
  const [dirInRoute, setDirInRoute] = useState([])

  const myRef = useRef()

  const w = window.innerWidth-40
  const h = window.innerHeight
  
  useEffect(()=>{
    const f = async () => {
    if (props.visible) {
      const resp = await get('routes', {direction_id: props.direction.id, limit: 3})
      setDirInRoute(resp.data)
      console.log(myRef)
    }
  }
  f()
  }, [props.visible])

  const onFilterBtnClick = () => {
    console.log(props.visible)
    props.directionVisible(true)
  }

  const onDirInRouteClick = (e, route) => {
    if (e.target.localName==='div') {
      props.selectRoute({...route, images: `["${route.images.join('","')}"]`})
    }
  }

  const onRoutesOpenClick = () => {
    props.routesVisible(true)
  }
  



  const AddBtn = () => {
    const [selected, setSelected] = useState()
    useEffect(()=>{setSelected(props.myRoute.filter(r=>r.lat===props.direction.lat&&r.lng===props.direction.lng).length!==0)}, [])
    const myRouteAddRemove = async () => {
      if (!selected) {props.myRouteAdd(JSON.parse(JSON.stringify(props.direction)))} 
      else {props.myRouteMove(props.myRoute.filter(r=>r.lat!==props.direction.lat&&r.lng!==props.direction.lng))}
    }
    return (
  	  <Tooltip title="Составить индивидуальный маршрут">
  	    <Button type={selected ? 'primary' : 'default'} onClick={myRouteAddRemove} size='large' style={{backgroundColor: !selected&&'RGB(238,238,238)'}}>
  	      { selected ? '✓Добавлено в мой маршрут' : '+Добавить в мой маршрут' }
  	    </Button>
  	  </Tooltip>
    )
  }


  const Title = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px'}} >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h2 style={{marginRight: '20px'}}>{props.direction.name}</h2>
          <Tooltip title='Оценить'>
            <Rates parent_id={props.direction.id} />
          </Tooltip>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Favorite 
            fav_id={props.direction.id} 
            add={props.direction.fav ? true : false} 
            type='direction'
          />
          <Tooltip title='Закрыть окно'>
            <Button 
              icon={<CloseOutlined />} 
              type='text' 
              style={{fontSize: '20px'}}
              onClick={()=>props.directionVisible(false)}
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  const d = props.direction
    
  return (
    <>
      <Modal
        visible={true}
        title={<Title />}
        style={{top: '10px', bottom: '10px'}}
        bodyStyle={{height: h-130}}
        width='70vw'
        onCancel={()=>props.directionVisible(false)}
        closable={false}
        footer={!props.direction.strict ? [<AddBtn />] : null}
      >
        <div style={{display: 'flex', width: '100%', height: '20px'}} />
        <div 
          style={{
            overflowY: 'scroll', 
            width: '100%', 
            height: h-150,
          }} 
          ref={myRef}
        >
          {props.direction && <ImageGallery 
            thumbnailPosition='right'
            items={JSON.parse(props.direction.images).map(i=>({original: i, thumbnail: i}))} 
            style={{backgroundColor: '#EDF1F2'}}
          />}
          <Tabs 
            defaultActiveKey="description" 
            onChange={()=>{}}
            style={{marginLeft: '40px', marginRight: '40px'}}
          >
            <TabPane tab="Описание" key="description">
              <span style={{fontWeight: 'bold', color: 'gray'}}>{`${d.region}, ${d.district ? d.district : d.town + d.address ? ', ' + d.address : ''}`}</span>
              <div style={{display: 'flex', flexDirection: 'row'}}>
              <span style={{color: 'gray', marginRight: '10px'}}>{`Координаты: ${d.lat}, ${d.lat}`}</span>
              {d.height && <span style={{color: 'gray', marginRight: '10px'}}>{`Над уровнем моря, м: ${d.height}`}</span>}
              <a 
                href={`https://yandex.ru/maps?whatshere[point]=${d.lng},${d.lat}&whatshere[zoom]z=16&ll=${d.lng},${d.lat}&z=16`}
                target="_blank"
              >
                Открыть в Яндекс.карте{<HiOutlineExternalLink />}
              </a>
              </div>
              <p style={{fontSize: '18px'}}>{props.direction.description}</p>
            </TabPane>
              {myRef.current&&dirInRoute.map(route=><EntityCard entity={route} type='route' w={myRef.current.clientWidth} selectEntity={props.selectRoute} />)}
              {dirInRoute.length>0 && <Link to='/routes' ><h4>Еще маршруты</h4></Link> }
            <TabPane tab={`Отзывы (${props.direction.review})`} key="reviews">
              <Review parent_id={props.direction.id} />
            </TabPane>
          </Tabs>
          <br/>
          <br/>
        </div>
      </Modal>
    </>
  )
}




const mapStateToProps = (state, ownProps) => ({
  visible: state.directionReducer.visible,
  direction: state.directionReducer.direction,
  myRoute: state.myrouteReducer.data,
})

const mapDispatchToProps = dispatch => ({
  directionVisible: visible => {dispatch(actionDirectionVisible(visible));},
  myRouteAdd: wayPoint => {dispatch(actionMyRouteAdd(wayPoint)) },
  myRouteMove: route => {dispatch(actionMyRouteMove(route));},
  selectRoute: route => {
    dispatch(actionRouteData(route));
    dispatch(actionRouteVisible(false));
    setTimeout(()=>{dispatch(actionRouteVisible(true))})
  },
  routesVisible: visible => {dispatch(actionRoutesVisible(visible));},
})


export default connect(mapStateToProps, mapDispatchToProps)(Direction)