import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { directionReducer } from "./reducers/direction";
import { directionsReducer } from "./reducers/directions";
import { filterReducer } from "./reducers/filter";
import { menuReducer } from "./reducers/menu";
import { rollReducer } from "./reducers/roll";
import { routeReducer } from "./reducers/route";
import { routesReducer } from "./reducers/routes";
import { myrouteReducer } from "./reducers/myroute";
import { transferReducer } from "./reducers/transfer"
import { transfersReducer } from "./reducers/transfers"
import { favoritesReducer } from "./reducers/favorites"
import { addFormReducer } from "./reducers/add"
import { commonReducer } from "./reducers/common"



const middleware = [thunk];




const store = createStore(
  combineReducers({
    directionReducer,
    directionsReducer,
    filterReducer,
    menuReducer,
    rollReducer,
    routeReducer,
    routesReducer,
    myrouteReducer,
    favoritesReducer,
    transferReducer,
    transfersReducer,
    addFormReducer,
    commonReducer,
  }),
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;