import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Select, Slider, Button, Popover, Radio, Space, Tree, Checkbox, Row, Col, Divider } from 'antd'
import { StarFilled, CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { IoLocationOutline } from "react-icons/io5";
import { get } from "./../../api/fetchData";
import { actionDirectionsFilter } from './../../actions/filter'
import { Rate, Fav, filterStyle, opened, closed, Search } from './commonFilters'
import { options, src } from './../../helpers/options'










const Location = props => {
  const [closable, setClosable] = useState(closed)
  const [data, setData] = useState()

  useEffect(()=>{
    const f = async () => {
      const resp = await get("directions", {...props.filter, mode: 'tree'})
      setData(resp.data)
    }
    f()
  }, [])

  const onCheck = async (checkedKeys, info) => {
    setClosable(opened)
    props.setFilter({...props.filter, location: info.checkedNodes.filter(node=>!node.children).map(item=>item.key).join(',')})
  };

  const onClose = () => {
    setClosable(closed)
    // setData({...data, params: {...data.params, defaultCheckedKeys: []}})
    props.setFilter({...props.filter, location: undefined})
  }

  return (
    <div style={filterStyle}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          data&&<Tree
            checkable
            onCheck={onCheck}
            defaultExpandedKeys={data.params.defaultExpandedKeys}
            defaultCheckedKeys={data.params.defaultCheckedKeys}
            treeData={data.data}
          />
        }
      >
        <Button
          type={closable.type}
          icon={<IoLocationOutline style={{color: 'black'}} />}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
        >
          Расположение
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onClose} 
        type="text" 
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}








const Type = props => {
  const [closable, setClosable] = useState(closed)

  const onChange = list => {
    setClosable(opened)
    props.setFilter({...props.filter, type: list})
  };

  const onCancel = () => {
    setClosable(closed)
    props.setFilter({...props.filter, type: []})
  }

  const Title = ({option}) => (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <img src={src(option.icon)} style={{height: '20px', marginRight: '5px'}} />
      <span>{option.type}</span>
    </div>
  )

  return (
    <div style={filterStyle}>
      <Popover trigger="click" placement="bottom" content={
          <>
            <Tree
              checkable
              defaultExpandedKeys={['all']}
              checkedKeys={props.filter.type}
              onCheck={onChange}
              treeData={[{title: 'Все', key: 'all', children: options.map(o=>({title: <Title option={o} key={Math.random()} />, key: o.icon}))}]}
            />
          </>
        }
      >
        <Button
          type={closable.type}
          onClick={(e)=>console.log(e)}
          style={{paddingLeft: '8px', borderRadius: '5px', paddingRight: closable.padding, backgroundColor: closable.backgroundColor}}
          icon={<HomeOutlined />}
        >
          {/* <img src={src('tower')} style={{width: 20, height: 20}}/>Тип */}
          Тип
        </Button>
      </Popover>
      {!closable.closed&&<Button 
        icon={<CloseOutlined style={{color: "rgba(52, 52, 52, 0.3)"}} />} 
        onClick={onCancel} 
        type="text"
        style={{borderRadius: '5px'}}
      />}
    </div>
  )
}








const DirectionsFilter = props => { 


  return (
    <div style={{marginBottom: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', backgroundColor: '#EEEEEE'}}>
      <div style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
        <Location setFilter={props.setFilter} filter={props.filter} />
        <Fav setFilter={props.setFilter} filter={props.filter} />
        <Type setFilter={props.setFilter} filter={props.filter} />
        <Rate setFilter={props.setFilter} filter={props.filter} />
      </div>
      <Search setFilter={props.setFilter} filter={props.filter} />
    </div>        
  )
}







const mapStateToProps = state => ({filter: state.filterReducer.directions_filter})
const mapDispatchToProps = dispatch => ({setFilter: filter => {dispatch(actionDirectionsFilter(filter))}})


export default connect(mapStateToProps, mapDispatchToProps)(DirectionsFilter)