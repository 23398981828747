import {useState, useEffect } from 'react';
import { AiOutlineUser } from "react-icons/ai";
import { Input, Button, Avatar, Modal, Tabs, Form, message, Row, Col, Spin } from 'antd'
import { LockOutlined, UserOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { GoogleLogin } from '@react-oauth/google';
import { post, get } from '../../api/fetchData';
const jwt = require('jwt-encode')


const rule = [{required: true, message: 'Обязательное поле',}]


const AuthForm = ({activeKey, form, forgotPswd, setForgotPswd}) => {
  
  

  // const handleSignin = async () => {
  //   props.setLoading(true)
  //   const fields = form.getFieldsValue()
  //   if (fields.mail===undefined) {message.error('Укажите почту')} 
  //   else if (fields.password===undefined) {message.error('Заполните пароль') } 
  //   else if (fields.password!==fields.passwordCheck) {message.error('Пароли не совпадают')} 
  //   else {
  //     const resp = await post('auth', props.activeKey, fields)
  //     if (resp.status===200 && resp.data.accessToken) {
  //       localStorage.setItem("user", JSON.stringify(resp.data))
  //       window.location.reload()
  //     } else {message.error(resp.data.detail)}
  //   }
  //   props.setLoading(false)
  // }

  return (
    <div>
      <Form
        layout="horizontal"
        name="basicForm"
        form={form}
        // onFinish={handleSignin}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Avatar size={128} icon={<UserOutlined />} style={{textAlign: 'center'}}/>
        </div>
        <Form.Item name="mail" rules={rule} >
          <Input prefix={<MailOutlined className="site-form-item-icon" />} size='large' placeholder='Электронная почта' />
        </Form.Item>
        {(!forgotPswd || activeKey==='signup') && <Form.Item name="password" rules={rule} >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} size='large' placeholder='Пароль' />
        </Form.Item>}
        {activeKey==='signin' && !forgotPswd && <Form.Item>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button type="link" size='large' onClick={()=>setForgotPswd(true)} >Забыли пароль</Button>  
          </div>
        </Form.Item>}
        {/* {activeKey==='signup' && <Form.Item name="passwordCheck" rules={rule} >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} size='large' placeholder='Повторите пароль' />
        </Form.Item>} */}
        {activeKey==='signup' && <Form.Item name="username" rules={rule} >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} size='large' placeholder='Имя пользователя' />
        </Form.Item>}

        {forgotPswd && activeKey==='signin' && <Form.Item>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button size='large'>Отправить письмо с восстановлением пароля</Button>  
            <Button type='text' size='large' onClick={()=>setForgotPswd(false)}>Отмена</Button>
          </div>
        </Form.Item>}
        {forgotPswd && activeKey==='signin' && <Form.Item>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <span style={{display: 'flex', justifyContent: 'center'}}>На указанный электронный адрес будет отправлено письмо со ссылкой для восстановления пароля</span>  
          </div>
        </Form.Item>}
      </Form>
    </div>
  )
}





const Auth = props => {
  const [activeKey, setActiveKey] = useState('signin')
  const [forgotPswd, setForgotPswd] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleGoogleAuth = async credentialResponse => {
    console.log(jwt(credentialResponse.credential, "SECRET"));
    console.log(credentialResponse.credential);
    const formData = new FormData()
    formData.append('data', credentialResponse.credential)
    const resp = await post('auth', {type: 'gauth'}, formData)
    // const resp = await post('auth', {type: 'gauth'}, {data: credentialResponse.credential})
    // const resp = await post('auth', {type: 'gauth'}, {data: jwt(credentialResponse.credential, "SECRET")})
    console.log(resp)
    if (resp.status===401) {return message.error(resp.data.detail)}
    else if (resp.status===200 && resp.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(resp.data))
      window.location.reload();
    }
  }


  const handleAuth = async () => {
    setLoading(true)
    const fields = form.getFieldsValue()
    fields.passwordCheck = undefined
    if (fields.mail===undefined) {message.error('Заполните электронную почту')} 
    else if (fields.password===undefined) {message.error('Заполните пароль') } 
    else if (fields.username===undefined && activeKey==='signup') {message.error('Заполните имя') } 
    // else if (activeKey==='signup' && fields.password!==fields.passwordCheck) {message.error('Пароли не совпадают')} 
    else {
      // const resp = await post('auth', {type: activeKey}, {d: jwt(fields, "SECRET")})
      const formData = new FormData()
      formData.append('data', jwt(fields, "SECRET"))
      // Object.keys(fields).forEach(key => formData.append(key, fields[key]));
      const resp = await post('auth', {type: activeKey}, formData)
      // const resp = await post('auth', {type: activeKey, data: jwt(fields, "SECRET")})
      if (resp.status===200 && resp.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(resp.data))
        window.location.reload()
      } else {message.error(resp.data.detail)}
    }
    setLoading(false)
  }


  const onError = () => {message.error('Ошибка входа с помощью google')}

  const formProps = {activeKey: activeKey, form: form, forgotPswd: forgotPswd, setForgotPswd: setForgotPswd}


  return (
    <Modal
      visible={props.visible}
      onCancel={()=>props.setVisible(false)}
      footer={null}
    >
      <div style={{margin: '20px'}}>
      <Spin indicator={<LoadingOutlined />} spinning={loading} >
        <Tabs
          centered
          activeKey={activeKey}
          onChange={key=>setActiveKey(key)} 
          items={[
            { label: <h1>Вход</h1>, key: 'signin', children: <AuthForm key='signin' {...formProps} /> }, 
            { label: <h1>Регистрация</h1>, key: 'signup', children: <AuthForm key='signup' {...formProps} /> },
          ]}
        />
          <Row gutter={20}>
            {activeKey==='signin'&&<Col span={12}><Button size='large' type='primary' style={{width: '100%'}} onClick={handleAuth} >Войти</Button></Col> }
            {activeKey==='signup'&&<Col span={12}><Button size='large' type='primary' style={{width: '100%'}} onClick={handleAuth} >Зарегистрироваться</Button></Col> }
            <Col span={12}>
            <GoogleLogin 
              onSuccess={handleGoogleAuth} 
              onError={onError} 
              // useOneTap 
              size='large'
              // type='icon'
              // theme='filled_blue'
              // width='250px'
              // width='100%'
              shape='circle'
            />
            </Col>
          </Row>
        </Spin>
      </div> 
    </Modal>
  )
}




export default Auth