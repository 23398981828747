import { useState, useEffect, useRef, createRef } from 'react';
import mapboxgl from "mapbox-gl";
import {
  Drawer, Form, Button, Col, Row, Input, Upload,
  Select, DatePicker, Divider, Spin, Card, Modal,
  Checkbox, Switch, Typography, Space, Image, Avatar
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { get, post } from "./../../api/fetchData";
import AddDirectionMap from "./map"
import UploadImages from "./uploadImages"
import AvatarLogo from './avatar'
import { options, src } from './../../helpers/options'


const { Option } = Select;
const rules = [{ required: true, message: 'Обязательное поле' }]


const tags_options = options.map(o=>({label: o.type, value: o.icon}))

// const AddDirectionMap = (props) => {
//   // const mapContainerRef = useRef(null);
//   // const mapContainerRef = createRef();
//   // useEffect(() => {
//   //   const map = new mapboxgl.Map({
//   //     container: mapContainerRef.current,
//   //     style: "mapbox://styles/mapbox/outdoors-v11",
//   //     center: [44.1786, 42.9507],
//   //     zoom: 8,
//   //     pitch: 45,
//   //     // bearing: 80,
//   //   });
//   //   return () => map.remove();
//   // }, []);

//   const handleCancel = () => {
//     props.setVisible(false)
//   }

//   const handleOk = () => {
//     // props.setFieldsValue()
//     props.setVisible(false)
//   }

//   return (
//     <Modal
//       title="Найти координаты" 
//       visible={props.visible} 
//       onOk={handleOk} 
//       onCancel={handleCancel}
//     >
//       {/* <div className='map-container' ref={mapContainerRef} /> */}
//       <AddDirectionMap_ />
//     </Modal>
//   )
// }



const DirectionForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState()
  const [data, setData] = useState()
  const [icon, setIcon] = useState()
  const [modalVisible, setModalVisible] = useState(false)




  useEffect(() => {
    setTimeout(()=>{
      setIcon(props.form.getFieldValue("icon"))
    }, 500)
  }, []);




  const regionShorter = (region) => {
    return region.replace("Республика ", "")
  }

  const handleSearch = async (value) => {
    setLoading(true)
    try {
      const resp = await get("latlng", {lat: props.form.getFieldValue('lat'), lng: props.form.getFieldValue('lng')})
      console.log(resp.data)
      props.form.setFieldsValue({
        district: resp.data.raw.district,
        region: regionShorter(resp.data.raw.region)
      });
      setData({
        district: resp.data.raw.district,
        region: regionShorter(resp.data.raw.region),
        town: resp.data.raw.place,
        adress: resp.data.raw.properties ? resp.data.raw.properties.address : undefined,
      })
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }






  const Type = () => {
    function handleChange(value) {
      props.form.setFieldsValue({icon: value, type: options.filter(o=>o.icon===value)[0].type})
    }
    return (
      <Select
      defaultValue={icon}
      onChange={handleChange}
      >
        {options.map(o=>{
          return <Option value={o.icon}>{<Avatar src={src(o.icon)} style={{width: '20px', height: '20px', marginRight: '5px'}} />}{o.type}</Option>
        })}
      </Select>
    )
  }


  const onSelectCurrency = (value) => {props.form.setFieldsValue({currency: value})}
  const selectCurrency = (
    <Select defaultValue="₽" className="select-before" onSelect={(value)=>onSelectCurrency(value)}>
      <Option value="₽">Рубль Ru</Option>
      <Option value="$">Доллар US</Option>
    </Select>
  )

  const onSelectPriceType = (value) => {props.form.setFieldsValue({price_type: value})}
  const selectPriceType = (
    <Select defaultValue="Входной билет на человека" className="select-after" onSelect={(value)=>onSelectPriceType(value)}>
      <Option value="Входной билет на человека">Входной билет на человека</Option>
      <Option value="Средний чек">Средний чек</Option>
    </Select>
  )

  const onPriceChange = (e) => {
    if (e.target.value) {
      if (!props.form.getFieldValue('currency')) {props.form.setFieldsValue({currency: '₽'})}
      if (!props.form.getFieldValue('price_type')) {props.form.setFieldsValue({price_type: "Входной билет на человека"})}
    }
  }



  const MapModal = () => {
  
    const onModalOk = (lng, lat) => {
      console.log(lng, lat)
      props.form.setFieldsValue({lng: lng, lat: lat})
      console.log(props.form.getFieldsValue())
      setModalVisible(false)
    }

    return (
      <Modal
        footer={null}
        title="Найти координаты" 
        open={modalVisible} 
        //onOk={()=>{onModalOk();setModalVisible(false)}} 
        //onCancel={()=>setModalVisible(false)}
        bodyStyle={{width: '1000px', height: '600px'}}
      >
        <AddDirectionMap 
          visible={modalVisible} 
          // onMapClick={onMapClick}
          lng={props.form.getFieldValue('lng')}
          lat={props.form.getFieldValue('lat')}
          onModalOk={onModalOk}
          setModalVisible={setModalVisible}
        />
      </Modal>
    )
  }
  return (

    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>} delay={0}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="name"
            rules={rules}
          >
            <Input 
              addonBefore='Наименование направления'
              placeholder="Наименование направления" 
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="type"
            rules={rules}
          >
            <Type />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            name="lat"
            rules={rules}
          >
            <Input 
              placeholder="lat" 
              addonBefore="lat" 
              // onChange={(e)=>setLat(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            name="lng"
            rules={rules}
          >
            <Input 
              placeholder="lng" 
              addonBefore="lng" 
              // onChange={(e)=>setLng(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button
            type='primary'
            onClick={()=>{setModalVisible(true)}}
          >
            Карта
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            name="region"
            rules={rules}
          >
            <Input placeholder="Регион" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="district" >
            <Input placeholder="Район" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button
            type='primary'
            onClick={handleSearch}
          >
            Заполнить 1
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Form.Item name="town" >
            <Input placeholder="Населенный пункт" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="address">
            <Input placeholder="Адрес" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button
            type='primary'
            onClick={()=>{props.form.setFieldsValue({town: data.town, address: data.address})}}
          >
            Заполнить 2
          </Button>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={18}>
          <Form.Item name="price" >
            <Input 
              placeholder="Необязательно" 
              addonBefore={selectCurrency} 
              addonAfter={selectPriceType}
              onChange={onPriceChange}
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item name="tags" >
            <Select
              placeholder='Теги для группировки на главной странице'
              mode="multiple"
              options={tags_options}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="phone" >
            <Input placeholder="Телефон" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="site" >
            <Input placeholder="Сайт" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="description" >
            <Input.TextArea 
              style={{marginTop: '30px', marginBottom: '10px'}}
              placeholder="Описание"
              rows={20} 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="source" >
            <Input.TextArea placeholder="Источник материала" rows={3} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'><h6>Загрузить фото</h6></Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="images" >
            <UploadImages form={props.form} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'><h6>Выбрать лого</h6></Divider>
      <AvatarLogo form={props.form} />
      <Form.Item name="icon" />
      <Form.Item name="id" />
      <Form.Item name="logo" />
      <Form.Item name="avatar" />
      <Form.Item name="currency" />
      <Form.Item name="price_type" />
      <Form.Item name="active" initialValue={props.form.getFieldValue('active') ? props.form.getFieldValue('active') : true} />
      <MapModal />
    </Spin>
  )
}

export default DirectionForm
