import Header from './header'
import Content from './content'
import Introduction from './introduction'


const user = localStorage.getItem("user")


const Partner = (props) => {
  if (user) {
    return (
      <div>
        <Header />
        <Content />
      </div>
    )
  } else {
    return <Introduction />
  }
}


export default Partner



