const initialMetaState = {
  visible: false,
  routes_filter: {},
  directions_filter: {type: []},
  transfers_filter: {type: []},
};

export const filterReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_FILTER_VISIBLE":
      return { ...state, visible: action.payload };
    case "ACTION_ROUTES_FILTER":
      return { ...state, routes_filter: action.payload };
    case "ACTION_DIRECTIONS_FILTER":
      return { ...state, directions_filter: action.payload };
    case "ACTION_TRANSFERS_FILTER":
      return { ...state, transfers_filter: action.payload };
    default:
      return state;
  }
};
