const initialMetaState = {
  visible: false,
  transfer: {images: "[]"},
};

export const transferReducer = (state = initialMetaState, action) => {
  switch (action.type) {
    case "ACTION_TRANSFER_VISIBLE":
      console.log(action)
      return { ...state, visible: action.payload };
    case "ACTION_TRANSFER_DATA":
      return { ...state, transfer: action.payload };
    default:
      return state;
  }
};
