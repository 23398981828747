import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Input, Tag, Button, Row, Col, Tooltip, notification, 
  Badge, Modal, Divider, DatePicker, TimePicker, Checkbox, InputNumber, Form, Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FaRoute } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRepeat } from "react-icons/bs"
import { BiSort } from "react-icons/bi"

import { List, arrayMove } from "react-movable";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { actionMyRouteVisible, actionMyRouteMove } from "./../../actions/myroute"
import { actionDirectionsVisible } from "./../../actions/directions"
import { actionTransfersDistance, actionTransfersSelected } from "./../../actions/transfers"
import SelectTransfer from './../transfers/selectTransfer'
import { post } from "./../../api/fetchData";
import { optiCoords } from "./../map"
import { minTommss } from './../../helpers/helpers'



const Properties = (props) => {

  return (
    <div style={{width: '100%'}}>
      <Divider orientation='left' >Параметры</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='datetime'>
            <DatePicker 
              placeholder="Дата и время" 
              showTime format="YYYY-MM-DD HH:mm"
              minuteStep={15} 
              style={{width: '100%'}} 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='food'>
            <InputNumber 
              addonBefore="Питание" 
              addonAfter="разовое" 
              defaultValue={0} 
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='food'>
            <Checkbox>Дети</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}


const Contact = (props) => {

  return (
    <div style={{width: '100%'}}>
      <Divider orientation='left' >Контактные данные</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='username'>
            <Input
              placeholder="Имя, необязательно" 
              style={{width: '100%'}} 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name='phone'>
            <PhoneInput defaultCountry='RU' placeholder='Телефон' />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}





const MyRoute = (props) => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
  const [disable, setDisable] = useState(true)

  const myRef = useRef()
  const [form] = Form.useForm();
  const legs = props.legs
  const w = window.innerWidth-40
  const h = window.innerHeight
  // const distance = Math.round(legs.map(l=>l.distance).reduce((a,b)=>a+b)/100)/10
  // const duration = Math.round(legs.map(l=>l.duration).reduce((a,b)=>a+b)/360)/10
  const lastDirIndex = props.myRoute.length-1

  useEffect(()=>{
    if (props.visible) {
      setUser(localStorage.getItem("user"))
      // console.log(props.height)
    }
  }, [props.visible])

  const handleCancel = (key) => {
    props.myRouteMove(props.myRoute.filter((r, i)=>i!==key))
    if (props.myRoute.filter((r, i)=>i!==key).length===0) {
      props.myRouteVisible(false);
      props.cancelTransfers()
    }
  }

  const addReverseRoute = () => {
    props.myRouteMove(props.myRoute.concat(props.myRoute[0]))
  }

  const Book = () => {
    const onClick = async () => {
      setLoading(true)
      form.setFieldsValue({directions: props.myRoute.map(d=>d.id)})
      form.setFieldsValue({transfers: props.transfers_selected.map(t=>{return({...t, directions: undefined})})})//.map(t=>t.id)})
      form.setFieldsValue({distance: Math.round(legs.map(l=>l.distance).reduce((a,b)=>a+b)/100)/10})
      form.setFieldsValue({duration: Math.round(legs.map(l=>l.duration).reduce((a,b)=>a+b)/360)/10})
      form.setFieldsValue({user_id: user ? JSON.parse(user).id : null})
      // console.log(form.getFieldsValue())
      // console.log(user)
      const resp = await post('addroutehistory', {edit: false}, form.getFieldsValue())
      if (resp.status===200) {
        notification['success']({
          message: 'Маршрут сформирован',
          description: 'Ожидайте звонка оператора для окончательного подтверждения маршрута.',
        })
        // setTimeout(()=>{
        //   props.myRouteMove([])
        //   props.myRouteVisible(false);
        //   props.cancelTransfers()
        // }, 1000)
      }
      setLoading(false)
    }
    return (
      <Button onClick={onClick} type="primary">
        Забронировать
      </Button>
    )
  }
  
  const Clear = () => {
    const onClick = () => {
      props.myRouteMove([])
      props.myRouteVisible(false);
      props.cancelTransfers()
    }
    return (
      <Button onClick={onClick}>
        Очистить
      </Button>
    )
  }
  
  const RollUp = () => {
    const onClick = () => {props.myRouteVisible(false)}
    return (
      <Button onClick={onClick}>
        Свернуть
      </Button>
    )
  }

  const optimize = async () => {
    setLoading(true)
    const resp = await optiCoords(props.myRoute.map(item=>`${item.lng},${item.lat}`).join(';'));
    const opti = resp.waypoints.map((wp, i)=>{return {index: wp.waypoint_index, waypoint: props.myRoute[i]}})
      .sort((a, b) => a.index - b.index).map(r=>r.waypoint)
    props.myRouteMove(opti)
    setLoading(false)
  }

  return (
      <div className="route">
        { props.myRoute.length>0 && <Badge count={props.myRoute.length-1} style={{ backgroundColor: '#52c41a' }} >
          <Button 
            icon={<FaRoute style={{color: "white", marginRight: '5px'}} />} 
            style={{backgroundColor: "#1890ff", borderRadius: '20px', color: 'white'}} 
            onClick={()=>props.myRouteVisible(true)}
          >
            Маршрут
          </Button>
        </Badge>}
        <Modal
          visible={props.visible}
          title={legs.length>0 ? `Мой маршрут ${Math.round(legs.map(l=>l.distance).reduce((a,b)=>a+b)/100)/10}км, ${minTommss(Math.round(legs.map(l=>l.duration).reduce((a,b)=>a+b)/360)/10)}ч` : "Мой маршрут"}
          onOk={()=>props.myRouteVisible(false)}
          onCancel={()=>props.myRouteVisible(false)}
          footer={[<Clear />, <RollUp />, <Book />]}
          style={{top: '3px', bottom: '3px'}}
          bodyStyle={{
            height: h-115,
            padding: '0px',
          }}
        >
          <div style={{
            overflowY: 'scroll', 
            overflowX: 'hidden', 
            width: '100%', 
            height: h-119,
            padding: '7px',
          }} ref={myRef}>
            <Spin spinning={loading} indicator={<LoadingOutlined style={{fontSize: 24 }} spin />}>
              <h5>Перемешайте направления для построения оптимального маршрута</h5>
              <List
                values={props.myRoute}
                onChange={({ oldIndex, newIndex }) =>
                  props.myRouteMove(arrayMove(props.myRoute, oldIndex, newIndex))
                }
                renderList={({ children, props, isDragged }) => (
                  <ul
                    {...props}
                    style={{ padding: 0, cursor: isDragged ? "grabbing" : undefined }}
                  >
                    {children}
                  </ul>
                )}
                renderItem={({ value, props, isDragged, isSelected }) => (
                    <li 
                      {...props} 
                      style={{
                        ...props.style,
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: "0.5em",
                        margin: "0.1em 0em",
                        listStyleType: "none",
                        cursor: isDragged ? "grabbing" : "grab",
                        border: "1px solid #CCC",
                        // boxShadow: "3px 3px #AAA",
                        color: "#333",
                        borderRadius: "3px",
                        backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF"
                      }}
                    >
                    
                      <div>
                        <img 
                          src={`http://rocktrip.ru/storage/route/${props.key===lastDirIndex ? 0: props.key}.png`} 
                          style={{height: '25px', width: '25px', marginRight: '10px'}}
                        />
                        {value.name}
                      </div>
                      <div>
                        { props.key!==0&&legs[props.key] ? <Tag color="green">{Math.round(legs[props.key].distance/100)/10} км</Tag> : null }
                        <Tooltip  title="Убрать из маршрута">
                          <Button onClick={()=>{handleCancel(props.key)}} icon={<AiOutlineClose />} type="text" danger />
                        </Tooltip>
                      </div>
                    </li>
                )}
              />
              <div>
                <Button onClick={()=>{props.directionsVisible(true)}}>
                  Добавить
                </Button>
                {props.myRoute.length>1&&props.myRoute[0]!==props.myRoute[props.myRoute.length-1]&&<Button 
                  onClick={addReverseRoute}
                  icon={<BsArrowRepeat style={{marginRight: '3px'}}/>}
                  type='link'
                >
                  Обратный путь
                </Button>}
                <Button onClick={optimize} icon={<BiSort />} type="text">
                  Оптимизировать
                </Button>
              </div>
              <Form layout="vertical" name="basicForm" form={form} >
                <Properties />       
                <SelectTransfer w={myRef.current}/>
                {!user && <Contact />}
                <Form.Item name="user_id" hidden />
                <Form.Item name="directions" hidden />
                <Form.Item name="transfers" hidden />
                <Form.Item name="distance" hidden />
                <Form.Item name="duration" hidden />
                <Form.Item name="status" hidden initialValue='order' />
              </Form>
              <br/>
              <br/>
              <br/>
            </Spin>
          </div>
        </Modal>
      </div>
    
  );
};



const mapStateToProps = state => ({
  visible: state.myrouteReducer.visible,
  myRoute: state.myrouteReducer.data,
  legs: state.myrouteReducer.legs,
  transfers_selected: state.transfersReducer.selected,
  height: state.rollReducer.height,
})


const mapDispatchToProps = dispatch => ({
  myRouteVisible: visible => {dispatch(actionMyRouteVisible(visible));},
  myRouteMove: route => {dispatch(actionMyRouteMove(route))},
  directionsVisible: visible => { dispatch(actionDirectionsVisible(visible)) },
  cancelTransfers: () => {
    dispatch(actionTransfersDistance(undefined));
    dispatch(actionTransfersSelected([]));
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(MyRoute)
