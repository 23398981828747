import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, Carousel, Divider, Image } from 'antd'
import { AiOutlineFilter } from "react-icons/ai";
import { HeartTwoTone } from '@ant-design/icons';
import { 
    actionTransferVisible,  
} from "./../../actions/transfer"
import { get } from "./../../api/fetchData";
import { Favorite } from './../favorites'



const Transfer = (props) => {

  const w = window.innerWidth-40
  const h = window.innerHeight

  const onFilterBtnClick = () => {
    console.log(props.visible)
    props.transferVisible(true)
  }
  
  const OkBtn = () => {
    return <Button onClick={()=>props.transferVisible(false)}>Ок</Button>
  }


  const Title = () => {
    return (
      <div style={{paddingBottom: "10px"}} >
        <div style={{float: "left"}}>{`${props.transfer.brand} ${props.transfer.model}`}</div>
        <div style={{float: "right", marginRight: '20px'}}>
          <Favorite 
            fav_id={props.transfer.id} 
            add={props.transfer.fav ? true : false}
            type='transfer'
          />
        </div>
      </div>
    )
  }
  
    
  return (
    <>
      <Modal
        visible={props.visible}
        title={<Title />}
        onCancel={()=>props.transferVisible(false)}
        footer={[<OkBtn />]}
        style={{top: '3px', bottom: '3px'}}
        bodyStyle={{
          height: h-115,
          padding: '0px',
        }}
      >
        <div style={{
          overflowY: 'scroll', 
          width: '100%', 
          height: h-119,
        }}>
          <Carousel autoplay>
            {JSON.parse(props.transfer.images).map(img=>{return (<Image src={img} />)})}
          </Carousel>
          <Divider orientation="left"><h4>Описание</h4></Divider>
        </div>
      </Modal>
    </>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.transferReducer.visible,
    transfer: state.transferReducer.transfer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    transferVisible: (visible) => {
      dispatch(actionTransferVisible(visible));
    },
})}

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)