import {
  ACTION_DIRECTIONS_FILTER,
  ACTION_ROUTES_FILTER,
  ACTION_TRANSFERS_FILTER,
  ACTION_FILTER_VISIBLE,
} from "./types";
  
  

export const actionFilterVisible = (visible) => ({
  type: ACTION_FILTER_VISIBLE,
  payload: visible
});


export const actionDirectionsFilter = (filter) => ({
  type: ACTION_DIRECTIONS_FILTER,
  payload: filter
});


export const actionRoutesFilter = (filter) => ({
  type: ACTION_ROUTES_FILTER,
  payload: filter
});


export const actionTransfersFilter = (filter) => ({
  type: ACTION_TRANSFERS_FILTER,
  payload: filter
});
  