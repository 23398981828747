import { useState, useEffect, useRef } from "react"
import { connect } from "react-redux";
import { Tooltip, Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { 
  actionRouteVisible,  
  actionRouteData,  
} from "./../../actions/route"
import { 
  actionRoutesVisible,  
} from "./../../actions/routes"
import {  
  actionMyRouteUrlCoords,  
  actionMyRouteRemove,  
  actionMyRouteMove,  
  actionMyRouteAdd,  
} from "./../../actions/myroute"
import {
  actionTransfersDistance,
  actionTransfersSelected
} from "./../../actions/transfers"
import { get } from "./../../api/fetchData";
import RoutesFilter from './../filters/routesFilter'
import EntityCard from './../commonTemplates/entityCard'
import { EntitiesModal } from './../commonTemplates/modals'
import Route from "./route";




const src = icon=>`http://rocktrip.ru/storage/icons/${icon}.png`
export const RouteProperty = (props) => {
  return (
    <Tooltip title={props.title} >
      <div style={{
        marginRight: '10px', 
        fontWeight: props.bold ? 'bold' : null,
        display: 'flex',
        alignItems: 'center',
      }}>
        <img src={src(props.icon)} style={{height: '20px', width: '20px'}} />{props.value}
      </div>
    </Tooltip>
  )
}

export const RouteDirections = (props) => {
  return (
    <>
      <h4 style={{color: 'white'}}>Направления</h4>
      <br />
      {props.directions.map(direction=>{return (
        <p>
          <img src={src(direction.icon)} style={{height: '20px', width: '20px'}}/>
          {direction.name}
        </p>
      )})}
    </>
  )
}




const Routes = props => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [w, setW] = useState(100)
  const [h, setH] = useState(100)

  // const w = window.innerWidth-40
  // const h = window.innerHeight

  const divRef = useRef()
  
  useEffect(() => {
    const f = async () => {
      setLoading(true)
      const resp = await get('routes', {...props.routes_filter})
      setData(resp.data)
      setLoading(false)
    }
    f()
    setW(divRef.current&&divRef.current.clientWidth-40)
    setH(divRef.current&&divRef.current.clientHeight)
  }, [props.routes_filter])

  

    
  return (
    <div style={{display: 'flex', flexDirection: 'column', marginTop: '60px', width: '100%', backgroundColor: '#EEEEEE'}}>
      <RoutesFilter />
      <div style={{
        overflowY: 'scroll', 
        height: '100%',
        // position: 'absolute',
        width: '100%',
        backgroundColor: '#EEEEEE'
      }}>
        <div className='dirs' >
        <div ref={divRef} className='dirs2' >
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          {data&&data.map(route=><EntityCard entity={route} type='route' w={w} selectEntity={props.selectRoute} />)}
        </Spin>
      </div>
      </div>
      </div>
      {props.routeVisible&&<Route />}
    </div>
  )
}




const mapStateToProps = (state, ownProps) => {
  return {
    visible: state.routesReducer.visible,
    routeVisible: state.routeReducer.visible,
    directions: state.directionsReducer.directions,
    // route: state.routeReducer.data,
    routes_filter: state.filterReducer.routes_filter,
  };
};

const mapDispatchToProps = dispatch => ({
  routesVisible: visible => { dispatch(actionRoutesVisible(visible)) },
  selectRoute: route => {
    dispatch(actionRouteData(route));
    dispatch(actionRouteVisible(true));
  },
  myRouteAdd: wayPoint => { dispatch(actionMyRouteAdd(wayPoint)) },
  myRouteRemove: wayPoint => { dispatch(actionMyRouteRemove(wayPoint)) },
  myRouteMove: route => { dispatch(actionMyRouteMove(route)) },
  cancelTransfers: () => {
    dispatch(actionTransfersDistance(undefined));
    dispatch(actionTransfersSelected([]));
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Routes)