import { useState, useEffect, useRef } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, Carousel, Divider, Image, Card, Rate, Row, Select, Slider, Spin } from 'antd'
import { StarFilled, PlusOutlined, CheckOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { actionDirectionsVisible } from "./../../actions/directions"
import { actionDirectionVisible, actionDirectionData } from "./../../actions/direction"
import { actionMyRouteRemove, actionMyRouteMove, actionMyRouteAdd, } from "./../../actions/myroute"
import { get } from "./../../api/fetchData";
import DirectionsFilter from './../filters/directionsFilter'
import EntityCard from './../commonTemplates/entityCard'
import { EntitiesModal } from './../commonTemplates/modals'
import Direction from './../directions/direction'






const Directions = props => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [w, setW] = useState(100)
  const [h, setH] = useState(100)

  // const w = window.innerWidth-40
  // const h = window.innerHeight

  const divRef = useRef()

  useEffect(() => {
    const f = async () => {
      setLoading(true)
      const resp = await get('directions', {...props.directions_filter, type: props.directions_filter.type.join(','), mode: "list"})
      setData(resp.data)
      setLoading(false)
    }
    f()
    setW(divRef.current&&divRef.current.clientWidth-40)
    setH(divRef.current&&divRef.current.clientHeight)
  }, [props.directions_filter]) 


  const BackBtn = () => {
    return (
      <Tooltip title="Вернуться" >
        <Button 
          onClick={()=>props.directionsVisible(false)} 
          icon={<ArrowLeftOutlined />} 
          type="text"
        />
      </Tooltip>
    )
  }





  if (props.modal) {return (
    <EntitiesModal
      visible={props.visible}
      title="Все направления"
      setVisible={props.directionsVisible}
      // h={h}
      // divRef={divRef}
    >
      <DirectionsFilter />
      <div ref={divRef} className='dirs' style={{
        height: window.innerHeight-346,
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      }}>
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
          {data&&data.map(direction=><EntityCard entity={direction} type='direction' w={w} selectEntity={props.selectDirection} />)}
        </Spin>
      </div>
    </EntitiesModal>
  )} else {return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '60px', width: '100%', backgroundColor: '#EEEEEE'}}>
      <DirectionsFilter />
      <div className='dirs' >
        <div ref={divRef} className='dirs2' >
          <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            {data&&data.map(direction=><EntityCard entity={direction} type='direction' w={w} selectEntity={props.selectDirection} />)}
          </Spin>
        </div>
        {props.directionVisible&&<Direction />}
      </div>
    </div>
  )}
}




const mapStateToProps = state => ({
  visible: state.directionsReducer.visible,
  directionVisible: state.directionReducer.visible,
  directions: state.directionsReducer.directions,
  myRoute: state.myrouteReducer.data,
  directions_filter: state.filterReducer.directions_filter,
})

const mapDispatchToProps = dispatch => ({
  directionsVisible: visible => { dispatch(actionDirectionsVisible(visible)) },
  selectDirection: direction => {
    dispatch(actionDirectionData(direction));
    dispatch(actionDirectionVisible(true));
  },
  myRouteAdd: wayPoint => { dispatch(actionMyRouteAdd(wayPoint)) },
  myRouteRemove: wayPoint => { dispatch(actionMyRouteRemove(wayPoint)) },
  myRouteMove: route => { dispatch(actionMyRouteMove(route)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(Directions)