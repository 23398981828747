import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { message, Drawer, Form, Button, Spin, List, Description } from 'antd';
import {
  LoadingOutlined
} from "@ant-design/icons";
import { get, post, postb } from "./../../api/fetchData";
import DirectionForm from './direction'
import TransferForm from './transfer'
import RouteForm from './route'
import {
  actionAddFormKey,
  actionAddFormVisible,
  actionAddFormEditData,
} from './../../actions/add'




const CommonBtns = (props) => {
  return (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button onClick={props.onCancel} style={{ marginRight: 8 }}>
        Отмена
      </Button>
      <Button onClick={()=>{props.onSave(props.form)}} htmlType="submit" type="primary">
        Сохранить
      </Button>
    </div>
  )
}

const Add = (props) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const [form] = Form.useForm();


  useEffect(()=>{
    setOpen(true)
    if (props.editData!==undefined) {
      form.setFieldsValue(JSON.parse(props.editData))
    } else {
      form.resetFields()
    }
  }, [])
  

  const onCancel = () => {
    setOpen(false)
    form.resetFields()
    setTimeout(()=>{
      props.setAddFormOpen && props.setAddFormOpen(false)
    }, 500)
  };

  const onSave = async (form) => {
    const valid = await form.validateFields().then(response=>response).catch(err=>err)
    if (valid.errorFields) {
      console.log(valid.errorFields)
      return message.error('Ошибка. Проверьте правильность заполнения формы')
    }
    setLoading(true)
    const result = await sendForm(form)
    if (result) {onCancel()} // close drawer and clear form data
    setLoading(false)
    props.reload()
  };

  const sendForm = async (form) => {
    console.log(form.getFieldsValue())
    try {
      const resp = await post(props.addFormKey, {edit: props.editData ? true : false}, form.getFieldsValue())
      if (resp.status===200) {
        message.success(resp.data.message)
        if (form.getFieldValue('upload')) {await handleUploadFiles(resp.data.id)}
        if (form.getFieldValue('logo')) {await logoAvatar(resp.data.id, 'logo')}
        if (form.getFieldValue('avatar')) {await logoAvatar(resp.data.id, 'avatar')}
        return true
      } else {
        console.log(resp)
        message.error("Ошибка при загрузке данных")
        return false
      }
    } catch (e) { console.log('error', e) }
  }


  const handleUploadFiles = async (id) => {
    const fileList = form.getFieldValue('upload')
    var formData = new FormData();
    fileList.forEach((file, index) => {formData.append('upload', file.originFileObj ? file.originFileObj : file.url)});
    try {
      const resp = await postb('addimages', {id: id, source: source()}, formData)
      if (resp.status===200) { message.success(resp.data.message) } 
      else { message.error("Ошибка при загрузке фото") } 
    } catch (e) { console.log('error', e) }
  }


  const logoAvatar = async (id, type) => {
    var formData = new FormData();
    formData.append('file', form.getFieldValue(type))
    const resp = await postb('addlogoavatar', {id: id, source: source(), type: type}, formData)
    if (resp.status===200) { message.success(resp.data.message) } 
    else { message.error("Ошибка при загрузке лого и аватара") } 
  }

  const source = () => {
    switch (props.addFormKey) {
      case 'adddirection':
        return 'directions'
      case 'addtransfer':
        return 'transfer'
      case 'addroute':
        return 'routes'
      default:
        return 
    }
  }



  const getForm = (form) => {
    switch (props.addFormKey) {
      case "adddirection":
        return <DirectionForm form={form} edit={props.editData ? JSON.parse(props.editData) : undefined} />
      case "addtransfer":
        return <TransferForm form={form} edit={props.editData ? JSON.parse(props.editData) : undefined} />
      case "addroute":
        return <RouteForm form={form} edit={props.editData ? JSON.parse(props.editData) : undefined} />
      default:
        return <DirectionForm form={form} />
    }
  }

  

  const getTitle = () => {
    const act = props.editData ? "Редактировать " : "Добавить "
    switch (props.addFormKey) {
      case "adddirection":
        return act + "направление"
        break;
      case "addtransfer":
        return act + "транспорт"
        break;
      case "addroute":
        return act + "маршрут"
        break;
      default:
        return act
    }
  }


  return (
    <>
      <Drawer
        title={getTitle()}
        width={720}
        onClose={onCancel}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        className='vertical'
        footer={<CommonBtns form={form} onSave={onSave} onCancel={onCancel} />}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>} spinning={loading}>
          <Form layout="vertical" name="basicForm" form={form} initialValues={props.editData ? JSON.parse(props.editData) : {images: []}} >
            { props.addFormVisible===true && getForm(form) }
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};


export default Add
