import {
    ACTION_TRANSFERS_VISIBLE,
    ACTION_TRANSFERS_SELECTED,
    ACTION_TRANSFERS_DISTANCE,
  } from "./types";
  
  

  
export const actionTransfersVisible = (visible) => ({
  type: ACTION_TRANSFERS_VISIBLE,
  payload: visible
});


export const actionTransfersSelected = (visible) => ({
  type: ACTION_TRANSFERS_SELECTED,
  payload: visible
});


export const actionTransfersDistance = (distance) => ({
  type: ACTION_TRANSFERS_DISTANCE,
  payload: distance
});