import { useState, useEffect, useRef } from "react"
import { connect } from "react-redux";
import { Button, Tooltip, Popover, Modal, Carousel, Divider, Image, Card, Tabs } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';
import Review from './../reviews'
import Rates from './../rate'
import { Favorite } from './../favorites'
import EntityCard from './../commonTemplates/entityCard'
import RouteMap from './../maps/routeMap'
import { minTommss } from "./../../helpers/helpers"
import { BookForm } from "./myRoute";
import { actionRouteVisible } from "./../../actions/route"
import { actionDirectionVisible, actionDirectionData } from "./../../actions/direction"
import { actionTransferVisible, actionTransferData, } from "./../../actions/transfer"
import { actionTransfersVisible, actionTransfersDistance } from "./../../actions/transfers"
import { actionTransfersFilter } from './../../actions/filter'
import { actionMyRouteMove, actionMyRouteUrlCoords } from "../../actions/myroute";
import { Link } from "react-router-dom";




const { TabPane } = Tabs;















const Route = (props) => {
  const [carousel, setCarousel] = useState(true)
  const [rerenderForMyRef, setRerenderForMyRef] = useState()

  const myRef = useRef()
  const w = window.innerWidth-40
  const h = window.innerHeight

  useEffect(()=>{setRerenderForMyRef(true)}, [])

  const onTransfersClick = (directions, distance) => {
    props.routeTransfers({directions: directions.map(d=>d.id).join(',')}, distance)
  }
  
  const onTabchange = tab => tab==='map' ? setCarousel(false) : setCarousel(true)




  const Title = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px'}} >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <h2 style={{marginRight: '20px'}}>{props.route.name}</h2>
          <Tooltip title='Оценить'>
            <Rates parent_id={props.route.id} />
          </Tooltip>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Favorite 
            fav_id={props.route.id} 
            add={props.route.fav ? true : false} 
            type='direction'
          />
          <Tooltip title='Закрыть окно'>
            <Button 
              icon={<CloseOutlined />} 
              type='text' 
              style={{fontSize: '20px'}}
              onClick={()=>props.routeVisible(false)}
            />
          </Tooltip>
        </div>
      </div>
    )
  }



  const CopyRoute = () => {
    const onClick = () => {props.myRouteMove(props.route.directions)}
    // return (
    //   carousel ? null : <Link to='/map'>
    //     <Button onClick={onClick} type='primary' >Построить свой маршрут</Button>
    //   </Link>
    // )
    return (
      <Link to='/map'>
        <Button onClick={onClick} type='primary' >Построить свой маршрут</Button>
      </Link>
    )
  }


  
    
  return (
    <>
      <Modal
        visible={props.visible}
        title={<Title />}
        onCancel={()=>props.routeVisible(false)}
        style={{top: '10px', bottom: '10px'}}
        bodyStyle={{height: h-80, padding: '0px',}}
        width='70vw'
        footer={null}
        closable={false}
        centered
      >
        <div style={{display: 'flex', width: '100%', height: '20px'}} />
        <div style={{
          overflowY: 'scroll', 
          width: '100%', 
          height: h-270,
        }} ref={myRef} >
          {/* {carousel&&<Carousel autoplay>
            {JSON.parse(props.route.images).map(img=>{return (
              <div>
                <div style={{width: 'auto', height: '300px', backgroundImage: `url("${img}")`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
              </div>
            )})}
          </Carousel>} */}
          {carousel && props.route && <ImageGallery 
            thumbnailPosition='right'
            items={JSON.parse(props.route.images).map(i=>({original: i, thumbnail: i}))} 
          />}
          {!carousel&&<RouteMap directions={props.route.directions} h={400} />}
          <Tabs 
            tabBarExtraContent={<CopyRoute />}
            defaultActiveKey="description" 
            onChange={onTabchange}
            style={{marginLeft: '40px', marginRight: '40px'}}
            // tabPosition={mode}
          >
            <TabPane tab="Описание" key="description">
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{color: 'gray', marginRight: '10px', fontWeight: 'bold'}}>{`Локаций: ${Math.round(props.route.directions.length)}`}</span>
                <span style={{color: 'gray', marginRight: '10px', fontWeight: 'bold'}}>{`Длительность: ${props.route.duration} ч`}</span>
                <span style={{color: 'gray', marginRight: '10px', fontWeight: 'bold'}}>{`Растояние: ${props.route.distance} км`}</span>
              </div>
              <p style={{fontSize: '18px'}}>{props.route.description}</p>
              <p style={{fontSize: '22px', fontWeight: 'bold'}}>Локации</p>
              {rerenderForMyRef&&myRef.current&&props.route.directions&&props.route.directions.map(
                direction=><EntityCard 
                  entity={direction} 
                  type='direction' 
                  w={myRef.current.clientWidth} 
                  selectEntity={props.selectDirection} 
                  mobile
                  hideAdd
              />)}
            </TabPane>
            <TabPane tab="Карта" key="map" />
            {/* <TabPane tab={`Трансфер`} key="transfer">
              {rerenderForMyRef&&props.route.transfers&&props.route.transfers.map(
                transfer=><EntityCard 
                  entity={transfer} 
                  type='transfer' 
                  w={myRef.current.clientWidth} 
                  selectEntity={props.selectTransfer} 
                />)}
              {props.route.transfers&&<h4><a style={{cursor: 'pointer'}} onClick={(e)=>onTransfersClick(props.route.directions, props.route.distance)}>Еще транспорт</a></h4>}
            </TabPane> */}
            <TabPane tab={`Отзывы (${props.route.review})`} key="reviews">
              <Review parent_id={props.route.id} />
            </TabPane>
          </Tabs>
        </div>
        <BookForm />
      </Modal>
    </>
  )
}


const mapStateToProps = state => ({
  visible: state.routeReducer.visible,
  route: state.routeReducer.route,
  myRoute: state.myrouteReducer.data,
})

const mapDispatchToProps = dispatch => ({
  routeVisible: visible => {dispatch(actionRouteVisible(visible));},
  selectDirection: direction => {
    dispatch(actionDirectionData(direction));
    dispatch(actionDirectionVisible(false));
    setTimeout(()=>{dispatch(actionDirectionVisible(true))})
  },
  selectTransfer: transfer => {
    dispatch(actionTransferData(transfer));
    dispatch(actionTransferVisible(false));
    setTimeout(()=>{dispatch(actionTransferVisible(true))})
  },
  routeTransfers: (filter, distance) => {
    dispatch(actionTransfersFilter(filter));
    dispatch(actionTransfersDistance(distance));
    dispatch(actionTransfersVisible(false));
    setTimeout(()=>{dispatch(actionTransfersVisible(true))});
  },
  myRouteMove: route => {dispatch(actionMyRouteMove(route));}
})

export default connect(mapStateToProps, mapDispatchToProps)(Route)







