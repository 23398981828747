import {useState, useEffect} from 'react';
import { Tabs, Button, Table } from 'antd';
import { connect } from "react-redux";
import Header from './header'
import Introduction from './introduction'
import { get, fetchData } from "./../../api/fetchData";
import MyDirections from './myDirections'
import MyTransfers from './myTransfers'
import UserInfo from './../menu/userInfo'
import {
  actionAddFormKey,
  actionAddFormVisible,
  actionAddFormEditData,
} from './../../actions/add'


const { TabPane } = Tabs;



const Content = (props) => {
  const [tab, setTab] = useState('my_directions')
  const [data, setData] = useState()


  // useEffect(async ()=>{
    // switch (tab) {
    //   case "my_directions":
    //     // const resp = await get('my_directions', {id: '618820bebaf6371ea24c4e29'})
    //     // setData(resp.data)
    //     const resp = await get('transfer_directions')
    //     console.log(resp.data)
    //     setData(resp.data)
    //     break;
    //   default:
    //     break;
    // }
  // }, [tab])

  // useEffect(async ()=>{
  //   if (editData!==undefined) {
  //     setAddFormVisible(true)
  //   }
  // }, [editData])



  const onTabChange = (key) => {setTab(key)}

  return (
  <div style={{padding: '20px'}}>
  <Tabs 
    defaultActiveKey={tab} 
    onChange={onTabChange}
    // tabBarExtraContent={ tab==='transfer' ? <AddTransferBtn /> : null }  
  >
    <TabPane tab="Мои направления" key="my_directions">
      <MyDirections />
    </TabPane>
    <TabPane tab="История маршрутов" key="route_history">
      Content of Tab Pane 2
    </TabPane>
    <TabPane tab="Транспорт" key="transfer">
      <MyTransfers />
    </TabPane>
    <TabPane tab="Мои данные" key="user">
      <UserInfo />
    </TabPane>
  </Tabs>
  </div>
)
  }

  // const mapStateToProps = (state) => {
  //   return {
  //     visible: state.addFormReducer.visible,
  //   };
  // };
  
  // const mapDispatchToProps = (dispatch) => ({
  //   addFormVisible: (visible) => {
  //     dispatch(actionAddFormVisible(visible))
  //   },
  //   setEditData: (editData) => {
  //     dispatch(actionAddFormEditData(editData))
  //     dispatch(actionAddFormVisible(true))
  //   }
  // });

export default Content
// export default connect(mapStateToProps, mapDispatchToProps)(Admin)



