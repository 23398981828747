import { useState, useEffect, useRef, createRef } from 'react';
import mapboxgl from "mapbox-gl";
import {
  Drawer, Form, Button, Col, Row, Input, InputNumber, Upload,
  Select, DatePicker, Divider, Spin, Card, Modal, Checkbox, Image,
  Tooltip,
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import { BsArrowDownUp } from "react-icons/bs"
import { List, arrayMove } from "react-movable";
import { get, post } from "./../../api/fetchData";
import AddDirectionMap from "./map"
import UploadImages from "./uploadImages"
import SmartSelect from "./select"
import AvatarLogo from './avatar'
import { coords } from './../maps/map'
import RouteMap from './../maps/routeMap'




const { Option } = Select;
const rules = [{ required: true, message: 'Обязательное поле' }]


mapboxgl.accessToken = 'pk.eyJ1IjoicnVzc3NpazEiLCJhIjoiY2t2Nzl0Y3Z1MW82ZDJ1czNxMzYyYTFzbCJ9.2bXmmydb3D-zOZhP6LDkdA';



const types = [
  'Джип тур',
  'Восхождение',
  'Групповой',
  'Пешая прогулка',
  'Поход',
]


const RouteForm = props => {
  const [loading, setLoading] = useState(false)
  const [distanceIndicator, setDistanceIndicator] = useState(false)
  const [durationIndicator, setDurationIndicator] = useState(false)
  const [showMap, setShowMap] = useState(false)

  const [dirValues, setDirValues] = useState(props.form.getFieldValue('directions') 
    ? props.form.getFieldValue('directions').map(d=>d.id) : [])
  const [dirData, setDirData] = useState(props.form.getFieldValue('directions') 
    ? props.form.getFieldValue('directions').map(d=>({...d, label: d.name, value: d.id, lng: d.lng, lat: d.lat})) : [])


  useEffect(() => { setTimeout(()=>{ props.form.setFieldsValue({directions: dirValues}) }, 500) }, []);

  console.log(props.form.getFieldValue('directions'))
  console.log(props.form.getFieldValue('images'))

  const handleDuration = async () => {
    const resp = await coords(dirData.map(d=>`${d.lng},${d.lat}`).join(';'))
    props.form.setFieldsValue({duration: Math.round(resp.routes[0].duration/36) / 100})
    setDurationIndicator(false)
  }

  const handleDistance = async () => {
    const resp = await coords(dirData.map(d=>`${d.lng},${d.lat}`).join(';'))
    props.form.setFieldsValue({distance: Math.round(resp.routes[0].distance/10) / 100})
    setDistanceIndicator(false)
  }



  const Direction = () => {

    const handleAdd = () => {setDirData(dirData.concat([{}]))}
  
    const handleRemove = (index) => {
      setDirValues(dirValues.filter((d, i)=>i!==index))
      setDirData(dirData.filter((d, i)=>i!==index))
      props.form.setFieldsValue({directions: dirValues.filter((d, i)=>i!==index)})
      setDistanceIndicator(true)
      setDurationIndicator(true)
      setLoading(true)
      setLoading(false)
    }
  
    const onSelect = (value, data, index) => {
      dirValues[index]=value
      dirData[index]=data.filter(d=>d.value===value)[0];
      props.form.setFieldsValue({directions: dirValues})
      setDirData(dirData)
      setDirValues(dirValues)
      setDistanceIndicator(true)
      setDurationIndicator(true)
      setLoading(true)
      setLoading(false)
    }

    return (
      <div style={{marginBottom: '20px'}}>
        <List
          values={dirData}
          onChange={({ oldIndex, newIndex }) => {
            props.form.setFieldsValue({directions: arrayMove(dirValues, oldIndex, newIndex)})
            setDirValues(arrayMove(dirValues, oldIndex, newIndex))
            setDirData(arrayMove(dirData, oldIndex, newIndex))
            setDistanceIndicator(true)
            setDurationIndicator(true)
            setLoading(true)
            setLoading(false)
          }}
          renderList={({ children, props, isDragged }) => (
            <ul {...props} style={{ padding: 0, cursor: isDragged ? "grabbing" : undefined }} >
              {children}
            </ul>
          )}
          renderItem={({ value, props, isDragged, isSelected }) => (
            <>
              <li 
                {...props} 
                style={{ ...props.style, display: 'flex', justifyContent: 'space-between', padding: "0.5em", margin: "0.1em 0em", listStyleType: "none", cursor: isDragged ? "grabbing" : "grab", border: "1px solid #CCC", color: "#333", borderRadius: "3px", backgroundColor: isDragged || isSelected ? "#EEE" : "#FFF"
                }}
              >
                <BsArrowDownUp />
                <SmartSelect
                  placeholder='Укажите направление'
                  onSelect={onSelect}
                  value={dirValues[props.key]}
                  collection='directions'
                  data={[value]}
                  index={props.key}
                  style={{width: '600px'}}
                  bordered={false}
                />
                <Tooltip  title="Убрать из маршрута">
                  <Button icon={<DeleteTwoTone twoToneColor='#ff4d4f' />}  type="text"  danger  onClick={()=>{handleRemove(props.key)}} />
                </Tooltip>
              </li>
            </>
          )}
        />
        <Button type="primary" ghost onClick={handleAdd} icon={<PlusOutlined />} >
          Добавить направление
        </Button>
      </div>
    )
  }


  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>} >
      <div style={{display: 'flex', flexDirection: 'column'}} >

        <Form.Item name="name" rules={rules} >
          <Input placeholder="Название маршрута" />
        </Form.Item>

        <Form.Item name="type" style={{width: '30%'}}>
          <Select options={types.map(t=>({value: t, label: t}))} placeholder='Тип тура' />
        </Form.Item>

        <Divider orientation='left'>Направления</Divider>

        <Direction />

        <Checkbox checked={showMap} onChange={()=>setShowMap(!showMap)} >Показать карту</Checkbox>
        {!loading && showMap && <RouteMap directions={dirData} h={400} />}

        <Form.Item name="description" >
          <Input.TextArea placeholder="Описание" rows={6} style={{marginTop: '20px', marginBottom: '20px'}} />
        </Form.Item>

        <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '20px'}}>
          <Form.Item name="duration" style={{width: '45%'}} >
            <Input addonBefore="Длительность, ч" style={{width: '100%', border: durationIndicator ? '2px solid red' : undefined}} />
          </Form.Item>
          <Button type='text' onClick={handleDuration} syze='small'>Рассчитать</Button>
          
          <Form.Item name="distance" style={{width: '45%'}}>
            <Input addonBefore="Расстояние, км" style={{width: '100%', border: durationIndicator ? '2px solid red' : undefined}} />
          </Form.Item>
          <Button type='text' onClick={handleDistance} syze='small' >Рассчитать</Button>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '20px'}}>
          <Form.Item name="price" style={{width: '50%'}} >
            <InputNumber addonBefore="Цена" style={{width: '68%'}} />
          </Form.Item>          
          <Form.Item name="discount_price" style={{width: '50%'}}>
            <InputNumber addonBefore="Скидочная цена" style={{width: '68%'}} />
          </Form.Item>
        </div>

        {/* <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="food" >
              <InputNumber addonBefore="Питание" />
            </Form.Item>
          </Col>
          <Col span={1} />
          <Col span={5}><Form.Item name="children" valuePropName="checked"><Checkbox>Дети</Checkbox></Form.Item></Col>
          <Col span={5}><Form.Item name="coffee" valuePropName="checked"><Checkbox>Кофе</Checkbox></Form.Item></Col>
          <Col span={5}><Form.Item name="hookah" valuePropName="checked"><Checkbox>Кальян</Checkbox></Form.Item></Col>
        </Row> */}
        <Divider orientation='left'>Загрузить фото</Divider>
          
        <Form.Item name="images" >
          <UploadImages form={props.form} route />
        </Form.Item>

        <Divider orientation='left'>Или</Divider>

        <Divider orientation='left'>Выбрать лого</Divider>
        <AvatarLogo form={props.form} />
        <Form.Item name="has_paid_directions" />   
        <Form.Item name="directions" /> 
        <Form.Item name="id" />
        <Form.Item name="logo" />
        <Form.Item name="avatar" />  
      </div>
    </Spin>
  )
}

export default RouteForm














