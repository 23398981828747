import { useState, useEffect } from "react";
// import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { Route, Routes, Navigate, Link } from "react-router-dom"
import { history } from './helpers/helpers';
import { Result, Button } from 'antd';
import Map from "./components/map";
// import 'antd/dist/antd.css';
import Sidebar from "./components/sidebar/sidebar";
import Roll from "./components/roll";
import MyRoute from "./components/routes_old/myRoute";
import MyRoutes from "./components/routes/routes";
import Dirs from "./components/dirs/dirs";
import Admin from "./components/admin/admin";
import Partner from "./components/partner/partner";
import { get } from "./api/fetchData";
import Header from "./components/header";
import Main from "./components/main";
import { connect } from "react-redux";
import { actionAddUser, actionDeleteUser } from './actions/common'
import { commonReducer } from "./reducers/common";
import UserMenu from "./components/userMenu";
import MyFavorites from "./components/favorites/myFavorites"




const mapStateToProps = state => ({
  user: state.commonReducer.user,
})
const mapDispatchToProps = dispatch => ({
  addUser: data => {dispatch(actionAddUser(data))},
  deleteUser: () => {dispatch(actionDeleteUser())},
})




const App = connect(mapStateToProps, mapDispatchToProps)(props => {
  const [user, setUser] = useState(props.user)

  useEffect(()=>{
    const f = async () => {
      const resp = await get('preloader')
      props.addUser(resp.data)
      setUser(resp.data)
    }
    f()
  }, [])

  return (
    <div style={{display: 'flex', height: window.innerHeight, width: '100%', position: 'relative', backgroundColor: 'transparent'}}>
      <div style={{position: 'absolute', zIndex: 4, width: '100%'}}>
        <Header authorized={user&&user.authorized} />
      </div>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/" element={<Main />} /> */}
        <Route path="/" element={<Map />} />
        <Route path="/map" element={<Map />} />
        <Route path="/routes" element={<MyRoutes />} />
        <Route path="/directions" element={<Dirs />} />
        {user.authorized && <Route path="/usermenu" element={<UserMenu />} />}
        {user.authorized && <Route path="/favorites" element={<MyFavorites />} />}
        {user.admin && <Route path="/admin" element={<Admin />} /> }
        {user.admin && <Route path="/partner" element={<Partner />} /> }
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </div>
  );
})


export default App;









const NotFound = () => (
  <div style={{
    display: 'flex', marginTop: '60px', justifyContent: 'center', width: '100%',
    backgroundImage: 'url("https://rossaprimavera.ru/static/files/a6dd165178de.jpg")',
    backgroundSize: "cover",
    // backgroundColor: 'gray',
    // backgroundBlendMode: 'screen',
  }}>
    <Result
      style={{backgroundColor: 'rgba(255,255,255,0.7)', height: '550px', marginTop: '40px', borderRadius: '20px'}}
      status="404"
      title="404"
      subTitle={<h2>Страница не найдена</h2>}
      extra={<Link to='' ><Button type="primary">Вернуться на главную</Button></Link>}
    />
  </div>
)


