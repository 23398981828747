import { useState, useEffect } from 'react';
import { Tree, Button, Table } from 'antd';
import { get, post } from "./../../api/fetchData";
import Add from './../add/add'
import { ava, cnt, active } from './../admin/admin'






const MyTransfers = (props) => {
  const [data, setData] = useState()
  const [addFormVisible, setAddFormVisible] = useState(false)
  const [editData, setEditData] = useState()

  useEffect(()=>{
    const f = async () => { 
      const resp = await get('transfers', {mode: 'partner'})
      setData(resp.data)
    }
    f()
  }, [])




  return (
    <>
      <Button onClick={()=>setAddFormVisible(true)} style={{marginBottom: '10px'}}>Добавить транспортное средство</Button>
      {addFormVisible&&<Add 
        addFormKey="addtransfer" 
        editData={editData}
        addFormVisible={addFormVisible}
        setAddFormVisible={setAddFormVisible}
      />}
      {data&&<Table 
        style={{cursor: 'pointer'}} 
        dataSource={data.data} 
        columns={[ 
          {title: 'Ava', dataIndex: 'avatar', key: 'avatar', width: '80px', render: ava}, 
          {title: 'Img', dataIndex: 'images', key: 'images', width: '60px', render: cnt},
          ].concat(data.params.columns.filter(item => !item.hidden)).concat([
            {title: 'Статус', dataIndex: 'images', key: 'images', width: '80px', render: (text, record)=>active(text, record, 'transfer')},
          ])}
        pagination={false}
        scroll={{ y: 'calc(90vh - 4em)' }}
        onRow={(record, index)=>{return {onClick: e => {if (!e.target.className.includes('switch')) {
          setEditData(JSON.stringify(record)); setAddFormVisible(true)}}}}}
      />}
    </>
  );
};


export default MyTransfers